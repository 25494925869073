import styled from "styled-components";

export const SubHeading = styled.h2`
  color: ${({ theme }) => theme.modal.heading.font.secondary};
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 28px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
