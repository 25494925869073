import { useState, useEffect, Fragment } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import { Loader } from "components/Loader";
import { useNavigation } from "utils/navigate";
import MobileHeaderMenu from "./MobileHeaderMenu";
import { AccountContainer } from "./Account.styles";
import { userDetailsState } from "Layout/slice/selector";
import ProfileDetailsHeader from "./ProfileDetailsHeader";
import { Heading } from "style/components/Heading.styles";
import { BackButton } from "style/components/BackButton.styles";
import {
  ACCOUNT_TWO_FACTOR_AUTHENTICATION_PATH,
  ACCOUNT_NOTIFICATION_PATH,
  ACCOUNT_OVERVIEW_PATH,
  ACCOUNT_PAYMENT_PATH,
  ACCOUNT_AVAILABLE_PLANS_PATH,
  ACCOUNT_PLAN_BILLING_PATH,
  PROFILE_MENU,
  ACCOUNT_SECURITY_PATH,
} from "./routes";

export const Account = () => {
  const theme = useTheme();
  const [active, setActive] = useState("/account");
  const profileDetails = useSelector(userDetailsState);
  const {
    navigateToAccountOverview,
    navigateToAccountTwoFactorAuthentication,
    navigateToProfileNotification,
    navigateToReceipts,
    navigateToPlans,
    navigateToProfilePayment,
    navigateToPortfolio,
    navigateToAccountSecurity,
  } = useNavigation();

  const navigationDict = {
    [ACCOUNT_OVERVIEW_PATH]: navigateToAccountOverview,
    [ACCOUNT_PLAN_BILLING_PATH]: navigateToReceipts,
    [ACCOUNT_AVAILABLE_PLANS_PATH]: navigateToPlans,
    [ACCOUNT_NOTIFICATION_PATH]: navigateToProfileNotification,
    [ACCOUNT_TWO_FACTOR_AUTHENTICATION_PATH]:
      navigateToAccountTwoFactorAuthentication,
    [ACCOUNT_PAYMENT_PATH]: navigateToProfilePayment,
    [ACCOUNT_SECURITY_PATH]: navigateToAccountSecurity,
  };

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const handleNavigation = (path) => () => {
    navigationDict[path]();
  };

  return (
    <AccountContainer>
      <div className="profile-wrapper">
        <div className="header">
          <BackButton
            label="< &nbsp;Back"
            onClick={navigateToPortfolio}
            className="btn-back"
            variant="secondary"
          />
          <Heading>Account Overview</Heading>
        </div>

        <div className="d-lg-block d-xl-none">
          <MobileHeaderMenu navigationDict={navigationDict} active={active} />
        </div>

        {profileDetails === null ? (
          <Loader />
        ) : (
          <div className="account">
            {/* Profile Name & Image Header */}
            <ProfileDetailsHeader />

            <div className="profile-information">
              <Row>
                <Col
                  xl={3}
                  lg={4}
                  className="profile-menu d-xl-block d-lg-none d-md-none"
                >
                  <Nav
                    activeKey={active}
                    className="flex-column"
                    defaultActiveKey={active}
                  >
                    {PROFILE_MENU.map(
                      ({ path, Icon, title, isVisible }) =>
                        isVisible && (
                          <Fragment key={path}>
                            <Nav.Link
                              eventKey={path}
                              onClick={handleNavigation(path)}
                            >
                              <span className="icon">
                                <Icon
                                  fill={theme.aside.icon.primary}
                                  width={30}
                                />
                              </span>
                              <span>{title}</span>
                            </Nav.Link>
                            <div className="divider" />
                          </Fragment>
                        )
                    )}
                  </Nav>
                </Col>
                <Col xl={9} lg={12}>
                  <div className="content-wrapper">
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </AccountContainer>
  );
};
