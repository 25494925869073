import { lazy } from "react";

const Marketplace = lazy(() => import("features/Marketplace"));
const StrategyDetail = lazy(() => import("features/Marketplace/Detail"));

export const MARKETPLACE_PATH = "/marketplace";
export const MARKETPLACE_STRATEGY_DETAIL_PATH =
  "/marketplace/strategy/:id/detail";

export const marketplaceRoutes = [
  {
    path: MARKETPLACE_PATH,
    component: <Marketplace />,
  },
  {
    path: MARKETPLACE_STRATEGY_DETAIL_PATH,
    component: <StrategyDetail />,
  },
];
