import BTModal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

import { ModalContainer } from "./Modal.styles";

export const Modal = ({
  show,
  onHide,
  heading,
  className,
  size,
  headingClass,
  ...props
}) => {
  return (
    <ModalContainer
      size={size}
      className={["modal-wrapper", className].join(" ")}
      animation={false}
      show={show}
      onHide={onHide}
      {...props}
      centered
    >
      <BTModal.Header closeButton>
        <BTModal.Title className={["body-heading", headingClass].join(" ")}>
          {heading}
        </BTModal.Title>
      </BTModal.Header>
      <BTModal.Body>{props.children}</BTModal.Body>
    </ModalContainer>
  );
};

Modal.propTypes = {
  heading: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  headingClass: PropTypes.string,
};

Modal.defaultProps = {
  show: true,
  size: "md",
  className: "",
  headingClass: "",
};
