import { object, string, ref } from "yup";

import { PASSWORD_MSG, PASSWORD_REGEX } from "utils/constants";

// const URL =
//   /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/gm;
// const URL_MSG = "Must be a valid URL";

const initialValues = (props) => ({
  first_name: props?.first_name || "",
  last_name: props?.last_name || "",
  email: props?.email || "",
  // linkedin: props.linkedin || "",
  // bio: props.bio || "",
  // user_icon: props.user_icon || "",
});

const validationSchema = object({
  first_name: string()
    .trim()
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  last_name: string()
    .trim()
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  email: string()
    .trim()
    .email("Invalid email address")
    .max(200, "Must be 200 characters or less")
    .required("Required"),
  // bio: string().trim().max(2000, "Must be 2000 characters or less"),
  phone: string().matches(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    "Invalid Phone"
  ),
  // linkedin: string().matches(URL, URL_MSG),
});

const initialChangePassValues = {
  current_password: "",
  new_password: "",
  confirm_new_password: "",
};

const changePassValidationSchema = object({
  current_password: string().trim().required("Required"),
  new_password: string()
    .trim()
    .required("Required")
    .matches(PASSWORD_REGEX, PASSWORD_MSG),
  confirm_new_password: string()
    .trim()
    .required("Required")
    .oneOf([ref("new_password"), null], "Passwords must match"),
});

export {
  initialValues,
  validationSchema,
  initialChangePassValues,
  changePassValidationSchema,
};
