import { object, string } from "yup";

const initialValues = ({ name, description }) => ({
  name: name || "",
  description: description || "",
  price: 0,
});

const validationSchema = object({
  name: string().trim().required("Required"),
  description: string().trim().required("Required"),
  price: string()
    .required("Required")
    .matches(/^[0-9]*$/, "Invalid number"),
});

export { initialValues, validationSchema };
