import PropTypes from "prop-types";
import classNames from "classnames";
import Nav from "react-bootstrap/Nav";
import { useEffect, useState } from "react";
import ReactNavbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";

import {
  toast,
  setTheme,
  clearUserState,
  setCollapsedSidebar,
} from "Layout/slice";
import {
  userDetailsState,
  themeState,
  collapsedSidebarSelect,
} from "Layout/slice/selector";
import {
  avatarIcon,
  hamburgerIcon,
  LogoBeta,
  LogoBetaDark,
} from "assets/images";
import {
  userIcon,
  CharmSunIcon,
  CharmMoonLightIcon,
  VerticalSeparatorIcon,
} from "assets/icons/navbar";
import {
  FORGOT_PASS_PATH,
  RESET_PASS_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  VERIFY_PHONE_PATH,
  WELCOME_PATH,
} from "features/Auth/routes";
import { Avatar } from "components/Avatar";
import { Button } from "components/Button";
import { appClearStorage } from "utils/storage";
import { useNavigation } from "utils/navigate";
import { Dropdown } from "components/Dropdown";
import { NavbarContainer } from "./Navbar.styles";
import { disconnectSocket } from "services/socket";
import { loggedInState } from "features/Auth/slice/selector";
import { updateIsLoggedIn } from "features/Auth/slice/thunk";
import NotificationSidebar from "features/Notification/Panel";
import { menuOptions, mobileMenuOptions } from "./menuOptions";
import { LANDING_PAGE_URL, THEME_TYPES } from "utils/constants";
import { contains, openNewTab, parseErrorMessage } from "utils";
import { trackEvent } from "utils/tracking";

export const Navbar = ({ isAuthPage }) => {
  const dispatch = useDispatch();
  const theme = useSelector(themeState);
  const isLoggedInState = useSelector(loggedInState);
  const userDetails = useSelector(userDetailsState);
  const collapsed = useSelector(collapsedSidebarSelect);

  const [showProfileDD, setShowProfileDD] = useState(false);
  const {
    navigateToAccountOverview,
    navigateToHome,
    navigateToSignup,
    navigateToSignin,
  } = useNavigation();

  const menu = menuOptions(isLoggedInState);
  const mobileMenu = mobileMenuOptions(isLoggedInState);
  const isWelcomePath = contains(location.pathname, WELCOME_PATH);
  const showAuthButton =
    !contains(location.pathname, SIGNUP_PATH) &&
    !contains(location.pathname, SIGNIN_PATH) &&
    !contains(location.pathname, FORGOT_PASS_PATH) &&
    !contains(location.pathname, RESET_PASS_PATH) &&
    !contains(location.pathname, VERIFY_PHONE_PATH);

  useEffect(() => {
    handleIsUserLoggedIn();
    dispatch(updateIsLoggedIn());
  }, []);

  const handleSigninVisibility = () => {
    handleIsUserLoggedIn();
    navigateToSignin();
  };

  const handleSignupVisibility = () => {
    navigateToSignup();
  };

  const navigateToSite = () => {
    if (showAuthButton) {
      navigateToHome();
    } else {
      openNewTab(LANDING_PAGE_URL);
    }
  };

  const handleSignout = () => {
    try {
      disconnectSocket();
      appClearStorage();
      dispatch(clearUserState());
      dispatch(updateIsLoggedIn());
      navigateToSignin();
      trackEvent({ category: "signout", label: "Navbar" });
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleIsUserLoggedIn = () => {
    dispatch(updateIsLoggedIn());
  };

  const handleNavigateToProfile = () => {
    navigateToAccountOverview();
  };

  const optionClickHandler = {
    signout: handleSignout,
    signin: handleSigninVisibility,
    profile: handleNavigateToProfile,
    signup: handleSignupVisibility,
  };

  const handleProfileDDToggle = () => {
    setShowProfileDD(!showProfileDD);
  };

  const handleThemeSwitch = (key) => {
    dispatch(setTheme(key));
  };

  const toggleCollapsed = () => {
    dispatch(setCollapsedSidebar(!collapsed));
  };

  const getLogo = () => {
    let logo = LogoBeta;
    if (theme === "dark") {
      logo = LogoBetaDark;
    }
    return logo;
  };

  return (
    <NavbarContainer>
      <ReactNavbar variant="dark" sticky="top">
        <Container
          fluid
          className={classNames({
            "justify-content-center": !showAuthButton,
          })}
        >
          <ReactNavbar.Toggle aria-controls="responsive-navbar-nav" />
          {!isAuthPage && (
            <img
              src={hamburgerIcon}
              className={classNames("hamburger-icon", {
                "d-none": !showAuthButton,
                "d-block d-xl-none d-lg-none d-md-none": showAuthButton,
              })}
              alt="toggle icon"
              onClick={toggleCollapsed}
            />
          )}
          <img
            alt="logo"
            onClick={navigateToSite}
            src={getLogo()}
            className="main-logo"
          />
          {!showAuthButton ||
            (!isWelcomePath && (
              <ReactNavbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-between"
              >
                <div></div>
                {/* <div>
              {showTitle && (
                <div className="d-flex ms-4 align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="strategy-image-wrapper">
                      {fileType == "py" && <img src={pythonRepoIcon} />}
                      {fileType == "js" && <img src={javascriptRepoIcon} />}
                    </div>
                  </div>
                  <div className="repo-title-name ms-2">{title}</div>
                </div>
              )}
            </div> */}
                <Nav>
                  {isLoggedInState && (
                    <div className="logged-in-options">
                      <NotificationSidebar />

                      {theme === THEME_TYPES.DARK ? (
                        <img
                          className="theme-icon"
                          src={CharmSunIcon}
                          alt="dark-light-mode"
                          onClick={() => handleThemeSwitch(THEME_TYPES.LIGHT)}
                        />
                      ) : (
                        <img
                          className="theme-icon"
                          src={CharmMoonLightIcon}
                          alt="dark-light-mode"
                          onClick={() => handleThemeSwitch(THEME_TYPES.DARK)}
                        />
                      )}
                      <img
                        className="notification-separator"
                        src={VerticalSeparatorIcon}
                        alt="notification"
                      />
                    </div>
                  )}
                  {isLoggedInState && (
                    <Dropdown
                      divider={false}
                      show={showProfileDD}
                      options={menu}
                      className={"my-second-step"}
                      onNavClick={handleIsUserLoggedIn}
                      onToggle={handleProfileDDToggle}
                      onOptionClick={(option) =>
                        optionClickHandler[option.key]()
                      }
                      title={
                        <Avatar
                          src={userDetails?.user_icon || avatarIcon}
                          showBorder={isLoggedInState}
                        />
                      }
                    />
                  )}
                  {!isLoggedInState && showAuthButton && (
                    <>
                      <div className="desktop-view">
                        <Button
                          size="small"
                          className="signin-btn"
                          onClick={handleSigninVisibility}
                        >
                          Sign In
                        </Button>
                        <Button
                          size="small"
                          className="signup-btn"
                          onClick={handleSignupVisibility}
                        >
                          Sign Up
                        </Button>
                      </div>
                      <div className="mobile-view">
                        <Dropdown
                          className="logged-out-menu"
                          show={showProfileDD}
                          options={mobileMenu}
                          onNavClick={handleIsUserLoggedIn}
                          onToggle={handleProfileDDToggle}
                          title={
                            <Avatar
                              src={userIcon}
                              showBorder={isLoggedInState}
                            />
                          }
                          onOptionClick={(option) =>
                            optionClickHandler[option.key]()
                          }
                          divider={false}
                        />
                      </div>
                    </>
                  )}
                </Nav>
              </ReactNavbar.Collapse>
            ))}
        </Container>
      </ReactNavbar>
    </NavbarContainer>
  );
};

Navbar.propTypes = {
  toggleCollapsed: PropTypes.func,
  isAuthPage: PropTypes.bool,
};
