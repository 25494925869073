import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    props.changedPath(pathname);
  }, [pathname]);

  return null;
};
