import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  facebookSquareIcon,
  linkedinSquareIcon,
  twitterSquareIcon,
  whatsappSquareIcon,
} from "assets/icons/profile";
import {
  showAllIcon as ShowAllIcon,
  etfFocusedIcon as EtfFocusedIcon,
  dayTradingIcon as DayTradingIcon,
  thematicIcon as ThematicIcon,
  // dcaIcon as DcaIcon,
  smallAccountIcon as SmallAccountIcon,
} from "assets/icons/marketplace";
import { COLOR_GREEN } from "style/theme";
import { cryptoIcon, etfIcon, stocksIcon } from "assets/icons/marketplace";

// env variables
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
export const REACT_APP_VER = process.env.REACT_APP_VERSION;
export const AES_KEY = process.env.REACT_APP_AES_KEY;
export const AWS_RUM_URL = process.env.REACT_APP_AWS_RUM_URL;
export const AWS_GUEST_ROLE_ARN = process.env.REACT_APP_AWS_GUEST_ROLE_ARN;
export const AWS_APP_IDENTITY_POOL = process.env.REACT_APP_IDENTITY_POOL;
export const FRONT_CLIENT_ID = process.env.REACT_APP_FRONT_CLIENT_ID;
export const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const GA_MEASURMENT_ID = process.env.REACT_APP_GA_MEASURMENT_ID;
export const ENV = process.env.REACT_APP_ENV;

// Storage keys
export const TRACKING_ID_KEY = "T:ID";
export const USER_ID_KEY = "U:ID";
export const AUTH_TOKEN_KEY = "A:TOKEN";
export const ASIDE_STATE_KEY = "ASD:STATE";
export const STORAGE_PERSIST_KEY = "A:PERSIST";
export const IS_DEFAULT_GITHUB_KEY = "IDE:ISDEFAULT";
export const THEME_KEY = "A:THEME";
export const BANNER_KEY = "A:BANNER";
export const SNAPTRADE_TIMESTAMP_KEY = "ST:TIMESTAMP";
export const SIGNUP_EMAIL_KEY = "A:SIGNUP_EMAIL";

// URLs
export const WAITING_LIST_LANDING_URL = "https://surmount.ai/waitlist";
export const LANDING_PAGE_URL = "https://surmount.ai/";
export const DOCS_LINK = "https://docs.surmount.ai/";

// Others
export const PAGE_SIZE = 16;
export const FALLBACK_LANG = "en";
export const RADIAN = Math.PI / 180;
export const EXCLUDE_LAYOUT = ["/welcome", "/waiting-list"];
export const appliedPriceptions = [{ key: "closePrice", title: "Close Price" }];
export const movingAverageTypeptions = [
  { key: "simpleAveraging", title: "Simple averaging" },
];
export const STRATEGY_UNSUB_REASONS = [
  { label: "It's too expensive", value: "too_expensive" },
  { label: "Some features are missing", value: "missing_features" },
  { label: "I’m switching to a different service", value: "switched_service" },
  { label: "I don’t use the service enough", value: "unused" },
  {
    label: "Customer service was less than expected",
    value: "customer_service",
  },
  { label: "Ease of use was less than expected", value: "too_complex" },
  { label: "Quality was less than expected", value: "low_quality" },
  { label: "Other", value: "other" },
];

// Validation Regexes
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])[A-Za-z\d@$!%*#?&-._^]{8,}$/;
export const PASSWORD_MSG =
  "Must contain at least 8 characters, with at least one number and one uppercase character";

export const PUBLISH_STATUS = {
  "-1": { title: "In Review", color: "#0A7EFF" },
  1: { title: "Hosted", color: "#0A7EFF" },
};

export const DEPLOY_STATUS = {
  0: {
    title: "Inactive",
    color: "${({ theme }) => theme.global.color.bodyColor}",
  },
  1: {
    title: "In Review",
    color: "#0A7EFF",
  },
  2: { title: "Deployed", color: COLOR_GREEN },
};

export const ASSET_CLASS = [
  { id: "any", title: "Any", icon: null, isActive: true },
  { id: "eft", title: "ETFs", icon: etfIcon, isActive: false },
  { id: "stock", title: "Stocks", icon: stocksIcon, isActive: false },
  { id: "crypto", title: "Crypto", icon: cryptoIcon, isActive: false },
];

export const NUMBER_OF_ASSETS = [
  { id: "any", title: "Any", isActive: true },
  { id: 1, title: "1", isActive: false },
  { id: 2, title: "2", isActive: false },
  { id: 3, title: "3", isActive: false },
  { id: 4, title: "4", isActive: false },
  { id: 5, title: "5", isActive: false },
  { id: 6, title: "6", isActive: false },
  { id: 7, title: "7+", isActive: false },
];

export const SOCIAL_SHARE_BUTTONS = [
  {
    key: "facebook",
    icon: facebookSquareIcon,
    ShareButton: FacebookShareButton,
  },
  {
    key: "linkedin",
    icon: linkedinSquareIcon,
    ShareButton: LinkedinShareButton,
  },
  { key: "twitter", icon: twitterSquareIcon, ShareButton: TwitterShareButton },
  {
    key: "whatsapp",
    icon: whatsappSquareIcon,
    ShareButton: WhatsappShareButton,
  },
];

export const HOST_STEPS = [
  {
    key: "submit",
    title: "Submit Strategy",
    isActive: true,
  },
  {
    key: "auto-review",
    title: "Automated Review",
    isActive: true,
  },
  {
    key: "manual-review",
    title: "Manual Review",
    isActive: false,
  },
  {
    key: "hosted",
    title: "Hosted to the Marketplace",
    isActive: false,
  },
];

export const PUBLISH_STATUS_VALUES = {
  IN_REVIEW: -1,
  PUBLISHED: 1,
  NOT_PUBLISHED: 0,
};

export const TIME_FRAME_OPTIONS = [
  { label: "1M", days: 30, duration: { months: 1 } },
  { label: "3M", days: 90, duration: { months: 3 } },
  { label: "6M", days: 180, duration: { months: 6 } },
  { label: "12M", days: 365, duration: { years: 1 } },
  { label: "3Y", days: 1095, duration: { years: 3 } },
  { label: "5Y", days: 1825, duration: { years: 5 } },
];

export const weightTypeOptions = [
  { key: "equal", title: "Equal" },
  { key: "specified", title: "Specified" },
  { key: "marketCap", title: "Market Cap" },
];

export const BROKER_ICONS = {
  alpaca: "alpaca",
  binanceInternational: "binance_international",
  binanceUs: "binance",
  bitstamp: "bitstamp",
  bittrex: "bittrex",
  cexIo: "cex_io",
  coinbase: "coinbase",
  coinbasePro: "coinbase_pro",
  cryptoCom: "crypto",
  robinhood: "robinhood",
  tda: "tda",
  paper: "surmount_paper",
  "alpaca-paper": "alpaca_paper",
  tdAmeritrade: "tdAmeritrade",
  weBull: "weBull",
  public: "public",
  vanguard: "vanguard",
  etoro: "etoro",
  acorns: "acorns",
  kuCoin: "kuCoin",
  kraken: "kraken",
  krakenDirect: "kraken",
};

export const BROKER_NAMES = {
  alpaca: "Alpaca",
  "alpaca-paper": "Alpaca Paper",
  tda: "TD Ameritrade",
  coinbase: "Coinbase",
  paper: "Surmount Paper",
};

export const CHART_TIME_FRAME = [
  {
    id: "1d",
    title: "1D",
  },
  {
    id: "1w",
    title: "1W",
  },
  {
    id: "1m",
    title: "1M",
  },
  {
    id: "3m",
    title: "3M",
  },
  {
    id: "1y",
    title: "1Y",
  },
  {
    id: "5y",
    title: "5Y",
  },
  {
    id: "all",
    title: "All",
  },
];

export const PERFORMANCE_TIME_FRAME = [
  {
    id: "3M",
    title: "3M",
  },
  {
    id: "YTD",
    title: "YTD",
  },
  {
    id: "1Y",
    title: "1Y",
  },
  {
    id: "3Y",
    title: "3Y",
  },
  {
    id: "5Y",
    title: "5Y",
  },
  {
    id: "Max",
    title: "Max",
  },
];

export const KEY_STATS = {
  mdd: "Maximum drawdown (peak to low) from 0-1 representing 0-100%",
  stdev: "Standard deviation of returns",
  calmar: "Calmar ratio",
  capacity:
    "Strategy alpha capacity, or how much liquidity can be traded using this strategy effectively, in $",
  risk_score:
    "Score ranging from 0-5 representing the risk profile of a strategy, 5 is the riskiest",
  total_trades: "Total number of trades placed over the backtest period",
  losing_trades:
    "Total number of losing trades closed over the backtest period",
  winning_trades:
    "Total number of winning trades closed over the backtest period",
  profit_factor:
    "The expected value when exiting a trade, >1 is good, <1 is bad",
  absolute_drawdown: "Maximum drawdown from the initial capital",
  annualized_return:
    "Returns per year during the backtest, where 0.1 means 10% annual gain",
  annualized_sharpe: "Sharpe ratio, the higher the better",
  consecutive_losing_trades:
    "Consecutive losing trades during the backtest period",
  consecutive_winning_trades:
    "Consecutive winning trades during the backtest period",
};

export const getPortfolioMenu = (isStale) => {
  if (isStale) {
    return [{ key: "1", title: "Remove Portfolio" }];
  }

  return [
    { key: "1", title: "Remove Portfolio" },
    { key: "2", title: "Manage Strategies" },
  ];
};

export const STATUS_CODES = {
  UNAUTHORIZED: 401,
};

export const HELP_CENTER_MENU = [
  {
    name: "FAQs",
    key: "faqs",
  },
  {
    name: "Report a Bug",
    key: "reportBug",
  },
  {
    name: "Contact Us",
    key: "contactUs",
  },
  {
    name: "Request a Feature",
    key: "requestFeature",
  },
  // {
  //   name: "User Guide",
  //   key: "userGuide",
  // },
];

export const FAQs = [
  {
    key: 0,
    title: "What is Surmount?",
    description:
      "Surmount is first of its kind automated investing platform which enables any individual, regardless of their account size or level of technical skillset, to link any equities, crypto, and forex portfolio to automate their investments using using AI-driven, rule based, and/or copy trading strategies. ",
  },
  {
    key: 1,
    title: "Who can use Surmount?",
    description: "Who can use Surmount?",
  },
  {
    key: 2,
    title: "How does Surmount make money?",
    description: "",
  },
  {
    key: 3,
    title: "Why does Surmount exist?",
    description: "",
  },
  {
    key: 4,
    title: "What problems does Surmount solve?",
    description: "",
  },
];

export const ALL_FEATURES = [
  {
    value: "nocode builder",
    label: "No code builder",
  },
  {
    value: "code builder",
    label: "Code builder",
  },
  {
    value: "ai builder",
    label: "AI builder",
  },
  {
    value: "marketplace",
    label: "Marketplace",
  },
  {
    value: "live trading",
    label: "Live trading",
  },
  {
    value: "broker connection",
    label: "Broker connection",
  },
  {
    value: "billing",
    label: "Billing",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const USER_GUIDE_MENU = [
  {
    key: "link-portfolio",
    name: "Link a Portfolio",
  },
  {
    key: "manage-portfolio",
    name: "Manage a Portfolio",
  },
  {
    key: "create-strategy",
    name: "Create a Strategy",
  },
  {
    key: "purchase-strategy",
    name: "Purchase a Strategy",
  },
  {
    key: "host-strategy",
    name: "Host a Strategy",
  },
  {
    key: "subscription-plans",
    name: "Subscription Plans",
  },
];

export const helpcenterRequestTypes = {
  requestFeature: "REQUEST_FEATURE",
  contactUs: "CONTACT_US",
};

export const AWS_RUM_KEYS = {
  APPLICATION_ID: "240ef1cd-ce6b-4ef4-8b40-ba553ba77227",
  APPLICATION_VERSION: "1.0.0",
  APPLICATION_REGION: "ap-southeast-2",
};

export const THEME_TYPES = {
  DARK: "dark",
  LIGHT: "light",
};

export const MARKETPLACE_SORT_OPTIONS = [
  { value: "popularity", label: "Popularity" },
  { value: "annualized_return", label: "Annualized Returns" },
  { value: "annualized_sharpe", label: "Annualized Sharpe" },
  { value: "risk_score", label: "Risk Score" },
  { value: "trades_per_day", label: "Trades Per Day" },
  { value: "total_return", label: "Total Return" },
  { value: "mdd", label: "Max Drawdown" },
];

export const MARKETPLACE_TAB_OPTIONS = (theme) => [
  {
    key: "popularity",
    name: "Show all",
    Icon: () => <ShowAllIcon fill={theme.aside.font.color} />,
  },
  {
    key: "equity",
    name: "Equity Focused",
    Icon: () => <EtfFocusedIcon fill={theme.aside.font.color} />,
    iconClass: "custom-g-path",
  },
  // { key: "crypto", name: "Crypto Focused", icon: cryptoFocusedIcon },
  {
    key: "pdt",
    name: "Day Trading",
    Icon: () => <DayTradingIcon fill={theme.aside.font.color} />,
  },
  {
    key: "thematic",
    name: "Thematic",
    Icon: () => <ThematicIcon fill={theme.aside.font.color} />,
  },
  {
    key: "small_accounts",
    name: "Small Accounts",
    Icon: () => <SmallAccountIcon fill={theme.aside.font.color} />,
  },
  // {
  //   key: "dca",
  //   name: "DCA Strategies",
  //   Icon: () => <DcaIcon fill={theme.aside.font.color} />,
  // },
];

export const SNAPTRADE_ALLOWED_ORIGINS = [
  "https://app.snaptrade.com",
  "https://app.staging.snaptrade.com",
  "https://app.local.snaptrade.com",
  "https://connect.snaptrade.com",
  "http://localhost:4001",
  "https://dev-fe.surmount.ai/",
  "https://staging-fe.surmount.ai/",
  "https://app.surmount.ai/",
];

export const HEAT_MAP_COLOR_SHADES = [
  "#ebedf0",
  "#FF3737",
  "#FF8D8D",
  "#86FBA6",
  "#23E759",
];

export const NCB_INDICATOR_VALIDATION = [1, 500];

export const EARLY_USER_MESSAGE =
  "Thank you for being an early user! You're getting all the benefits of Surmount Premium for free for a limited time!";
