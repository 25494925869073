import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { arrowUpIcon } from "assets/icons/marketplace";
import { scrollTopValueSelect } from "Layout/slice/selector";
import { ScrollButtonContainer } from "./ScrollButton.styles";

const ScrollButton = ({ handleScrollTop }) => {
  const [visible, setVisible] = useState(false);
  const scrollTopValue = useSelector(scrollTopValueSelect);

  useEffect(() => {
    if (scrollTopValue > 300) {
      setVisible(true);
    } else if (scrollTopValue <= 300) {
      setVisible(false);
    }
  }, [scrollTopValue]);

  return (
    visible && (
      <ScrollButtonContainer onClick={handleScrollTop}>
        <img src={arrowUpIcon} alt="backToTop" />
      </ScrollButtonContainer>
    )
  );
};

ScrollButton.propTypes = {
  handleScrollTop: PropTypes.func,
};

export default ScrollButton;
