import PropTypes from "prop-types";
import { Toast as BSToast, ToastContainer } from "react-bootstrap";

export const Toast = ({
  body,
  delay,
  show,
  onClose,
  position,
  type,
  header,
}) => {
  const getHeaderText = () => {
    if (header) {
      return header;
    } else if (type === "error") {
      return "Error";
    }
    return "Notification";
  };

  return (
    <ToastContainer position={position}>
      <BSToast show={show} onClose={onClose} delay={delay} autohide>
        <BSToast.Header className={type}>
          <strong className="me-auto">{getHeaderText()}</strong>
        </BSToast.Header>
        <BSToast.Body>{body}</BSToast.Body>
      </BSToast>
    </ToastContainer>
  );
};

Toast.propTypes = {
  body: PropTypes.string,
  delay: PropTypes.number,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.string,
  type: PropTypes.oneOf(["info", "error"]),
  header: PropTypes.string,
};

Toast.defaultProps = {
  delay: 3000,
  position: "top-end",
  type: "info",
  show: true,
  header: "",
};
