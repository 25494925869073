import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { avatarIcon, cameraIcon } from "assets/images";
import { setUserDetails, toast } from "Layout/slice";
import { Title } from "style/components/Title.styles";
import { updateProfile, uploadUserIcon } from "../service";
import { parseErrorMessage } from "utils";
import { Loader } from "components/Loader";
import { userDetailsState } from "Layout/slice/selector";

const ProfileDetailsHeader = () => {
  const dispatch = useDispatch();
  const profileDetails = useSelector(userDetailsState);

  const fileUploader = useRef(null);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleImageUpload = async (e) => {
    try {
      setIsImageLoading(true);
      const data = new FormData();
      data.append("user_icon", e.target.files[0]);
      const response = await uploadUserIcon(data);
      let values = {};
      Object.assign(values, profileDetails);
      values.user_icon = response?.image_url;
      await updateProfile(values);
      dispatch(setUserDetails(values));
      dispatch(
        toast({
          type: "info",
          body: "Profile Image uploaded successfully",
        })
      );
    } catch (error) {
      setIsImageLoading(false);
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <div className="profile-header">
      {isImageLoading && <Loader className="image-loader" />}

      {profileDetails !== null && (
        <>
          {!isImageLoading && (
            <div className="img-profile" role="button" tabIndex={0}>
              <img
                src={profileDetails?.user_icon || avatarIcon}
                alt="icon"
                height={100}
                width={100}
                className="profile"
              />
              <img
                src={cameraIcon}
                alt="icon"
                className="icon-camera"
                onClick={() => fileUploader.current.click()}
              />

              <input
                ref={fileUploader}
                className="d-none"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
          )}
          <Title className="name">{`${profileDetails.first_name || "-"} ${
            profileDetails.last_name || ""
          }`}</Title>
        </>
      )}
    </div>
  );
};

export default ProfileDetailsHeader;
