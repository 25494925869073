import { Popover } from "react-bootstrap";
import styled from "styled-components";

export const NotificationPopover = styled(Popover)`
  &.popover {
    max-width: 450px;
    width: 450px;
    background: ${({ theme }) => theme.dropdown.bg.primary};
    height: calc(100vh - 80px);
    overflow: scroll;
  }

  .body-wrapper {
    padding: 0 0 10px;

    .list-group-item {
      background-color: ${({ theme }) => theme.input.bg.primary};
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      border-radius: 10px;
      margin: 0px 4px 11px 7px;
      min-height: 70px;
      padding-top: 13px;
    }

    .seen {
      .dot {
        background-color: ${({ theme }) => theme.global.color.bodyColor};
      }
    }

    .no-data {
      background: ${({ theme }) => theme.dropdown.bg.primary};
      opacity: 0.5;
    }
  }

  .list-group {
    border-radius: 0;
  }

  .popover-arrow::after {
    border-bottom-color: ${({ theme }) => theme.dropdown.bg.primary};
  }

  .popover-header {
    background: ${({ theme }) => theme.dropdown.bg.primary};
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:before {
      border: none;
    }

    .mobile-view {
      padding-top: 0;
    }
  }

  .mark-as-read {
    text-align: right;
    margin-bottom: 10px;
    padding: 0 10px;

    button {
      padding: 8px 18px;
      border: none;
      font-size: 14px !important;
      margin-right: 0;
      color: ${({ theme }) => theme.button.bg.primary};
    }
  }

  .actions {
    text-align: center;

    button {
      padding: 8px 18px;
      border: none;
      font-size: 14px !important;
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      // color: ${({ theme }) => theme.button.bg.primary};
    }
  }
`;

export const NotificationCardContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .dot {
    min-height: 9px;
    margin-right: 8px;
    min-width: 9px;
    background-color: #0a76e1;
    border-radius: 50%;
    display: inline-block;
  }

  .description {
    margin: 0 12px;
    cursor: pointer;
  }

  .time {
    opacity: 0.5;
  }

  .profile {
    height: 40px;
    width: 40px;
  }

  .action .btn-action {
    padding: 6px 20px;
  }

  .no-avatar img {
    padding: 5px;
  }

  .icon-close:focus {
    box-shadow: none;
  }
`;

export const NotificationContainer = styled.div`
  h2 {
    margin-bottom: 0;
  }

  .wrapper {
    margin: 0 -10px;
    margin-top: 12px;

    .list-group-item {
      background-color: ${({ theme }) => theme.input.bg.primary};
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      border-radius: 10px;
      margin: 0px 8px 11px 7px;
      min-height: 70px;
      padding-top: 13px;
    }

    .list-group {
      border-radius: 0;
    }

    .seen {
      .dot {
        background-color: ${({ theme }) => theme.global.color.bodyColor};
      }
    }

    .no-data {
      text-align: center;
      opacity: 0.5;
    }
  }

  .mark-as-read {
    text-align: right;
    margin-bottom: 10px;
    padding: 0 10px;

    button {
      padding: 8px 18px;
      border: none;
      font-size: 14px !important;
      margin-right: 0;
      color: ${({ theme }) => theme.button.bg.primary};
    }
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const NotificationCount = styled.div`
  position: relative;

  .count {
    position: absolute;
    background: ${({ theme }) => theme.button.bg.primary};
    border-radius: 50%;
    padding: 0 4px;
    font-size: 10px;
    font-weight: 600;
    border: 2px solid ${({ theme }) => theme.global.bg.primary};
    bottom: 4px;
    right: -10px;
  }
`;
