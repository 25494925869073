import { get } from "services/httpService";

const SERVICE_URLS = {
  getBrokers: () => "supported-brokers/",
  getBrokerPortfolio: (brokerName) => `user/broker/${brokerName}/`,
  getTrackingId: () => `user/anonymous-tracking-id/`,
  getBrokerOAuthURL: (qs) => `broker-accounts/oauth-url/?${qs}`,
  getFrontIframeUrl: () => `broker/front/iframe`,
  getBannerDetails: () => `user/banner/`,
};

export const getTrackingId = () => get(SERVICE_URLS.getTrackingId());
export const getBrokers = () => get(SERVICE_URLS.getBrokers());
export const getBrokerPortfolio = (brokerName) =>
  get(SERVICE_URLS.getBrokerPortfolio(brokerName));

export const getBrokerOAuthURL = (qs) =>
  get(SERVICE_URLS.getBrokerOAuthURL(qs));

export const getFrontIframeUrl = () => get(SERVICE_URLS.getFrontIframeUrl());

export const getBannerDetails = () => get(SERVICE_URLS.getBannerDetails());
