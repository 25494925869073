import { Col, Row } from "react-bootstrap";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  contains,
  makeQueryParams,
  openNewTab,
  parseErrorMessage,
} from "utils";
import { toast } from "Layout/slice";
import PlanCard from "shared/PlanCard";
import { Table } from "components/Table";
import { Loader } from "components/Loader";
import { Button } from "components/Button";
import { PAGE_SIZE } from "utils/constants";
import { useNavigation } from "utils/navigate";
import { getBillingHistory } from "../service";
import { billingHistoryColumns } from "../columns";
import { userDetailsState } from "Layout/slice/selector";
import { HistoryMobileCard } from "./HistoryMobileCard";
import { PlanAndBillingContainer } from "./PlanAndBilling.styles";
import { Heading } from "style/components/Heading.styles";

export const PlanAndBilling = () => {
  const dispatch = useDispatch();

  const { navigateToPlans, navigateToProfilePayment } = useNavigation();

  const user = useSelector(userDetailsState);
  const [isBillHistoryLoading, setBillHistoryLoading] = useState(false);
  const [totalHistory, setTotalHistory] = useState(0);
  const [historyActivePage, setHistoryActivePage] = useState(0);
  const [totalBillingPages, setTotalBillingPages] = useState(0);
  const [billingHistory, setBillingHistory] = useState([]);

  useEffect(() => {
    getMyBillingHistory();
  }, []);

  // Get My Billing History
  const getMyBillingHistory = async (page = 1, append = false) => {
    try {
      setBillHistoryLoading(true);
      const qs = makeQueryParams({ page, size: PAGE_SIZE });
      const response = await getBillingHistory(qs);
      setTotalHistory(response.total_count);
      setTotalBillingPages(response.total_pages);
      setHistoryActivePage(page);
      if (append) {
        setBillingHistory([...billingHistory, ...response.data]);
      } else {
        setBillingHistory(response.data);
      }
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setBillHistoryLoading(false);
    }
  };

  const handleLoadMoreHistoryClick = () => {
    const page = historyActivePage + 1;
    if (page <= totalHistory) {
      getMyBillingHistory(page, true);
    }
  };

  const handleOpenInvoice = (url) => {
    if (!url || url === "No invoice") {
      dispatch(
        toast({
          type: "error",
          body: "No invoice found",
        })
      );
    } else {
      openNewTab(url);
    }
  };

  const handleButtonClick = () => {
    if (
      contains(user?.surmount_plan?.name, "Premium") &&
      user?.payment_failed
    ) {
      return navigateToProfilePayment();
    }

    navigateToPlans();
  };

  const getButtonLabel = () => {
    if (contains(user.surmount_plan.name, "Free")) {
      return "Get Premium";
    } else if (
      contains(user?.surmount_plan?.name, "Premium") &&
      user?.payment_failed
    ) {
      return "Update payment method";
    }
  };

  return (
    <PlanAndBillingContainer>
      {/* Current Plan */}
      <div className="plan-wrapper">
        <Heading>Your Plan</Heading>
        <div className="divider mt-1 mb-3"></div>

        {/* <Tooltip
          placement="auto"
          tooltipValue="Surmount Premium is coming soon! Rest assured you are getting the highest benefits until we switch over"
        >
          <Button variant="secondary">Get Surmount Premium</Button>
        </Tooltip> */}
        <Row>
          <Col xl={9} lg={12} xs={12}>
            {user && user.surmount_plan ? (
              <PlanCard />
            ) : (
              <>No plan purchased yet</>
            )}
            <Button className="mt-4 d-none" onClick={handleButtonClick}>
              {getButtonLabel()}
            </Button>
          </Col>
        </Row>
      </div>

      {/* Receipts */}
      <Heading>Receipts</Heading>
      <div className="divider mt-1 mb-3"></div>
      <div className="desktop-view">
        {/* Billing History */}
        <div className="billing-history-wrapper">
          <Table
            isLoading={isBillHistoryLoading}
            columns={billingHistoryColumns(handleOpenInvoice)}
            data={billingHistory}
            totalRecords={totalHistory}
            notFoundText="No transaction history"
            customPagination
            handlePageChange={getMyBillingHistory}
            totalPages={totalBillingPages}
          />
        </div>
      </div>

      <div className="mobile-view">
        {isBillHistoryLoading ? (
          <Loader />
        ) : (
          <>
            {billingHistory.length === 0 && <div>No transaction history</div>}

            {billingHistory.map((subscription) => (
              <Fragment key={subscription.subscription_id}>
                <HistoryMobileCard subscription={subscription} />
              </Fragment>
            ))}

            {historyActivePage < totalBillingPages && (
              <div className="link-more" onClick={handleLoadMoreHistoryClick}>
                Load More
              </div>
            )}
          </>
        )}
      </div>
    </PlanAndBillingContainer>
  );
};
