import styled from "styled-components";

export const PlanContainer = styled.div`
  .desktop-view {
    .title {
      display: none;
    }
  }

  .mobile-view {
    @media (max-width: 768px) {
      .title {
        display: block;
      }
    }
  }
`;

export const NewPlanContainer = styled.div`
  min-height: 400px;

  h2 {
    margin-bottom: 5px;
  }

  .plan-wrapper {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
  }

  .plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 576px) {
    .plan-wrapper {
      flex-direction: column;
    }
  }
`;
