import styled from "styled-components";

export const BannerContainer = styled.div`
  top: 0;
  z-index: 5;
  position: sticky;

  .alert {
    border: 0;
    margin-bottom: 0;
    border-radius: 0;
    padding: 10px 1rem;
    color: ${({ theme }) => theme.button.font.primary};
    background-color: ${({ theme }) => theme.button.bg.primary};

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 14px;

      .message-wrapper {
        display: flex;
        align-items: center;

        .btn-action {
          background-color: ${({ theme }) => theme.banner.button.bg};
          color: ${({ theme }) => theme.banner.button.color};
          text-transform: capitalize;
          margin-left: 16px;
          font-size: 13px;
          padding: 10px 15px;
        }
      }

      .btn-close {
        opacity: 1;
      }
    }
  }

  @media (max-width: 720px) {
    .alert .wrapper {
      .message-wrapper {
        flex-direction: column;
        align-items: flex-start;

        .btn-action {
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }
`;
