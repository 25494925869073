import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { toast } from "Layout/slice";
import { trackEvent } from "utils/tracking";
import { paymentCheckout } from "../service";
import { openNewTab, parseErrorMessage } from "utils";
import SubscriptionPayment from "shared/SubscriptionPayment";

export const PaymentDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const onPayNow = async () => {
    try {
      setIsLoading(true);
      const { checkout_url } = await paymentCheckout(id, pathname);
      trackEvent({
        action: "submit",
        category: "plan_purchase",
        label: "Payment",
      });
      openNewTab(checkout_url);
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return <SubscriptionPayment isLoading={isLoading} handlePayNow={onPayNow} />;
};
