import { createAsyncThunk } from "@reduxjs/toolkit";

import { parseErrorMessage } from "utils";
import { getProfile } from "features/Account/service";

export const fetchUserDetails = createAsyncThunk(
  "user/details",
  async (params, thunkAPI) => {
    try {
      const response = await getProfile();
      return response;
    } catch (error) {
      const msg = parseErrorMessage(error);
      return thunkAPI.rejectWithValue({ error: msg });
    }
  }
);
