import styled from "styled-components";

export const PlanCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
  box-shadow: ${({ theme }) => theme.card.shadow};

  .plan-header {
    width: 100%;
    padding: 5px 12px;
    color: ${({ theme }) => theme.card.color.secondary};
    background-color: ${({ theme }) => theme.button.bg.primary};
    border-top-right-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    border-top-left-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
  }

  .plan-name {
    width: 100%;
    padding: 4.5rem 1rem;
    background-color: ${({ theme }) => theme.button.bg.tertiary};
    font-weight: ${({ theme }) => theme.constants.FONT_WEIGHT_700};
    font-size: ${({ theme }) => theme.constants.FONT_SIZE_28};
    border-top-right-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    border-top-left-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
  }

  .plan-details {
    width: 100%;
    min-height: 200px;
    position: relative;
    background: ${({ theme }) => theme.card.bg.quaternary};
    color: ${({ theme }) => theme.global.extraInfo.font.primary};
    border-bottom-left-radius: ${({ theme }) =>
      theme.constants.BORDER_RADIUS_8};
    border-bottom-right-radius: ${({ theme }) =>
      theme.constants.BORDER_RADIUS_8};
    padding: 2rem 1.5rem 1.5rem;
    border: 3px solid ${({ theme }) => theme.button.bg.tertiary};

    a {
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      font-size: 14px;
      position: absolute;
      bottom: 1rem;
    }

    h4 {
      font-weight: 700;
      margin-bottom: 1.2rem;
    }

    .price-divider {
      margin: 5px;
    }

    .line-through {
      position: relative;
      display: inline-block;
    }

    .line-through::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1.5px;
      background-color: ${({ theme }) => theme.global.extraInfo.font.color};
      transform: rotate(-50deg);
      transform-origin: 50% 50%;
    }

    .amount-wrapper {
      font-size: 28px;
      font-weight: 600;
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      margin-bottom: 0;
    }

    .duration {
      font-size: 12px;
      font-weight: 400;
      vertical-align: middle;
      display: inline-block;
      margin-left: 8px;
    }

    .benefit {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      margin-top: 8px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 12px;

      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        &:before {
          content: "✓";
          color: green;
          font-weight: 800;
          margin-right: 10px;
        }
      }
    }

    .divider {
      border: 1px solid ${({ theme }) => theme.divider.bg.secondary};
    }

    .get-start-btn {
      margin-top: 20px;
      width: 100%;
      float: right;
    }

    .card-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .ending {
        font-weight: bold;

        .brand {
          text-transform: capitalize;
        }
      }

      img {
        height: 22px;
      }

      .date {
        color: ${({ theme }) => theme.global.color.quaternary};
      }

      .btn-update {
        font-weight: bold;
        color: ${({ theme }) => theme.global.color.quaternary};
        margin-left: 3rem;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .plan-name {
      font-size: 24px;
      padding: 3.5rem 1rem;
    }

    .plan-details .row {
      gap: 15px;
    }
  }
`;
