import styled from "styled-components";

export const DropdownContainer = styled.div`
  display: contents;

  .action-dropdown {
    background: ${({ theme }) => theme.dropdown.bg.quaternary} !important;
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8} !important;
    border: none !important;
    font-weight: 400 !important;
  }

  .dropdown-menu {
    margin-top: 0.75em;
    background: ${({ theme }) => theme.dropdown.bg.quaternary} !important;
    border: none !important;

    position: absolute !important;
    inset: 0px 0px auto auto !important;
    border: none !important;
    transform: translate3d(-1.5px, 35.5px, 0px) !important;
    border-radius: 10px !important;

    &::after {
      content: "";
      position: absolute;
      top: -1em;
      right: 1.875em;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 15px solid ${({ theme }) => theme.dropdown.bg.primary};

      filter: drop-shadow(
        0px 0px 2px ${({ theme }) => theme.input.border.color}
      );
    }
    .dropdown-item {
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      text-align: center;

      &:hover {
        color: ${({ theme }) => theme.global.color.bodyColor};
      }
      .icon {
        margin-right: 0.5rem;
      }
    }
  }

  .dropdown-toggle-split {
    border-radius: 12px !important;
    position: absolute !important;
    right: 0 !important;
  }

  .dropdown-toggle::after {
    display: none;
    &::after {
      display: inline-block;
    }
  }
  .dropdown-btn {
    text-align: start;
  }
`;

export const CustomDropdownContainer = styled.div`
  .floated-label {
    position: relative;

    label {
      position: absolute;
      top: -20px;
      left: 0px;
      padding: 0px 5px;
    }
  }
  .btn-select {
    min-width: 95px;
  }
  .popover {
    top: -5px !important;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    border: 1px solid #ffffff;
    border-radius: 6px;

    .popover-header,
    .popover-body {
      background: #191b20;
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      border-radius: 6px;
    }

    .popover-arrow {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .floated-label label {
      top: -14px;
      font-size: 8px;
    }
  }
`;
