export const noCodeBuilderReducers = {
  addStrategy: (state, action) => {
    state.strategy.push(action.payload);
  },
  deleteStrategyAtIndex: (state, action) => {
    state.strategy.splice(action.payload.inx, 1);
  },
  addStatement: (state, action) => {
    state.strategy[action.payload.inx].conditions.push(action.payload.data);
  },
  updateStatementAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].conditions[action.payload.inx] =
      action.payload.data;
  },
  deleteStatementAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].conditions.splice(
      action.payload.inx,
      1
    );
  },
  addThenAction: (state, action) => {
    state.strategy[action.payload.inx].if_action.steps.push(
      action.payload.data
    );
  },
  deleteThenActionAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].if_action.steps.splice(
      action.payload.inx,
      1
    );
  },
  updateThenActionAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].if_action.steps[action.payload.inx][
      action.payload.key
    ] = action.payload.value;
  },
  updateElseActionAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].else_action.steps[
      action.payload.inx
    ][action.payload.key] = action.payload.value;
  },
  addElseAction: (state, action) => {
    state.strategy[action.payload.inx].else_action.steps.push(
      action.payload.data
    );
  },
  deleteElseActionAtIndex: (state, action) => {
    state.strategy[action.payload.mainInx].else_action.steps.splice(
      action.payload.inx,
      1
    );
  },
  updateSelectedTargetAssets: (state, action) => {
    state.assets = action.payload;
  },
  deleteAssetAtIndex: (state, action) => {
    state.assets.splice(action.payload.inx, 1);
  },
  updateAll: (state, action) => {
    if (!state.interval && !state.assets.length && !state.strategy.length) {
      state.interval = action.payload?.interval ?? "";
      state.assets = action.payload?.assets ?? [];
      state.strategy = action.payload?.strategy ?? [];
    }
  },
  updateInterval: (state, action) => {
    state.interval = action.payload;
  },
  clearNcbState: (state) => {
    state.interval = "";
    state.assets = [];
    state.strategy = [];
    state.isLoading = false;
  },
  updateIsLoading: (state, action) => {
    state.isLoading = action.payload;
  },
};
