import { useFormik } from "formik";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resendVerification,
  updateAndverifyPhone,
  updatePhone,
} from "features/Account/service";
import {
  codeInitialValues,
  codeValidationSchema,
  phoneInitialValues,
  phoneValidationSchema,
} from "./validation";
import { parseErrorMessage } from "utils";
import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { Form, Input } from "components/Form";
import { setUserDetails, toast } from "Layout/slice";
import { userDetailsState } from "Layout/slice/selector";
import { ChangePhoneContainer } from "./ChangePhone.styles";

const ChangePhone = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const profileDetails = useSelector(userDetailsState);

  const [showCode, setShowCode] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let intervalId;

    if (seconds > 0) {
      setResendDisabled(true);
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
      setResendDisabled(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  const handleResendClick = async () => {
    try {
      await resendVerification({
        type: "sms",
        email: profileDetails.email,
      });
      setSeconds(20);
      dispatch(
        toast({
          type: "info",
          body: "The code has been sent!",
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleSubmitPhone = async (values) => {
    try {
      const unmaskedPhone = values.phone.replace(/\D/g, "");
      await updatePhone({
        phone: `+${unmaskedPhone}`,
      });
      setSeconds(20);
      setShowCode(true);
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleCodeSubmit = async (values) => {
    try {
      const unmaskedPhone = phoneFormik.values.phone.replace(/\D/g, "");

      const response = await updateAndverifyPhone({
        mfa_code: values.code,
        phone: `+${unmaskedPhone}`,
      });
      setShowCode(false);
      dispatch(setUserDetails({ ...profileDetails, ...response }));
      onSuccess();
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const phoneFormik = useFormik({
    validationSchema: phoneValidationSchema,
    initialValues: phoneInitialValues(profileDetails),
    onSubmit: handleSubmitPhone,
  });

  const codeFormik = useFormik({
    onSubmit: handleCodeSubmit,
    initialValues: codeInitialValues,
    validationSchema: codeValidationSchema,
  });

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <ChangePhoneContainer>
      <Form className="phone-form" onSubmit={phoneFormik.handleSubmit}>
        <span className="control">
          <InputMask
            mask="+1 (999) 999-9999"
            maskChar="_"
            placeholder="+1 (___) ___-____"
            className="phone-input mr-3"
            {...phoneFormik.getFieldProps("phone")}
          />
          {!showCode && (
            <Button
              type="submit"
              className="verify-btn"
              size={"small"}
              disabled={!phoneFormik.isValid || !phoneFormik.values.phone}
            >
              {phoneFormik.isSubmitting ? (
                <Loader className="on-btn" />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </span>
        {phoneFormik.touched.phone && phoneFormik.errors.phone && (
          <div className="form-error">{phoneFormik.errors.phone}</div>
        )}
      </Form>

      {showCode && (
        <Form className="code-form" onSubmit={codeFormik.handleSubmit}>
          <Input
            label=""
            type="text"
            placeholder="Code"
            {...codeFormik.getFieldProps("code")}
          />

          {codeFormik.touched.code && codeFormik.errors.code ? (
            <p className="form-error">{codeFormik.errors.code}</p>
          ) : null}

          <div className="verify-button-wrapper">
            <Button
              type="submit"
              className="verify-btn"
              disabled={!codeFormik.isValid}
            >
              {codeFormik.isSubmitting ? (
                <Loader className="on-btn" />
              ) : (
                "Verify"
              )}
            </Button>
            <Button
              type="button"
              variant="secondary"
              disabled={resendDisabled}
              onClick={handleResendClick}
            >
              {seconds > 0 ? `Resend in ${formattedSeconds}` : "Resend"}
            </Button>
          </div>
        </Form>
      )}
    </ChangePhoneContainer>
  );
};

ChangePhone.propTypes = {
  onSuccess: PropTypes.func,
};

export default ChangePhone;
