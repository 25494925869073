export const sortByUnitAmount = (a, b) =>
  a.price.unit_amount - b.price.unit_amount;

export const getFormattedPlansData = (plans) => {
  const descriptionData = [];

  const _tooltipContent = {
    Backtesting: "Maximum # of backtest you can run per month",
    "Signal Notifications":
      "Receive notifcations whenever your strategy place a trade",
    "Active Strategies":
      "Maximum # of active strategies you can run simultaneously across portfolio",
    Timeframes: "The minimum timeframe you can consider for trading strategies",
    Portfolios:
      "Maximum # of external portfolios you can link to & manage on surmount",
  };

  for (let i = 0; i < plans.length; i++) {
    const plan = plans[i];
    const planName = plan.name;
    const description = JSON.parse(plan.description);
    const keys = Object.keys(description);

    keys.forEach((key) => {
      const value = description[key];
      const alreadyExist = descriptionData.find((i) => i.title === key);

      if (alreadyExist) {
        alreadyExist[planName] = value;
      } else {
        if (key !== "card") {
          descriptionData.push({
            title: key,
            [planName]: value,
            tooltipContent: _tooltipContent[key] ?? null,
            type: "text",
          });
        }
      }
    });
  }
  descriptionData.push({
    title: "",
    tooltipContent: "",
    type: "button",
    plans,
  });

  return descriptionData;
};
