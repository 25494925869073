import styled from "styled-components";

export const ScrollButtonContainer = styled.div`
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 1;

  img {
    height: 50px;
    width: 50px;
    cursor: pointer;
    padding: 15px;
    background: ${({ theme }) => theme.input.bg.primary};
    box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.button.bg.primary};
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
  }
`;
