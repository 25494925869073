import { createSlice } from "@reduxjs/toolkit";
import { noCodeBuilderReducers } from "./reducer";

const initialState = {
  interval: "",
  strategy: [],
  assets: [],
  isLoading: false,
};

export const noCodeBuilderSlice = createSlice({
  name: "noCodeBuilder",
  initialState,
  reducers: noCodeBuilderReducers,
});

export const {
  addThenAction,
  addElseAction,
  deleteThenActionAtIndex,
  deleteElseActionAtIndex,
  addStatement,
  deleteStatementAtIndex,
  addStrategy,
  deleteStrategyAtIndex,
  updateThenActionAtIndex,
  updateStatementAtIndex,
  updateSelectedTargetAssets,
  updateElseActionAtIndex,
  updateAll,
  updateInterval,
  clearNcbState,
  updateIsLoading,
  deleteAssetAtIndex,
} = noCodeBuilderSlice.actions;

export default noCodeBuilderSlice.reducer;
