import styled from "styled-components";

export const ButtonContainer = styled.button`
  border: 0;
  color: ${({ theme }) => theme.button.font.primary};
  font-weight: ${({ theme }) => theme.constants.FONT_WEIGHT_500};
  border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};

  &.button-primary {
    background-color: ${({ theme }) => theme.button.bg.primary};
    &:hover {
      background-color: ${({ theme }) => theme.button.hover.color};
      box-shadow: 1px 1px 3px 0px #929292;
    }
  }
  &.button-secondary {
    background: ${({ theme }) => theme.button.bg.secondary};
    color: ${({ theme }) => theme.button.font.secondary};
  }
  &.button-small {
    font-size: 12px;
    padding: 10px 16px;
  }
  &.button-medium {
    font-size: 14px;
    padding: 11px 20px;
  }
  &.button-large {
    font-size: 16px;
    padding: 12px 24px;
  }
`;
