import styled from "styled-components";

import { bgGradient, bgLight } from "assets/images";

export const LayoutContainer = styled.div`
  .app {
    height: 100%;
    display: flex;
    position: relative;
    background: ${({ theme }) => theme.global.bg.primary};
    background-image: url("${bgGradient}");
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;

    &.auth-app {
      background: ${({ theme }) => theme.global.bg.auth};
      background-image: none;
    }

    .col-3:first-child {
      padding-right: 0;
    }
    > .col-9 {
      padding-left: 0;
    }
    .dropdown-menu {
      background: ${({ theme }) => theme.dropdown.bg.primary} !important;
      border: 1px solid ${({ theme }) => theme.input.border.color} !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.22) !important;
      border-radius: ${({ theme }) =>
        theme.constants.BORDER_RADIUS_8} !important;
      padding: 8px 14px !important;

      .dropdown-divider {
        border-top: 1px solid ${({ theme }) => theme.global.border.secondary};
        margin: 0.4em 0.75em;
      }

      .dropdown-item {
        color: ${({ theme }) => theme.dropdown.font.primary};
        opacity: 0.7;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;

        &:hover {
          background: ${({ theme }) => theme.dropdown.hover.primary};
          border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
        }
      }
    }
    .app-content {
      display: flex;
      width: 100%;

      .sticky-top {
        z-index: 1;
      }

      .screen {
        width: 100%;
        height: auto;
        /* overflow: auto; */
        margin-left: 100px;
      }
    }
    .content {
      overflow-y: auto;
      height: calc(100vh - 90px);
      max-height: 100%;
      padding: 0 2em 0.5em 1.5em;

      &.full-height {
        height: 100vh;
        padding-bottom: 0;
      }

      &.disabled-scroll {
        overflow-y: hidden;
      }
    }
  }
  .theme-light {
    background-image: url("${bgLight}");
  }
  .theme-light .content,
  .theme-dark .content {
    padding-bottom: 25px;
  }
  .theme-light .content,
  .theme-dark .content {
    padding-bottom: 25px;
  }
  .theme-light .content,
  .theme-dark .content {
    padding-bottom: 25px;
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .app-content {
      padding-top: 30px !important;
    }
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    .app-content {
      padding-top: 30px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .screen .content.disabled-scroll {
      overflow-y: auto;
    }

    .app .app-content .screen {
      margin-left: 0;
      .content {
        padding: 16px;
      }
    }
  }
`;
