import {
  sub as fnsSub,
  format as fnsFormat,
  getYear as fnsGetYear,
  isAfter as fnsIsAfter,
  fromUnixTime as fnsFromUnixTime,
  differenceInDays as fnsDifferenceInDays,
} from "date-fns/esm";

// Date time format
export const DATETIME_12HR_FORMAT = "MMM dd, yy - p";

// Date formats
export const DATE_FORMAT = "MMM dd, yyyy";
export const MONTH_YEAR_FORMAT = "MMM YYYY";
export const MONTH_DATE_YEAR_FORMAT = "MM/dd/yy";

// Timw formats
export const TIME_12HR_FORMAT = "hh:mm A";

export const unixToDateTime = (date, format = DATETIME_12HR_FORMAT) => {
  try {
    if (date && new Date(date) instanceof Date && !isNaN(date)) {
      return fnsFormat(fnsFromUnixTime(date), format);
    }
    return null;
  } catch (error) {
    console.error("unixToDateTime: ", error.message);
  }
};

export const convertTimestampToDate = (value, timeframe, format) => {
  if (!value) {
    return "";
  }
  if (timeframe === "1d") {
    return unixToDateTime(value, TIME_12HR_FORMAT);
  } else if (format) {
    return unixToDateTime(value, format);
  } else {
    return unixToDateTime(value, DATE_FORMAT);
  }
};

export const format = (format = DATETIME_12HR_FORMAT, date = new Date()) => {
  return fnsFormat(date, format);
};

export const sub = (duration, date = new Date()) => {
  return fnsSub(date, duration);
};

export const getYear = (date = new Date()) => {
  return fnsGetYear(date);
};

export const momentToFormat = (momentObject, format = "X") => {
  return momentObject.format(format);
};

export const momentToDate = (momentObject) => {
  return momentObject.toDate();
};

export const unixSecToMillisec = (date) => {
  if (date && new Date(date) instanceof Date && !isNaN(date)) {
    return date * 1000;
  }
};

export const differenceInDays = fnsDifferenceInDays;
export const fromUnixTime = fnsFromUnixTime;
export const isAfter = fnsIsAfter;
