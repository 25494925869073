import styled from "styled-components";

import { Button } from "components/Button";
import { SubHeading } from "style/components/SubHeading.styles";
import { profileHeader } from "assets/images";

export const AccountContainer = styled.div`
  .profile-wrapper {
    padding-top: 1em;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        margin-bottom: 0;
      }

      .btn-back {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    .account {
      background-color: ${({ theme }) => theme.card.bg.primary};
      backdrop-filter: blur(25px);
      border-radius: 10px;

      .name {
        text-align: center;
        text-transform: capitalize;
        font-size: 18px;
        margin-bottom: 0.5em;
      }
      .profile-information {
        padding: 15px 30px;

        .input-form {
          .input-form-field {
            margin-bottom: 15px;
          }
          .input-form-field::placeholder,
          .input-form-field-description::placeholder {
            color: ${({ theme }) => theme.input.placeholder.color};
          }
          .input-form-field-description {
            border-radius: 10px;
          }
        }

        .profile-menu {
          background-color: ${({ theme }) => theme.card.bg.septenary};
          border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};

          .nav-link {
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: ${({ theme }) => theme.global.color.quaternary};
            padding: 1.2rem 1rem;
            display: flex;
            align-items: center;

            &.active {
              color: ${({ theme }) => theme.nav.font.color};

              .icon {
                opacity: 1;
              }
            }

            .icon {
              margin-right: 30px;
              margin-left: 20px;
              opacity: 0.5;
              width: 30px;
              display: inline-block;
            }
          }

          .divider {
            margin: 0.2em 0;
          }
        }

        .content-wrapper {
          padding: 1em 0 10rem 2rem;
        }
      }

      .profile-header {
        background-image: url("${profileHeader}");
        background-size: cover;
        padding-top: 2rem;
        padding-bottom: 8px;

        .loader {
          height: 127px;
        }
        .img-profile {
          height: 100px;
          width: 100px;
          background-color: #bac1cb;
          border-radius: 50%;
          position: relative;
          margin: 0 auto;
          margin-bottom: 15px;

          .icon-camera {
            bottom: 0;
            right: 5px;
            position: absolute;
            padding: 5px;
            border-radius: 50%;
            background: #048af5;
            height: 25px;
            width: 25px;
          }

          .profile {
            border-radius: 50%;
            height: 100%;
            width: 100%;
          }
        }

        .plan-box {
          display: flex;
          padding: 12px 8px;
          border: 2px solid #157bfb;
          border-radius: 4px;
          align-items: center;
          margin-bottom: 15px;

          img {
            background: #157bfb;
            margin-right: 8px;
            padding: 4px;
            width: 25px;
            height: 25px;
            border-radius: 8px;
          }

          .label {
            color: ${({ theme }) => theme.global.color.senary};
            font-weight: 500;
            font-size: 0.85em;
            margin-right: 8px;
          }

          .value {
            font-size: 0.85em;
          }
        }

        .my-subcription {
          font-weight: 700;
          font-size: 15px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .profile-wrapper {
      .account .profile-information {
        padding: 15px;

        .profile-menu {
          display: none;
        }
        .content-wrapper {
          padding-left: 0;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .submit-btn {
      width: 100% !important;
    }
  }
`;

export const FormSubmitButton = styled(Button)`
  width: 340px;
  margin-top: 1rem;
  padding: 10px 0;
  margin-bottom: 1.5rem;
`;

export const BodyHeading = styled(SubHeading)`
  color: ${({ theme }) => theme.global.color.senary} !important;
  text-align: center;
  margin-bottom: 2.5rem;
`;
