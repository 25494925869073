import { createSlice } from "@reduxjs/toolkit";

import {
  strategyListCases,
  removeStrategyCases,
  communityStrategylistCases,
  setErrorMessageReducer,
  setBacktestStatusLog,
} from "./reducer";

const initialState = {
  strategies: [],
  backtestStatusLog: [],
  communityStrategies: [],
  strategyId: null,
  loading: false,
  errorMessage: "",
  refetchStrategies: false,
};

export const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    setErrorMessage: setErrorMessageReducer,
    setBacktestLog: setBacktestStatusLog,
  },
  extraReducers: (builder) => {
    strategyListCases(builder);
    removeStrategyCases(builder);
    communityStrategylistCases(builder);
  },
});

export const { setErrorMessage, setBacktestLog } = strategySlice.actions;
export default strategySlice.reducer;
