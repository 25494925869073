import { fetchUserDetails } from "./thunk";
import { ASIDE_STATE_KEY, SIGNUP_EMAIL_KEY, THEME_KEY } from "utils/constants";
import { setItemInStorage, setItemPersist } from "utils/storage";

export const setScrollRefBottomReducer = (state, action) => {
  state.scrollRefBottom = action.payload;
};

export const setToastDataReducer = (state, action) => {
  state.toastData = action.payload;
};

export const userDetailsCases = (builder) => {
  builder.addCase(fetchUserDetails.pending, (state) => {
    state.user = null;
  });
  builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
    state.user = action.payload;
  });
  builder.addCase(fetchUserDetails.rejected, (state) => {
    state.user = null;
  });
};

export const toggleSigninModalReducer = (state) => {
  state.signinModalData.visible = !state.signinModalData.visible;
};

export const toggleSignupModalReducer = (state) => {
  state.signupModalData.visible = !state.signupModalData.visible;
};

export const toggleForgotPassModalReducer = (state) => {
  state.forgotPassModalData.visible = !state.forgotPassModalData.visible;
};

export const toggleCheckEmailPassModalReducer = (state, action) => {
  state.checkEmailModalData = action.payload;
};

export const setUserDetailsReducer = (state, action) => {
  state.user = action.payload;
};

export const setRunWalkthroughReducer = (state, action) => {
  state.walkthrough = action.payload;
};

export const setThemeReducer = (state, action) => {
  state.theme = action.payload;
  setItemPersist(THEME_KEY, action.payload);
};

export const clearUserStateReducer = (state) => {
  state.user = null;
};

export const setUserEmailReducer = (state, action) => {
  state.userEmail = action.payload;
  setItemInStorage(SIGNUP_EMAIL_KEY, action.payload);
};

export const setHasBannerReducer = (state, action) => {
  state.hasBanner = action.payload;
};

export const setScrollTopValueReducer = (state, action) => {
  state.scrollTopValue = action.payload;
};

export const setCurrentPathReducer = (state, action) => {
  state.currentPath = action.payload;
};

export const setCollapsedSidebarReducer = (state, action) => {
  state.collapsedSidebar = action.payload;
  setItemInStorage(ASIDE_STATE_KEY, String(!action.payload));
};
