import styled from "styled-components";

export const ManualTradingPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 20px 47px 21px;
  background: ${({ theme }) => theme.card.bg.panel};
  width: 400px;

  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  form {
    width: 100%;

    .input-form {
      .form-control {
        margin-bottom: 1rem;
      }

      svg {
        top: 15px;
      }
    }

    .button-wrapper {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      gap: 20px;

      button {
        width: 50%;
        background: rgba(165, 199, 255, 0.05);
        color: ${({ theme }) => theme.global.color.bodyColor};
        font-size: 16px;
        border-radius: 0;
        border-bottom: 1px solid #c9c9c9;

        &.buy {
          color: #03cd17;
          border-bottom: 1px solid #03cd17;
        }

        &.sell {
          color: #ff3f3f;
          border-bottom: 1px solid #ff3f3f;
        }

        &.active {
          color: #048af5;
          border-bottom: 1px solid #048af5;
        }

        &:hover {
          background: rgba(165, 199, 255, 0.05);
          box-shadow: none;
        }
      }
    }

    .divider {
      margin: 2rem 0;
    }

    .estimated {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 1rem;
    }

    button.button-small {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
