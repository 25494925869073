import { lazy } from "react";

const Portfolio = lazy(() => import("features/Portfolio"));
const PortfolioDetail = lazy(() => import("features/Portfolio/Detail"));

export const HOME_PATH = "/";
export const PORTFOLIO_PATH = "/portfolio";
export const PORTFOLIO_DETAIL_PATH = "/portfolio/:id/detail";
export const PORTFOLIO_DETAIL_ID_PATH = "/portfolio/:id/detail/:detailId";

export default [
  {
    path: HOME_PATH,
    component: <Portfolio />,
  },
  {
    path: PORTFOLIO_PATH,
    component: <Portfolio />,
  },
  {
    path: PORTFOLIO_DETAIL_PATH,
    component: <PortfolioDetail />,
  },
  {
    path: PORTFOLIO_DETAIL_ID_PATH,
    component: <PortfolioDetail />,
  },
];
