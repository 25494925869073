import PropTypes from "prop-types";
import CloseButton from "react-bootstrap/CloseButton";

import { Avatar } from "components/Avatar";
import { Emblem } from "assets/images";
import { NotificationCardContainer } from "./Notification.styles";
import { openNewTab } from "utils";
import { markAsReadNotification, clearNotifications } from "./service";
import classNames from "classnames";
import {
  EnvelopeClosedIcon,
  EnvelopeOpenedIcon,
} from "assets/icons/notification";

const NotificationCard = ({
  id,
  link,
  message,
  time,
  handleClick,
  handleClearClick,
  avatar,
  seen,
  showEnvelope,
}) => {
  const handleNotificationClick = async () => {
    try {
      await markAsReadNotification({ notifications: id });
      handleClick(id);
      openNewTab(window.location.origin + link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearNotification = async () => {
    try {
      await clearNotifications({ notifications: [id] });
      handleClearClick();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NotificationCardContainer>
      <div className="d-flex align-items-center">
        <span className="dot"></span>
        <div>
          <Avatar
            src={avatar || Emblem}
            showBorder
            className={classNames("profile", {
              "no-avatar": !avatar,
            })}
            size="large"
          />
        </div>
        <div className="description" onClick={handleNotificationClick}>
          {message} <span className="time">{time}</span>
        </div>
      </div>
      {/* <div className="action">
        {btnText ? (
          <Button
            className="btn-action"
            onClick={handleNotificationClick}
          >
            {btnText}
          </Button>
        ) : (
          <Avatar src={avatarIcon} showBorder className="profile" />
        )}
      </div> */}
      <div className="d-flex gap-2 align-items-center">
        {showEnvelope && (
          <>
            {seen && <img src={EnvelopeOpenedIcon} />}
            {!seen && <img src={EnvelopeClosedIcon} />}
          </>
        )}
        <CloseButton
          variant="white"
          onClick={handleClearNotification}
          className="icon-close"
        />
      </div>
    </NotificationCardContainer>
  );
};

NotificationCard.defaultProps = {
  link: "",
  showEnvelope: true,
};

NotificationCard.propTypes = {
  message: PropTypes.string,
  time: PropTypes.string,
  link: PropTypes.string,
  id: PropTypes.string,
  handleClick: PropTypes.func,
  handleClearClick: PropTypes.func,
  avatar: PropTypes.string,
  seen: PropTypes.bool,
  showEnvelope: PropTypes.bool,
};

export default NotificationCard;
