import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  FORGOT_PASS_PATH,
  RESET_PASS_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  SIGNUP_VERIFY_LINK_PATH,
} from "features/Auth/routes";
import { AppMenu } from "./AppMenu";
import { contains, isLoggedIn } from "utils";
import { REACT_APP_VER } from "utils/constants";
import { setCollapsedSidebar } from "Layout/slice";
import { PLANS_PATH } from "features/Plans/routes";
import { SidebarContainer } from "./Sidebar.styles";
import { MAIN_ACCOUNT_PATH } from "features/Account/routes";
import { VERIFY_PHONE_PATH, WELCOME_PATH } from "features/Auth/routes";
import { collapsedSidebarSelect, hasBannerSelect } from "Layout/slice/selector";

export const Sidebar = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const hasBanner = useSelector(hasBannerSelect);
  const collapsed = useSelector(collapsedSidebarSelect);

  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  const toggleCollapsed = (state) => {
    dispatch(setCollapsedSidebar(state));
  };

  const hideSidebar =
    contains(pathname, SIGNUP_PATH) ||
    contains(pathname, SIGNIN_PATH) ||
    contains(pathname, FORGOT_PASS_PATH) ||
    contains(pathname, SIGNUP_VERIFY_LINK_PATH) ||
    contains(pathname, RESET_PASS_PATH) ||
    contains(pathname, WELCOME_PATH) ||
    contains(pathname, MAIN_ACCOUNT_PATH) ||
    contains(pathname, PLANS_PATH) ||
    contains(pathname, VERIFY_PHONE_PATH);

  return (
    <SidebarContainer
      hasBanner={hasBanner}
      onMouseEnter={() => toggleCollapsed(false)}
      onMouseLeave={() => toggleCollapsed(true)}
      className={classNames({
        collapsed: collapsed,
        "d-none": hideSidebar || !isLoggedIn(),
      })}
    >
      <div className="sidebar">
        <div className="sidebar-inner">
          <div className="sidebar-layout">
            <div className="sidebar-content">
              <nav className="menu">
                <AppMenu active={active} collapsed={collapsed} />
              </nav>
            </div>

            <div className="sidebar-footer">
              <div className="sidebar-btn-wrapper">
                <span className="sidebar-btn" rel="noopener noreferrer">
                  <span className="text-elipses">{REACT_APP_VER}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};
