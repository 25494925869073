import React from "react";
import PropTypes from "prop-types";

import { Button } from "components/Button";
import { PremiumPlanModalContainer } from "./PremiumPlanModal.styles";
import { Heading } from "style/components/Heading.styles";

export const PremiumPlanModal = (props) => {
  return (
    <PremiumPlanModalContainer show={props.show} onHide={props.onHide}>
      <div className="card-wrapper">
        <Heading>Surmount Premium</Heading>
        <p>
          Connect & manage an unlimited number of broker accounts. Place manual
          trades across all your accounts. Automate your accounts. Deploy up to
          5 automation strategies per account
        </p>
        <div className="text-center">
          <Button>Get Premium</Button>
        </div>
      </div>

      <div onClick={props.onHide} className="dismiss">
        {`i'll stay on free`}
      </div>
    </PremiumPlanModalContainer>
  );
};

PremiumPlanModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

PremiumPlanModal.defaultProps = {
  show: false,
};

PremiumPlanModal.displayName = "PremiumPlanModal";
