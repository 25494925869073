import { createGlobalStyle } from "styled-components";

import { iconWhiteClose } from "assets/images";

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
}


/**
 * Fix fonts that render as bold in Firefox
 */

html {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  height: 100%;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}



footer,
header,
nav,
section,
main {
  display: block;
}

body {
  overflow: hidden;
  margin: 0;
  height: 100%;
  color: ${({ theme }) => theme.global.color.bodyColor};
  font-family: ${({ theme }) => theme.global.fonts.poppins};

  #root {
    height: 100%;    
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .on-btn {
      --bs-spinner-width: 1.5em;
      --bs-spinner-height: 1.5em;
      --bs-spinner-border-width: 0.2em;
    }
  }

  /* Tooltip container */
  .app-tooltip {
    position: relative;
    display: inline-block;    
    align-items: center;
    display: flex;

    &:hover .tooltiptext {
      visibility: visible;
    }

    .tooltiptext {
      font-size: 0.875rem;
      font-weight: 400;
      visibility: hidden;
      min-width: 100px;
      background-color: ${({ theme }) => theme.tooltip.bg.primary};
      color: ${({ theme }) => theme.tooltip.font.color} !important;
      text-align: center;
      padding: 0.25rem 0.5rem;
      border-radius: 6px;
      left: 125%;

      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;

      &.right {
        left: 125%;
      }
      &.left {
        right: 125%;
      }
      &.top {
        bottom: 110%;
        left: 50%;
        margin-left: -50px; /* Use half of the width (120/2 = 60), to center the tooltip */
      }
      &.bottom {
        top: 110%;
        left: 50%;
        margin-left: -50px; /* Use half of the width (120/2 = 60), to center the tooltip */
      }

      &.mw-200px {
        min-width: 200px;
      }
    }
  }
  
  .chart-tooltip {
    background: ${({ theme }) => theme.chart.tooltip.bg};
    border-radius: 10px;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    padding: 5px 10px;
    border: 0;

    .datetime {
      font-size: 10px;
      color: ${({ theme }) => theme.global.extraInfo.font.color};
    }
    .hp-datetime {
      font-size: 14px;
      color: ${({ theme }) => theme.global.extraInfo.font.color};
    }
    .label {
      font-size: 12px;
    }
  }

  .disabled {
    cursor: not-allowed !important;
  }

  button[disabled] {
    cursor: not-allowed !important;
    background: ${({ theme }) => theme.button.bg.secondary} !important;
    color: ${({ theme }) => theme.button.font.secondary} !important;
  }

  .divider {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.divider.bg.primary};
    margin: 0.5em 0;
  }

  .v-divider {
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.divider.bg.primary};
  }

  .tooltip{

    &.show {
      opacity: 1;
    }

    & > div.tooltip-inner {
      background: ${({ theme }) => theme.tooltip.bg.primary}; 
      color: ${({ theme }) => theme.tooltip.font.color};
    }
    
    &.bs-tooltip-bottom .tooltip-arrow::before{
      border-bottom-color: ${({ theme }) => theme.tooltip.bg.primary};  
      border-left-color: transparent;  
      border-right-color: transparent;  
    }

    & > .tooltip-arrow::before {
      border-left-color: ${({ theme }) => theme.tooltip.bg.primary};  
      border-right-color: ${({ theme }) => theme.tooltip.bg.primary};        
    }

    &[data-popper-placement="left-start"]{
      margin-top: -10px;
      & > .tooltip-arrow::before{
        top: 10px;
      }
    }
    
    &.tooltip-wrapper {
      background: ${({ theme }) => theme.tooltip.bg.primary};  
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};  
      z-index: 9999;
      margin-bottom: 10px;
    
      .tooltip-inner {
        background: ${({ theme }) => theme.tooltip.bg.primary} !important;
        border-radius: 30px;
        padding: 2px 10px;
        color: ${({ theme }) => theme.tooltip.font.color};
        max-width: 300px;
        text-align: start;
      }
    
      .tooltip-arrow::before {
        border-top-color: ${({ theme }) => theme.tooltip.bg.primary};
        border-left-color: transparent;
        border-right-color: transparent;
        top: 7px;
      }
    
      .action-details {
        .action-name {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
    
          p {
            font-weight: 400;
            font-size: 12px;
            color: ${({ theme }) => theme.tooltip.font.color};
            margin-bottom: 0;
          }
    
          img {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
/* body end */

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button.unset {
  all: unset;
}

.color-primary {
  color: ${({ theme }) => theme.global.text.primary} !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.renew-text {
  color: ${({ theme }) => theme.global.color.bodyColor};
  font-size: 14px;
  font-weight: 400;
}

.host-desc {
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.global.extraInfo.font.color};
}


.delete-strat-text {
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.global.extraInfo.font.color};
  margin-bottom: 0px;
}

.bg-dark {
  background-color: ${({ theme }) => theme.global.color.secondary};
}

.no-data {
  color: ${({ theme }) => theme.global.color.noData};
  font-weight: 600;
  font-size: 14px;
  margin-top: 0 1em;
}

.fs {
  &-14 {
    font-size: 14px !important;
  }
}

.text-left {
  text-align: left;
}

.text-elipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-content {
  margin: 0 auto;
  background: ${({ theme }) => theme.modal.bg.primary};
  padding: 30px 12px;
  border: 2px solid ${({ theme }) => theme.modal.border.color};
  border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8}
}

.toast-container {
  margin: 2em;
  z-index: 99999;

  &.top-0 {
    margin-top: 5em;
  }
  .toast {
    background: ${({ theme }) => theme.toast.bg.primary};

    .toast-header{
      padding: 7px 10px 8px 10px;
      color: #ffffff;
      background-color: ${({ theme }) => theme.toast.bg.primary};

      &.info {
        background-color: ${({ theme }) => theme.toast.bg.info};
      }
      &.error {
        background-color: ${({ theme }) => theme.toast.bg.error};
      }
      .btn-close {
        background: url("${iconWhiteClose}") center/1em auto no-repeat;
        opacity: 1;
      }
    }
    .toast-body {
      padding: 10px 10px 30px;
      font-size: 14px;
      font-weight: 600;
      opacity: 0.8;
    }
  }
}

.desktop-view {
  display: none;  
}

.mobile-view {
  display: none;
  padding-top: 20px;
}

.tablet-view {
  display: none;
}

// Animation 
.app__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__slideInCenter {
  -webkit-animation-name: slideInCenter;
  animation-name: slideInCenter;
}

@keyframes slideInCenter {
  from {
    -webkit-transform: translate3d(-55%, 0, 0);
    transform: translate3d(-55%, 0, 0);
  }
  
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideOutCenter {
  -webkit-animation-name: slideOutCenter;
  animation-name: slideOutCenter;
}

@keyframes slideOutCenter {
  from {
    -webkit-transform: translate3d(55%, 0, 0);
    transform: translate3d(55%, 0, 0);
  }
  
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
}


.modal-wrapper{
  z-index:9999;
}

 /* Media Query for Tablets Ipads portrait mode */
 @media (min-width: 768px) and (max-width: 1024px){
  .tablet-view {
    display: block;
  }
}

@media (min-width: 769px) {
  .desktop-view {
    display: block;
  }
}

@media (max-width: 768px) {
  .mobile-view {
    display: block;
  }
 
  .toast-container {
    margin: 2em 10px;
  }
}

::-webkit-scrollbar { width: 7px; height:5px; position:absolute; }
::-webkit-scrollbar-track { background: transparent; }
:hover::-webkit-scrollbar-thumb { background: #242e45; border-radius:10px; }
::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover { background: #242e45; border-radius:10px; }
::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active { background: #242e45; border-radius:10px; }

`;

export default GlobalStyle;
