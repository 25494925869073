import { lazy } from "react";

const Signup = lazy(() => import("features/Auth/Signup"));
const Signin = lazy(() => import("features/Auth/Signin"));
const ConnectBroker = lazy(() => import("features/Auth/Welcome/Connect"));
const ConnectedBroker = lazy(() => import("features/Auth/Welcome/Connected"));
const ResetPassword = lazy(() => import("features/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("features/Auth/ForgotPassword"));
const CheckEmail = lazy(() => import("features/Auth/CheckEmail"));
const VerifyEmail = lazy(() => import("features/Auth/Signup/VerifyEmail"));
const VerifyPhone = lazy(() => import("features/Auth/VerifyPhone"));

export const SIGNIN_PATH = "/signin";
export const SIGNUP_PATH = "/signup";
export const SIGNUP_EMAIL_PATH = "/signup/email";
export const VERIFY_PHONE_PATH = "/verify-phone";
export const SIGNUP_SUCCESS_PATH = "/signin/success";
export const RESET_PASS_PATH = "/reset-password";
export const FORGOT_PASS_PATH = "/forgot-password";
export const CHECK_EMAIL_PATH = "/check-email";
export const SIGNUP_VERIFY_LINK_PATH = "/verify_link";
export const WELCOME_PATH = "/welcome";
export const WELCOME_CONNECTED_PATH = "/welcome/connected";

export const authPublicRoutes = [
  {
    path: SIGNUP_PATH,
    component: <Signup />,
  },
  {
    path: SIGNIN_PATH,
    component: <Signin />,
  },
  {
    path: FORGOT_PASS_PATH,
    component: <ForgotPassword />,
  },
  {
    path: SIGNUP_EMAIL_PATH,
    component: <CheckEmail />,
  },
  {
    path: SIGNUP_VERIFY_LINK_PATH,
    component: <VerifyEmail />,
  },
  {
    path: RESET_PASS_PATH,
    component: <ResetPassword />,
  },
  {
    path: VERIFY_PHONE_PATH,
    component: <VerifyPhone />,
  },
];

export const authPrivateRoutes = [
  {
    path: WELCOME_PATH,
    component: <ConnectBroker />,
  },
  {
    path: WELCOME_CONNECTED_PATH,
    component: <ConnectedBroker />,
  },
];
