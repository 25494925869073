import { useState } from "react";
import PropTypes from "prop-types";
import { default as InitialsAvatar } from "react-avatar";

import { avatar } from "assets/icons/strategy";
import { AvatarContainer } from "./Avatar.styles";

export const Avatar = ({
  height,
  size,
  width,
  src,
  showBorder,
  name,
  round,
  className,
  ...props
}) => {
  const [showInitial, setShowInitial] = useState(false);
  const setInitials = () => {
    setShowInitial(true);
  };
  return !src || showInitial ? (
    <div className={className}>
      <InitialsAvatar
        name={name}
        src={!name ? avatar : ""}
        round={round}
        size={typeof size !== "string" ? size : 40}
        {...props}
      />
    </div>
  ) : (
    <AvatarContainer
      {...props}
      className={[
        `${className} avatar-${typeof size === "string" ? size : "medium"}`,
      ].join(" ")}
    >
      <img
        height={height}
        width={width}
        src={src ? src : avatar}
        alt="icon"
        className="thumbnail-image"
        style={{
          border: showBorder ? "2px solid #157bfb" : "none",
        }}
        onError={() => setInitials()}
      />
    </AvatarContainer>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["small", "medium", "large"]),
    PropTypes.number,
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  showBorder: PropTypes.bool,
  name: PropTypes.string,
  round: PropTypes.bool,
};

Avatar.defaultProps = {
  size: "medium",
  className: "",
  src: null,
  showBorder: false,
  round: false,
};
