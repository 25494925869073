import { post } from "services/httpService";

const SERVICE_URLS = {
  signin: () => "user/signin/",
  signup: (qs) => `user/signup/?${qs}`,
  verifySignupEmail: () => `user/verify-account/`,
  resendEmailLink: () => `user/verify-email/`,
  forgotPassword: () => "user/forgot-password/",
  resetPassword: (qs) => `user/reset-password/?${qs}`,
  verifyPhone: () => `user/verify/`,
};

export const signin = (payload) => post(SERVICE_URLS.signin(), payload);
export const signup = (payload, qs) => post(SERVICE_URLS.signup(qs), payload);
export const verifySignupEmail = (payload) =>
  post(SERVICE_URLS.verifySignupEmail(), payload);
export const resendEmailLink = (payload) =>
  post(SERVICE_URLS.resendEmailLink(), payload);

export const forgotPassword = (payload) =>
  post(SERVICE_URLS.forgotPassword(), payload);

export const resetPassword = (payload, qs) =>
  post(SERVICE_URLS.resetPassword(qs), payload);

export const verifyPhone = (payload) =>
  post(SERVICE_URLS.verifyPhone(), payload);
