import PropTypes from "prop-types";
import { FormContainer } from "./Form.styles";

const Form = ({ children, ...props }) => {
  return (
    <FormContainer className="form" {...props}>
      {children}
    </FormContainer>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Form;
