import styled from "styled-components";

export const ChangePhoneContainer = styled.div`
  form {
    &.phone-form {
      width: 280px;

      .form-error {
        margin-top: 10px;
      }
      .control {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }

    &.code-form {
      margin-top: 25px;
    }

    .phone-input {
      background: ${({ theme }) => theme.input.bg.primary};
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      border: 1px solid ${({ theme }) => theme.input.border.color};
      color: ${({ theme }) => theme.input.font.primary};
      padding: 10px 15px;
      width: 100%;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${({ theme }) => theme.input.placeholder.color};
        opacity: 1;
      }
    }

    .verify-button-wrapper {
      display: flex;
      gap: 20px;
      margin: 1rem 0;
      width: 250px;
    }

    .form-control {
      margin-bottom: 1rem;
    }
  }
`;
