import { get, post } from "services/httpService";

const SERVICE_URLS = {
  getAllNotifications: (qs) => `notifications?${qs}`,
  clearNotifications: () => "notifications/clear/",
  markAsReadNotification: () => "notifications/seen/",
};

export const getAllNotifications = (qs) =>
  get(SERVICE_URLS.getAllNotifications(qs));

export const clearNotifications = (payload) =>
  post(SERVICE_URLS.clearNotifications(), payload);

export const markAsReadNotification = (payload) =>
  post(SERVICE_URLS.markAsReadNotification(), payload);
