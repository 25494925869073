import styled from "styled-components";

export const TableHeader = styled.th`
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;

  &.sorting-column {
    cursor: pointer;
  }

  .sorting-order {
    cursor: pointer;
    margin-left: 6px;
    height: 14px;
  }
`;
