import BTSelect from "react-select";
import { useTheme } from "styled-components";

import { SelectStyles } from "./Select.styles";

const Select = (props) => {
  const theme = useTheme();
  return (
    <BTSelect
      styles={SelectStyles(theme)}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};

export default Select;
