import styled from "styled-components";

import { Button } from "components/Button";

export const BackButton = styled(Button)`
  height: 45px;
  width: 110px;
  font-weight: 600;

  .icon-left {
    margin-right: 5px;
    & > path {
      fill: ${({ theme }) => theme.backButton.font.color};
    }
  }

  @media (max-width: 768px) {
    padding: 0 !important;
    width: 70px !important;
    height: 35px;
    font-weight: 500;
    font-size: 13px !important;
  }
`;
