import { contains } from "utils";
import {
  getStrategyFiles,
  postStrategyRepo,
  getStrategyDefaultFile,
} from "./thunk";

export const dirStructHelper = (files) => {
  let result = [];
  let level = { result };

  files.forEach((file, index) => {
    file?.pathname?.split("/")?.reduce((r, name) => {
      if (!r[name]) {
        r[name] = { result: [] };
        r.result.push({
          ...file,
          name,
          children: r[name].result,
          positionIndex: index,
        });
      }

      return r[name];
    }, level);
  });
  return result;
};

export const getStrategyFilesCases = (builder) => {
  builder
    .addCase(getStrategyFiles.pending, (state) => {
      state.status.getStrategyFiles = "loading";
      state.error.getStrategyFiles = null;
    })
    .addCase(getStrategyFiles.fulfilled, (state, action) => {
      state.status.getStrategyFiles = "succeeded";
      state.data = action.payload;
    })
    .addCase(getStrategyFiles.rejected, (state) => {
      state.status.getStrategyFiles = "failed";
      state.error.getStrategyFiles = null;
    });
};

export const postStrategyRepoCases = (builder) => {
  builder
    .addCase(postStrategyRepo.pending, (state) => {
      state.status.createStrategyRepo = "loading";
      state.error.createStrategyRepo = null;
    })
    .addCase(postStrategyRepo.fulfilled, (state, action) => {
      state.status.createStrategyRepo = "succeeded";
      state.data = action.payload;
    })
    .addCase(postStrategyRepo.rejected, (state) => {
      state.status.createStrategyRepo = "failed";
      state.error.createStrategyRepo = null;
    });
};

export const editorReducers = {
  addCustomFile: (state, action) => {
    let fileIndex = null;
    const item = state.data.files?.find((file, index) => {
      fileIndex = index;
      return file.id === action.payload.id;
    });
    if (!item) {
      state.data.files.push(action.payload);
      state.flags.selectedFileIndex = state.data.files.length - 1;
      state.flags.activeIndex = state.data.files.length - 1;
    } else {
      item.showTab = true;
      state.flags.selectedFileIndex = fileIndex;
      state.flags.activeIndex = fileIndex;
    }
  },
  showFileTab: (state, action) => {
    state.data.files[action.payload].showTab = true;
  },
  hideFileTab: (state, action) => {
    state.data.files[action.payload].showTab = false;
  },
  expandFolderTab: (state, action) => {
    state.data.files[action.payload].expandFolder = true;
  },
  collapseFolderTab: (state, action) => {
    state.data.files[action.payload].expandFolder = false;
  },
  addCodeToFile: (state, action) => {
    const {
      payload: { index, content },
    } = action;
    state.data.files[index].content = content;
  },
  changeSelectedFileIndex: (state, action) => {
    state.flags.selectedFileIndex = action.payload.index;
  },
  changeActiveIndex: (state, action) => {
    state.flags.activeIndex = action.payload.index;
  },
  updateFile: (state, action) => {
    const { index, file } = action.payload;
    state.data.files[index] = { ...state.data.files[index], ...file };
  },
  addFile: (state, action) => {
    state.data.files.push(action.payload);
  },
  deleteFileAtIndex: (state, action) => {
    state.data.files.splice(action.payload, 1);
    state.data.files.forEach((file, ind) => (file.positionIndex = ind));
  },
  updateRepoData: (state, action) => {
    state.repoDetail = action.payload;
  },
  updateToggleName: (state, action) => {
    state.repoDetail.toggleNameModal = action.payload;
  },
  updateDeployedStrategyStatus: (state, action) => {
    state.isDeployed = action.payload;
  },
  clearState: (state) => {
    state.data = {};
    state.defaultFile = {};
    state.falgs = {
      selectedFileIndex: null,
      activeIndex: null,
    };
    state.status = {
      getStrategyFiles: "idle",
      createStrategyRepo: "idle",
    };
    state.error = {
      getStrategyFiles: null,
      createStrategyRepo: null,
    };
    state.repoDetail = {
      name: "",
      fileType: "",
      toggleNameModal: false,
    };
    state.isDeployed = 0;
  },
  updateFolderName: (state, action) => {
    state.data.files.forEach((file) => {
      if (contains(file.pathname, `${action.payload.oldName}/`))
        file.pathname = file.pathname.replace(
          `${action.payload.oldName}/`,
          `${action.payload.newName}/`
        );
    });
  },
  deleteFolderFromList: (state, action) => {
    state.data.files = state.data.files.filter(
      (file) => !contains(file.pathname, `${action.payload.folderName}/`)
    );
  },
  updateDefaultFile: (state, action) => {
    state.defaultFile = { ...action.payload };
  },
};

export const getStrategyDefaultFileCases = (builder) => {
  builder
    .addCase(getStrategyDefaultFile.pending, (state) => {
      state.status.getStrategyDefaultFile = "loading";
      state.error.getStrategyDefaultFile = null;
    })
    .addCase(getStrategyDefaultFile.fulfilled, (state, action) => {
      state.status.getStrategyDefaultFile = "succeeded";
      state.defaultFile = action.payload;
    })
    .addCase(getStrategyDefaultFile.rejected, (state) => {
      state.status.getStrategyDefaultFile = "failed";
      state.error.getStrategyDefaultFile = null;
    });
};
