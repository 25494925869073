import styled from "styled-components";

import { Button } from "components/Button";

export const SidebarContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
  margin-left: 20px;
  width: 200px;
  transition: width 0.5s ease;
  height: ${({ hasBanner }) =>
    hasBanner ? "calc(100vh - 110px)" : "calc(100vh - 40px)"};

  &.collapsed {
    width: 70px;

    .sidebar
      .sidebar-inner
      .sidebar-layout
      .sidebar-footer
      .sidebar-btn-wrapper {
      padding: 20px 2px;

      .sidebar-btn {
        padding: 0;

        span {
          font-size: 11px;
        }
      }
    }
  }

  .sidebar {
    position: relative;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    height: 100%;

    .sidebar-inner {
      background: ${({ theme }) => theme.aside.bg.primary};
      border: 1px solid ${({ theme }) => theme.global.border.primary};
      backdrop-filter: blur(25px);
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_24};
      margin-top: 20px;
      min-width: 80px;
      border-bottom: none;
      position: relative;
      height: 100%;

      .sidebar-layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        .sidebar-content {
          flex-grow: 1;

          .menu {
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }

        .sidebar-footer {
          border-top: none;
          text-align: center;

          .sidebar-btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .sidebar-btn {
              transition: width 0.3s;
              width: 150px;
              padding: 1px 15px;
              ${({ theme }) => theme.constants.BORDER_RADIUS_8};
              color: ${({ theme }) => theme.aside.button.color};
              text-decoration: none;
              margin: 0 auto;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-overflow: ellipsis;
              overflow: hidden;

              span {
                font-weight: 600;
                font-size: 14px !important;
                line-height: 140%;
                color: ${({ theme }) => theme.aside.font.color};
                opacity: 0.5;
              }

              &:hover {
                color: ${({ theme }) => theme.aside.button.hover};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 8px;

    &.collapsed {
      left: -100px;
    }
  }
`;

export const CreateStrategyIcon = styled(Button)`
  padding: 6px !important;
  width: 100%;
`;

export const AppMenuContainer = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .menu-item {
      opacity: 0.5;
      margin-top: 0;
      margin-bottom: 35px;

      &:first-child {
        margin-top: 20px;
      }

      &.back-btn {
        opacity: 1;
      }

      &.helpcenter-toggle {
        position: absolute;
        margin-bottom: 0;
        bottom: 5em;
      }

      &.active {
        opacity: 1;

        .item-content {
          opacity: 1;
        }

        .icon-wrapper {
          opacity: 1;
        }

        .inner-item {
          margin: 0px 20px 0px 18px;
          border-radius: 5px;
          padding: 6px 2px 8px 2px;
        }
      }

      .item-content {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: ${({ theme }) => theme.aside.font.color};
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .move-back-btn img {
          margin-right: 8px;
          width: 9px;
          height: 100%;
        }
      }

      .icon-wrapper {
        margin-right: 5px;
        opacity: 0.8;
        background-color: hsla(0, 0%, 100%, 0);
        display: inline-block;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
        margin-right: 10px;
        min-width: 35px;
        text-align: center;
        width: 35px;

        .icon {
          font-size: 14px;
          line-height: 35px;
          text-align: center;

          .move-back-btn {
            padding: 0;

            img {
              width: 9px;
              height: 100%;
            }
          }
        }
      }

      .inner-item {
        height: 35px;
        align-items: center;
        cursor: pointer;
        display: flex;
        padding: 8px 35px 8px 20px;
        position: relative;
      }
    }
  }
`;
