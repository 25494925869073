import RSAsyncSelect from "react-select/async";
import { useTheme } from "styled-components";

import { SelectStyles } from "./Select.styles";

const AsyncSelect = (props) => {
  const theme = useTheme();

  return (
    <RSAsyncSelect
      cacheOptions
      defaultOptions
      styles={SelectStyles(theme)}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};

export { AsyncSelect };
