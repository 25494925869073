import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  flexRender,
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";

import { Button } from "components/Button";
import { PAGE_SIZE } from "utils/constants";
import { TableContainer } from "./Table.styles";
import { TableHeader } from "style/components/TableHeader.styles";
import { TablePlaceholder } from "./TablePlaceholder";
import { arrowUpIcon, arrowDownIcon } from "assets/icons/marketplace";

const columnHelper = createColumnHelper();

export const Table = ({
  data,
  columns,
  isLoading,
  notFoundText,
  initialPageSize,
  headerClass,
  showPagination,
  onRowClick,
  tableClassName,
  totalRecords,
  customPagination,
  handlePageChange,
  totalPages,
  renderSubComponent,
  getRowCanExpand,
  showHeader,
  onSortChange,
  sorting,
  ...props
}) => {
  const tableColumns = columns.map((column) => {
    return columnHelper.accessor(column.dataField, {
      header: () => column.text ?? column.dataField,
      ...column.props,
    });
  });

  const pageCount = customPagination
    ? totalPages
    : Math.ceil(data.length / initialPageSize);

  const table = useReactTable({
    data,
    columns: tableColumns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: customPagination,
    manualSorting: true,
    debugTable: false,
    pageCount,
    state: {
      sorting,
    },
    onSortingChange: onSortChange,
  });

  const currentPage = table.getState().pagination.pageIndex + 1;
  const pageSize = table.getState().pagination.pageSize;
  const showEntries = (currentPage - 1) * pageSize + 1;
  const totalData = customPagination ? totalRecords : data?.length;
  const toEntriesCalculation = showEntries + pageSize - 1;
  const toEntries =
    toEntriesCalculation > totalData ? totalData : toEntriesCalculation;

  useEffect(() => {
    table.setPageSize(initialPageSize);
  }, []);

  const onPageChange = (page) => {
    const pageIndex = page - 1;
    table.setPageIndex(pageIndex);
    handlePageChange(page);
  };

  const handleMoreClick = () => {
    if (currentPage + 5 <= pageCount) onPageChange(currentPage + 5);
    else onPageChange(pageCount);
  };

  const getPageButtons = () => {
    // const buttonCount = pageCount > 5 ? 5 : pageCount;

    return (
      <>
        {Array(pageCount)
          .fill(0)
          .map((d, inx) => {
            const pageNo = inx + 1;
            return (
              <Fragment key={inx}>
                {((currentPage <= 5 && pageNo <= 5) ||
                  (currentPage > 5 &&
                    pageNo > currentPage - 5 &&
                    pageNo <= currentPage)) && (
                  <Button
                    label={pageNo.toString()}
                    size="small"
                    width={40}
                    className={classNames("rounded-0", {
                      "inactive-page": currentPage !== pageNo,
                    })}
                    key={inx}
                    onClick={() =>
                      customPagination
                        ? onPageChange(pageNo)
                        : table.setPagination({
                            pageIndex: inx,
                            pageSize,
                          })
                    }
                  />
                )}
              </Fragment>
            );
          })}
        {table.getCanNextPage() && (
          <Button
            label="..."
            size="small"
            width={40}
            className="rounded-0 inactive-page"
            onClick={handleMoreClick}
          />
        )}
      </>
    );
  };

  return (
    <TableContainer
      {...props}
      className={["table-wrapper", tableClassName].join(" ")}
    >
      {showHeader && (
        <thead className={headerClass}>
          {table.getHeaderGroups().map((hg, i) => (
            <tr key={`${hg.id}-${i}`}>
              {hg.headers.map((header, j) => {
                const headerColumn = header.column;

                return (
                  <TableHeader
                    key={`${header.id}-${j}`}
                    {...{
                      className: classNames({
                        "sorting-column": headerColumn.columnDef.enableSorting,
                      }),
                      onClick: headerColumn.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      headerColumn.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: (
                        <img
                          className={"sorting-order"}
                          src={arrowUpIcon}
                          alt="ascending"
                        />
                      ),
                      desc: (
                        <img
                          className={"sorting-order"}
                          src={arrowDownIcon}
                          alt="desc"
                        />
                      ),
                    }[headerColumn.getIsSorted()] ?? null}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {/* loader */}
        {isLoading && (
          <TablePlaceholder
            headerCount={table.getHeaderGroups()[0].headers.length}
          />
        )}
        {/* no data */}
        {!data.length && !isLoading && (
          <tr>
            <td
              className="no-data-text"
              colSpan={table.getHeaderGroups()[0].headers.length}
            >
              {notFoundText}
            </td>
          </tr>
        )}
        {/* content */}
        {data.length > 0 &&
          !isLoading &&
          table.getRowModel().rows.map((row, i) => {
            return (
              <Fragment key={`${row.id}-${i}`}>
                <tr
                  className={classNames({
                    "active-row":
                      row.original.broker_account_id ===
                      row.original.selectedRow,
                  })}
                >
                  {row.getVisibleCells().map((cell, j) => {
                    return (
                      <td
                        style={{
                          maxWidth: cell.column.columnDef.maxWidth,
                        }}
                        onClick={() => {
                          if (cell.column.id !== "action") {
                            onRowClick(cell.row.original);
                          }
                        }}
                        className="table-cell"
                        key={`${cell.id}-${j}`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
      </tbody>
      {showPagination && (
        <tfoot>
          <tr>
            <td colSpan={columns.length} className="table-pagination">
              {!!data.length && (
                <div className="d-flex justify-content-between align-items-center">
                  <p className="data-count-text">
                    Showing {showEntries} to {toEntries} of {totalData} entries
                  </p>

                  <div className="d-flex">
                    <Button
                      label="Previous"
                      onClick={() =>
                        customPagination
                          ? onPageChange(currentPage - 1)
                          : table.previousPage()
                      }
                      disabled={!table.getCanPreviousPage()}
                      size="small"
                      width={90}
                      className="pagination-btn prev"
                    />
                    {getPageButtons()}
                    <Button
                      label="Next"
                      onClick={() =>
                        customPagination
                          ? onPageChange(currentPage + 1)
                          : table.nextPage()
                      }
                      disabled={!table.getCanNextPage()}
                      size="small"
                      width={60}
                      className="pagination-btn next"
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
        </tfoot>
      )}
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  notFoundText: PropTypes.string,
  initialPageSize: PropTypes.number,
  headerClass: PropTypes.string,
  showPagination: PropTypes.bool,
  onRowClick: PropTypes.func,
  tableClassName: PropTypes.string,
  totalRecords: PropTypes.number,
  customPagination: PropTypes.bool,
  handlePageChange: PropTypes.func,
  totalPages: PropTypes.number,
  getRowCanExpand: PropTypes.func,
  renderSubComponent: PropTypes.func,
  showHeader: PropTypes.bool,
  customSorting: PropTypes.bool,
  onSortChange: PropTypes.func,
  sorting: PropTypes.array,
};

Table.defaultProps = {
  data: [],
  columns: [],
  isLoading: false,
  notFoundText: "No Data found",
  initialPageSize: PAGE_SIZE,
  showPagination: true,
  tableClassName: "",
  totalRecords: 0,
  customPagination: false,
  totalPages: 0,
  onRowClick: () => {},
  getRowCanExpand: () => false,
  showHeader: true,
  onSortChange: () => {},
  sorting: [],
};
