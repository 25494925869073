import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createRepoGithub,
  fetchFiles,
  fetchStrategyRepo,
  getFileCode,
} from "../service";

export const getStrategyFiles = createAsyncThunk(
  "editor/getStrategyFiles",
  async ({ strategyId }) => {
    const response = await fetchStrategyRepo({ strategyId });
    const filesResponse = await fetchFiles({ strategyId });
    return {
      repository_name: response?.repository_name,
      files: filesResponse?.map((file, index) => ({
        url: file.git_url,
        sha: file.sha,
        name: file.name,
        id: file.download_url,
        pathname: file.path,
        positionIndex: index,
        expandFolder: true,
      })),
    };
  }
);

export const postStrategyRepo = createAsyncThunk(
  "editor/postStrategyRepo",
  async ({ strategyId, strategyName, useDefault = false }) => {
    const response = await createRepoGithub({
      strategyId,
      strategyName,
      useDefault,
    });
    const filesResponse = await fetchFiles({ strategyId });
    return {
      repository_name: response?.name,
      files: filesResponse?.map((file, index) => ({
        url: file.git_url,
        sha: file.sha,
        name: file.name,
        id: file.download_url,
        pathname: file.path,
        positionIndex: index,
      })),
    };
  }
);

export const getStrategyDefaultFile = createAsyncThunk(
  "editor/getStrategyDefaultFile",
  async ({ strategyId }) => {
    const response = await getFileCode({ strategyId, fileName: "main.py" });
    return response;
  }
);
