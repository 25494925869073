import { createSlice } from "@reduxjs/toolkit";

import { SIGNUP_EMAIL_KEY, THEME_KEY, THEME_TYPES } from "utils/constants";
import { getItemFromStorage, getItemPersist } from "utils/storage";
import {
  setScrollRefBottomReducer,
  setToastDataReducer,
  userDetailsCases,
  toggleSigninModalReducer,
  toggleSignupModalReducer,
  toggleForgotPassModalReducer,
  toggleCheckEmailPassModalReducer,
  setUserDetailsReducer,
  setRunWalkthroughReducer,
  setThemeReducer,
  clearUserStateReducer,
  setUserEmailReducer,
  setHasBannerReducer,
  setScrollTopValueReducer,
  setCurrentPathReducer,
  setCollapsedSidebarReducer,
} from "./reducer";

const initialState = {
  user: null,
  scrollRefBottom: false,
  scrollTopValue: 0,
  currentPath: "/",
  walkthrough: {
    run: false,
    steps: {},
  },
  toastData: {
    visible: false,
  },
  signinModalData: {
    visible: false,
  },
  signupModalData: {
    visible: false,
  },
  forgotPassModalData: {
    visible: false,
  },
  checkEmailModalData: {
    visible: false,
    email: "",
  },
  userEmail: getUserEmail(),
  theme: getTheme(),
  hasBanner: false,
  collapsedSidebar: true,
};

function getTheme() {
  const theme = getItemPersist(THEME_KEY);
  if (theme) {
    return theme;
  }
  return THEME_TYPES.LIGHT;
}

function getUserEmail() {
  const userEmail = getItemFromStorage(SIGNUP_EMAIL_KEY);
  return userEmail || "";
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setScrollRefBottom: setScrollRefBottomReducer,
    toast: setToastDataReducer,
    toggleSigninModal: toggleSigninModalReducer,
    toggleSignupModal: toggleSignupModalReducer,
    toggleForgotPassModal: toggleForgotPassModalReducer,
    toggleCheckEmailPassModal: toggleCheckEmailPassModalReducer,
    setUserDetails: setUserDetailsReducer,
    setRunWalkthrough: setRunWalkthroughReducer,
    setTheme: setThemeReducer,
    clearUserState: clearUserStateReducer,
    setUserEmail: setUserEmailReducer,
    setHasBanner: setHasBannerReducer,
    setScrollTopValue: setScrollTopValueReducer,
    setCurrentPath: setCurrentPathReducer,
    setCollapsedSidebar: setCollapsedSidebarReducer,
  },
  extraReducers: (builder) => {
    userDetailsCases(builder);
  },
});

export const {
  setScrollRefBottom,
  toast,
  toggleSigninModal,
  toggleSignupModal,
  toggleForgotPassModal,
  toggleCheckEmailPassModal,
  setUserDetails,
  setRunWalkthrough,
  setTheme,
  clearUserState,
  setUserEmail,
  setHasBanner,
  setScrollTopValue,
  setCurrentPath,
  setCollapsedSidebar,
} = layoutSlice.actions;

export default layoutSlice.reducer;
