import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { ProgressModalContainer } from "./ProgressModal.styles";

export const ProgressModal = ({ show, onHide, progress }) => {
  return (
    <ProgressModalContainer
      className="progress-modal"
      show={show}
      onHide={onHide}
    >
      <h2>{progress} % Completed</h2>
      <ProgressBar animated now={100} />
      <p>Deploying</p>
    </ProgressModalContainer>
  );
};

ProgressModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  progress: PropTypes.number,
};

ProgressModal.defaultProps = {
  show: false,
  progress: 1,
};
