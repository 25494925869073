import { del, get, post, put, patch } from "services/httpService";

const SERVICE_URLS = {
  getPortfolios: () => "broker-accounts/",
  getPortfolioData: (accountId) => `broker-accounts/${accountId}/data/`,
  createPortfolio: () => "user/broker/",
  deletePortfolio: () => "user/broker/",
  updatePortfolio: (id) => `broker-accounts/${id}/edit/`,
  getPortfolio: (id) => `broker-accounts/${id}/edit/`,
  getPortfolioOverview: (id) => `broker-accounts/${id}/overview/`,
  getPortfoliosByStrategyId: (strategyId) => `broker-accounts/${strategyId}/`,
  getPortfolioOrders: (id, qs) => `broker-accounts/${id}/order-history/?${qs}`,
  getPortfolioPositions: (id) => `broker-accounts/${id}/positions/`,
  placeOrder: () => `trade/place-order/`,
  getSnaptradeIframeUrl: () => `broker-accounts/oauth-url/?broker=alpaca`,
  getAvailableBrokers: () => `supported-brokers/`,
  getBrokerConnectionLink: (qs) => `broker-accounts/connect/?${qs}`,
  updatePortfolioName: (id) => `broker/${id}/rename/`,
  reconnectPortfolio: (id) => `broker/${id}/redirect/`,
  getPortfolioTradingDetail: (qs) => `trading-connection/create/?${qs}`,
  getPortfolioTradingConnectionPositions: (id) =>
    `trading-connection/${id}/positions/`,
  getPortfolioTradingConnectionOrders: (id, qs) =>
    `trading-connection/${id}/orders/?${qs}`,
  getPortfolioTradingConnectionKeyStats: (id) =>
    `trading-connection/${id}/key-stats/`,
  getPortfolioTradingConnectionHpChart: (id) =>
    `trading-connection/${id}/chart/`,
  getAssetTypes: () => `trade/asset-types/`,
  getTradeAccounts: (qs) => `trade/accounts?${qs}`,
  getTradeQuote: (qs) => `trade/quote?${qs}`,
  getTradeAssets: (qs) => `trade/assets?${qs}`,
};

export const getPortfolios = () => get(SERVICE_URLS.getPortfolios());
export const getPortfolioData = (accountId) =>
  get(SERVICE_URLS.getPortfolioData(accountId));
export const getPortfolio = (id) => get(SERVICE_URLS.getPortfolio(id));
export const getPortfolioOverview = (id) =>
  get(SERVICE_URLS.getPortfolioOverview(id));

export const createPortfolio = (payload) =>
  put(SERVICE_URLS.createPortfolio(), payload);

export const updatePortfolio = (id, payload) =>
  put(SERVICE_URLS.updatePortfolio(id), payload);

export const deletePortfolio = (payload) =>
  del(SERVICE_URLS.deletePortfolio(), payload);

export const getPortfoliosByStrategyId = (strategyId) =>
  get(SERVICE_URLS.getPortfoliosByStrategyId(strategyId));

export const getPortfolioOrders = (id, qs) =>
  get(SERVICE_URLS.getPortfolioOrders(id, qs));

export const getPortfolioPositions = (id) =>
  get(SERVICE_URLS.getPortfolioPositions(id));

export const placeOrder = (payload) => post(SERVICE_URLS.placeOrder(), payload);

export const getSnaptradeIframeUrl = () =>
  get(SERVICE_URLS.getSnaptradeIframeUrl());

export const getAvailableBrokers = () =>
  get(SERVICE_URLS.getAvailableBrokers());

export const getBrokerConnectionLink = (qs) =>
  get(SERVICE_URLS.getBrokerConnectionLink(qs));

export const updatePortfolioName = (id, payload) =>
  patch(SERVICE_URLS.updatePortfolioName(id), payload);

export const reconnectPortfolio = (id) =>
  get(SERVICE_URLS.reconnectPortfolio(id));

export const getPortfolioTradingDetail = (id) =>
  get(SERVICE_URLS.getPortfolioTradingDetail(id));

export const getPortfolioTradingConnectionPositions = (id) =>
  get(SERVICE_URLS.getPortfolioTradingConnectionPositions(id));

export const getPortfolioTradingConnectionOrders = (id, qs) =>
  get(SERVICE_URLS.getPortfolioTradingConnectionOrders(id, qs));

export const getPortfolioTradingConnectionKeyStats = (id) =>
  get(SERVICE_URLS.getPortfolioTradingConnectionKeyStats(id));

export const getPortfolioTradingConnectionHpChart = (id) =>
  get(SERVICE_URLS.getPortfolioTradingConnectionHpChart(id));

export const getAssetTypes = () => get(SERVICE_URLS.getAssetTypes());

export const getTradeAccounts = (qs) => get(SERVICE_URLS.getTradeAccounts(qs));

export const getTradeQuote = (qs) => get(SERVICE_URLS.getTradeQuote(qs));

export const getTradeAssets = (qs) => get(SERVICE_URLS.getTradeAssets(qs));
