import GA from "react-ga4";

export const trackEvent = (params) => {
  /* 
    action: "your action",
    category: "your category",
    label: "your label", // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  */
  GA.event({
    action: "click",
    label: document.title,
    ...params,
  });
};

export const trackPage = (params) => {
  GA.send({
    hitType: "pageview",
    title: document.title,
    ...params,
  });
};

export const initTracking = GA.initialize;
