import { numberSeparator, roundOff } from "utils";
import { unixToDateTime, MONTH_DATE_YEAR_FORMAT } from "utils/date";
import { ViewDetailsLink } from "./PlanAndBilling/PlanAndBilling.styles";

export const billingHistoryColumns = (handleOpenInvoice) => [
  {
    dataField: "created",
    text: "Date",
    props: {
      cell: ({ getValue }) => {
        const value = getValue();
        return <b>{unixToDateTime(value, MONTH_DATE_YEAR_FORMAT)}</b>;
      },
    },
  },
  {
    dataField: "subscription_details",
    text: "Item",
    props: {
      cell: ({ getValue }) => {
        const { metadata } = getValue();
        return <span>{metadata?.description || "-"}</span>;
      },
    },
  },
  {
    dataField: "total",
    text: "Price",
    props: {
      cell: ({ getValue }) => {
        const value = getValue() / 100;
        const formattedValue = numberSeparator(roundOff(value));
        return `$${formattedValue}` || "-";
      },
    },
  },
  {
    dataField: "status",
    text: "Status",
    props: {
      cell: ({ getValue }) => {
        return getValue().toUpperCase() || "-";
      },
    },
  },
  {
    dataField: "hosted_invoice_url",
    text: "",
    props: {
      cell: ({ getValue }) => {
        const value = getValue();
        return (
          <ViewDetailsLink onClick={() => handleOpenInvoice(value)}>
            View details
          </ViewDetailsLink>
        );
      },
    },
  },
];
