import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const Draggable = ({ initialPos, children, className, style }) => {
  const [pos, setPos] = useState(initialPos);
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (!dragging) return;

      e.stopPropagation();
      e.preventDefault();

      setPos({
        x: e.pageX - rel.x,
        y: e.pageY - rel.y,
        right: "auto",
        bottom: "auto",
      });
    };

    const onMouseUp = (e) => {
      e.stopPropagation();
      e.preventDefault();

      setDragging(false);
    };

    if (dragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging, rel]);

  const onMouseDown = (e) => {
    if (e.button !== 0) return;

    const pos = e.currentTarget.getBoundingClientRect();
    setDragging(true);
    setRel({
      x: e.pageX - pos.x,
      y: e.pageY - pos.y,
    });
  };

  return (
    <div
      onMouseDown={onMouseDown}
      className={className}
      style={{
        position: "absolute",
        left: pos.x + "px",
        top: pos.y + "px",
        right: pos.right,
        bottom: pos.bottom,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

Draggable.propTypes = {
  initialPos: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    y: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
