import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Heading } from "style/components/Heading.styles";
import { NewCardContainer, StripeCard } from "./SubscriptionPayment.styles";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { Loader } from "components/Loader";
import { useNavigation } from "utils/navigate";
import { purchasePlan, updateCardDetails } from "features/Account/service";
import { toast } from "Layout/slice";
import { parseErrorMessage } from "utils";

const NewCard = ({ isUpdateCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { navigateToPlans } = useNavigation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const cardElement = elements.getElement("card");
      const payload = await stripe.createToken(cardElement);

      if (payload?.token?.id) {
        let res;
        if (isUpdateCard) {
          res = await updateCardDetails({ token: payload.token.id });
        } else {
          res = await purchasePlan({ token: payload.token.id });
        }

        dispatch(
          toast({
            type: "info",
            body: res.message,
          })
        );
        navigateToPlans();
      }
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: StripeCard(theme),
    hidePostalCode: true,
    disableLink: true,
  };

  return (
    <NewCardContainer>
      <Heading>
        {isUpdateCard ? "Update Card Details" : "New Card Details"}
      </Heading>
      <div className="divider" />
      <div className="wrapper">
        <Form className="form" onSubmit={handleSubmit}>
          <div className="card-container">
            <CardElement options={cardElementOpts} />
          </div>
          <div className="add-button">
            <Button disabled={!stripe} type="submit" size="small">
              {isSubmitting && <Loader className="on-btn" />}{" "}
              {isUpdateCard ? "Update" : "Pay"}
            </Button>
          </div>
        </Form>
      </div>
    </NewCardContainer>
  );
};

NewCard.propTypes = {
  isUpdateCard: PropTypes.bool,
};

NewCard.defaultProps = {
  isUpdateCard: false,
};

export default NewCard;
