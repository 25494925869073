import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { toast } from "Layout/slice";
import { parseErrorMessage } from "utils";
import { Loader } from "components/Loader";
import { sortByUnitAmount } from "../utils";
import { NewPlanContainer } from "./Plan.styles";
import { AvailablePlanCard } from "./AvailablePlanCard";
import { fetchUserDetails } from "Layout/slice/thunk";
import { Heading } from "style/components/Heading.styles";
import { PaymentFailedModal } from "shared/Modal/PaymentFailedModal";
import { ACCOUNT_AVAILABLE_PLANS_PATH } from "features/Account/routes";
import { PaymentSuccessModal } from "shared/Modal/PaymentSuccessModal";
import { getPlans } from "features/Plans/service";

export const AvailablePlan = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentFailure, setShowPaymentFailure] = useState(false);

  const toggleSuccessModal = () => setShowPaymentSuccess(!showPaymentSuccess);
  const toggleFailureModal = () => setShowPaymentFailure(!showPaymentFailure);

  useEffect(() => {
    getAllPlans();

    const paymentStatus = searchParams.get("status");
    if (paymentStatus === "success") {
      toggleSuccessModal();
    } else if (paymentStatus === "failed") {
      toggleFailureModal();
    }

    window.history.replaceState(null, "", ACCOUNT_AVAILABLE_PLANS_PATH);
  }, []);

  const getAllPlans = async () => {
    try {
      setIsLoading(true);
      const paymentPlans = await getPlans();
      setPaymentPlans(paymentPlans.sort(sortByUnitAmount));
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelPlan = () => {
    getAllPlans();
    dispatch(fetchUserDetails());
  };

  return (
    <NewPlanContainer>
      <Heading>Available Plans</Heading>
      <div className="divider mt-1 mb-3"></div>

      {isLoading && <Loader />}

      {!isLoading && (
        <div className="plan-wrapper">
          {paymentPlans.map((plan) => (
            <div key={plan.id} className="px-0">
              <AvailablePlanCard
                plan={plan}
                handleCancelPlan={handleCancelPlan}
              />
            </div>
          ))}
        </div>
      )}

      <PaymentSuccessModal
        show={showPaymentSuccess}
        onHide={toggleSuccessModal}
        showDescription={false}
        buttonTitle="Ok"
      />

      <PaymentFailedModal
        show={showPaymentFailure}
        onHide={toggleFailureModal}
      />
    </NewPlanContainer>
  );
};
