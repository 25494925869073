import io from "socket.io-client";

import { getItemFromStorage } from "utils/storage";
import { AUTH_TOKEN_KEY, BASE_URL } from "utils/constants";

export let socket = null;

export const connectSocket = (authToken) => {
  const token = authToken || getItemFromStorage(AUTH_TOKEN_KEY);

  if (!token) return;

  const options = {
    extraHeaders: {
      authorization: `Bearer ${token}`,
    },
  };
  socket = io(BASE_URL.replace("/api/", ""), options);

  if (socket) {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

function onConnect() {
  console.log("=== socket connected ===");
}

function onDisconnect() {
  console.log("=== socket disconnected ===");
}
