import { updateIsLoggedIn } from "./thunk";

export const isLoggedInCases = (builder) => {
  builder.addCase(updateIsLoggedIn.pending, (state) => {
    state.isLoggedIn = false;
  });

  builder.addCase(updateIsLoggedIn.fulfilled, (state, action) => {
    state.isLoggedIn = action.payload;
  });

  builder.addCase(updateIsLoggedIn.rejected, (state) => {
    state.isLoggedIn = false;
  });
};

export const setAuthUserReducer = (state, action) => {
  state.authUser = action.payload;
};
