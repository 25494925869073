import { authPublicRoutes, authPrivateRoutes } from "../features/Auth/routes";
import accountRoutes from "../features/Account/routes";
import portfolioRoutes from "../features/Portfolio/routes";
import strategyRoutes from "../features/Strategy/routes";
import paymentRoutes from "../features/Account/PurchasePlan/routes";
import helpCenterRoutes from "../features/HelpCenter/routes";
import notificationRoutes from "../features/Notification/routes";
import plansRoutes from "../features/Plans/routes";
import { marketplaceRoutes } from "../features/Marketplace/routes";

// public routes
export const publicRoutes = [...authPublicRoutes, ...plansRoutes];

// private routes
export const privateRoutes = [
  ...marketplaceRoutes,
  ...accountRoutes,
  ...strategyRoutes,
  ...portfolioRoutes,
  ...paymentRoutes,
  ...notificationRoutes,
  ...helpCenterRoutes,
  ...authPrivateRoutes,
];
