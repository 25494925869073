import styled from "styled-components";

export const NavbarContainer = styled.div`
  .navbar {
    background: ${({ theme }) => theme.global.bg.primary};
    height: 90px;
    padding: 0.5em 2em 0 1.5em;

    .container-fluid {
      padding: 0;
    }

    .main-logo {
      cursor: pointer;
    }

    .hamburger-icon {
      display: none;
      cursor: pointer;
    }

    .logged-in-options {
      display: flex;
      gap: 24px;
      margin-right: 10px;
      align-items: center;

      img {
        cursor: pointer;

        &.theme-icon {
          width: 25px;
        }
      }
    }

    .navbar-toggler-icon {
      cursor: pointer;
    }

    .navbar-nav {
      align-items: center;

      .dropdown-toggle {
        .thumbnail-image {
          padding: 2px;
        }
      }
      .dropdown-toggle:focus {
        outline: 0;
      }
      .dropdown-menu {
        padding: 0px !important;
        background: ${({ theme }) => theme.dropdown.bg.primary} !important;
        border: none !important;
        min-width: 111px;
        margin-top: 30px;
        margin-right: 7px;

        &::after {
          display: none;
        }
        .dropdown-item {
          text-align: left;
          padding: 1rem 2rem 1rem 1.5rem;
        }
      }
    }

    .strategy-image-wrapper {
      width: 30px;
      height: 30px;
      svg {
        font-size: 30px;
        margin-right: 5px;
      }
    }

    .icon-notification {
      cursor: pointer;

      & > path {
        opacity: ${({ theme }) => theme.image.opacity};
        fill: ${({ theme }) => theme.image.color.tertiary};
      }
    }

    .signin-btn {
      background: transparent;
      border: 1px solid ${({ theme }) => theme.button.font.quinary};
      margin-right: 10px;
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      width: 104px;
      height: 40px;
      padding: 5px;
      font-size: 14px !important;
      color: ${({ theme }) => theme.button.font.quinary};
    }

    .signup-btn {
      border: 1px solid ${({ theme }) => theme.button.bg.primary};
      font-size: 16px !important;
      width: 104px;
      height: 40px;
      background: ${({ theme }) => theme.button.bg.primary};
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      padding: 5px;
      font-size: 14px !important;
    }
  }

  .logged-out-menu {
    .dropdown-menu {
      div:nth-child(1) {
        background: ${({ theme }) => theme.button.bg.primary};
        width: 140px;
        margin: auto;
        padding: 7px;
        margin-bottom: 5px;
        border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
        a {
          color: ${({ theme }) => theme.button.font.primary};
        }
      }
      div:nth-child(2) {
        padding: 7px;
        border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
        border: 1px solid ${({ theme }) => theme.button.font.quinary};
        color: ${({ theme }) => theme.button.font.quinary};
        margin-top: 12px;

        a {
          color: ${({ theme }) => theme.button.font.quinary};
        }
      }
      a.dropdown-item {
        text-align: center;
        padding-right: 0;
        opacity: 1;

        &:hover {
          background-color: transparent !important;
        }
        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .navbar {
      padding: 35px 15px 5px 15px;

      .hamburger-icon {
        display: block !important;
      }

      .main-logo {
        width: 200px;
      }

      .logged-in-options {
        gap: 10px;

        svg {
          margin-left: 10px;
        }
        .notification-separator {
          display: none;
        }
      }
    }
  }
`;
