export const layoutScrollState = (state) => state.layout.scrollRefBottom;
export const userDetailsState = (state) => state.layout.user;
export const toastDataSelect = (state) => state.layout.toastData;
export const isToastVisibleSelect = (state) => state.layout.isToastVisible;
export const signinModalDataSelect = (state) => state.layout.signinModalData;
export const signupModalDataSelect = (state) => state.layout.signupModalData;
export const runWalkthroughSelect = (state) => state.layout.walkthrough;
export const forgotPassModalDataSelect = (state) =>
  state.layout.forgotPassModalData;
export const checkEmailModalDataSelect = (state) =>
  state.layout.checkEmailModalData;
export const themeState = (state) => state.layout.theme;
export const userEmailSelect = (state) =>
  decodeURIComponent(state.layout.userEmail);
export const hasBannerSelect = (state) => state.layout.hasBanner;
export const scrollTopValueSelect = (state) => state.layout.scrollTopValue;
export const currentPathSelect = (state) => state.layout.currentPath;
export const collapsedSidebarSelect = (state) => state.layout.collapsedSidebar;
