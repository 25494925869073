import PropTypes from "prop-types";
import classNames from "classnames";
import { AwsRum } from "aws-rum-web";
import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider as BTThemeProvider } from "react-bootstrap";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import Banner from "shared/Banner";
import GlobalStyle from "style/global";
import { Toast } from "components/Toast";
import { Sidebar } from "components/Sidebar";
import OverrideStyle from "style/override";
import { Navbar } from "components/Navbar";
import { initTracking } from "utils/tracking";
import LocationPath from "hooks/locationPath";
import ScrollButton from "shared/ScrollButton";
import { fetchUserDetails } from "./slice/thunk";
import { setItemInStorage } from "utils/storage";
import { LayoutContainer } from "./Layout.styles";
import { themeState } from "Layout/slice/selector";
import { PLANS_PATH } from "features/Plans/routes";
import { toastDataSelect } from "./slice/selector";
import { lightTheme, darkTheme } from "style/theme";
import { contains, isLoggedIn, toTitleCase } from "utils";
import { MAIN_ACCOUNT_PATH } from "features/Account/routes";
import { connectSocket, disconnectSocket } from "services/socket";
import {
  toast,
  setScrollRefBottom,
  setScrollTopValue,
  setCollapsedSidebar,
} from "./slice";
import {
  ENV,
  ASIDE_STATE_KEY,
  AWS_APP_IDENTITY_POOL,
  AWS_GUEST_ROLE_ARN,
  AWS_RUM_KEYS,
  AWS_RUM_URL,
  THEME_TYPES,
  GA_MEASURMENT_ID,
} from "utils/constants";
import {
  FORGOT_PASS_PATH,
  RESET_PASS_PATH,
  SIGNIN_PATH,
  SIGNUP_EMAIL_PATH,
  SIGNUP_PATH,
  VERIFY_PHONE_PATH,
} from "features/Auth/routes";
import ManualTradingFloatingButton from "shared/ManualTradingFloatingButton";

console.info(ENV);

if (ENV === "production") {
  try {
    // Initialize RUM
    let awsRum = null;
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: AWS_GUEST_ROLE_ARN,
      identityPoolId: AWS_APP_IDENTITY_POOL,
      endpoint: AWS_RUM_URL,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    };

    awsRum = new AwsRum(
      AWS_RUM_KEYS.APPLICATION_ID,
      AWS_RUM_KEYS.APPLICATION_VERSION,
      AWS_RUM_KEYS.APPLICATION_REGION,
      config
    );
    console.log(awsRum.tracking_id);

    // Activate Google Analytics
    initTracking(GA_MEASURMENT_ID);
  } catch (error) {
    console.log(error);
  }
}

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector(themeState);
  const toastData = useSelector(toastDataSelect);

  const [siteTitle, setSiteTitle] = useState("");
  const [isAuthPage, setIsAuthPage] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const scrollRef = useBottomScrollListener(onBottom, {
    offset: 300,
  });

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(fetchUserDetails());
    }

    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  const changedPath = (pathname) => {
    // Set tab title
    let title = pathname.split("/")[1];
    if (title) {
      title = title.replace("-", " ");
      title = toTitleCase(title);
    } else {
      title = "Dashboard";
    }
    setSiteTitle(title);

    // Set aside state
    if (contains(pathname, "/strategy") && contains(pathname, "/edit")) {
      setCollapsedSidebar(true);
      setItemInStorage(ASIDE_STATE_KEY, String(true));
    }

    // Set auth page visibility
    if (
      contains(pathname, SIGNUP_PATH) ||
      contains(pathname, SIGNIN_PATH) ||
      contains(pathname, FORGOT_PASS_PATH) ||
      contains(pathname, RESET_PASS_PATH) ||
      contains(pathname, SIGNUP_EMAIL_PATH) ||
      contains(pathname, VERIFY_PHONE_PATH)
    ) {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }

    if (
      contains(pathname, MAIN_ACCOUNT_PATH) ||
      contains(pathname, PLANS_PATH)
    ) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  };

  function onBottom() {
    dispatch(setScrollRefBottom(true));
  }

  const hideToast = () => {
    dispatch(
      toast({
        body: "",
        visible: false,
        type: toastData.type,
      })
    );
  };

  const handleScroll = (event) => {
    dispatch(setScrollTopValue(event.currentTarget.scrollTop));
  };

  const handleScrollTop = () => {
    scrollRef.current.scrollTo(0, 0);
  };

  const content = (
    <div
      className={classNames("content", {
        "full-height p-3": isAuthPage,
      })}
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );

  const themeConfig = theme === THEME_TYPES.DARK ? darkTheme : lightTheme;

  return (
    <HelmetProvider>
      <ThemeProvider theme={themeConfig}>
        <GlobalStyle />
        <OverrideStyle />
        <BrowserRouter>
          <Helmet>
            <title className="text-capitalize">{siteTitle} | Surmount AI</title>
          </Helmet>
          <LocationPath changedPath={changedPath} />
          <BTThemeProvider prefixes={{}}>
            <LayoutContainer>
              <Banner />
              <div
                className={classNames("app", {
                  "auth-app": isAuthPage,
                })}
                style={{ width: "100%" }}
              >
                <div className="app-content">
                  <Sidebar />
                  <div
                    className={classNames("screen", {
                      "mx-0": isAuthPage || !showSidebar,
                    })}
                  >
                    {!isAuthPage && <Navbar isAuthPage={isAuthPage} />}

                    {/* Dynamic View */}
                    {content}

                    <Toast
                      onClose={hideToast}
                      type={toastData?.type}
                      body={toastData?.body}
                      show={toastData?.visible}
                      header={toastData?.header}
                    />
                  </div>
                </div>
              </div>
              <ScrollButton handleScrollTop={handleScrollTop} />
              <ManualTradingFloatingButton />
            </LayoutContainer>
          </BTThemeProvider>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
