import { MAIN_ACCOUNT_PATH } from "features/Account/routes";
import { AccountIcon, SignoutIcon } from "assets/icons/navbar";

const loggedInItems = [
  {
    key: "profile",
    title: "My Account",
    navigateTo: MAIN_ACCOUNT_PATH,
    icon: <AccountIcon />,
  },
  {
    key: "signout",
    title: "Sign Out",
    icon: <SignoutIcon />,
  },
];

const logOutItems = [
  {
    key: "signin",
    title: "Sign In",
  },
  {
    key: "signup",
    title: "Sign up",
  },
];

const mobileLogOutItems = [
  {
    key: "signup",
    title: "Sign up",
  },
  {
    key: "signin",
    title: "Sign In",
  },
];

export const menuOptions = (isLoggedIn) => {
  if (isLoggedIn) return loggedInItems;

  return logOutItems;
};

export const mobileMenuOptions = (isLoggedIn) => {
  if (isLoggedIn) return loggedInItems;

  return mobileLogOutItems;
};
