import { object, string, number } from "yup";

export const initialValues = {
  side: "buy",
  orderType: "shares",
  price: "",
  amount: "",
  nominal: false,
  broker: "",
  assets: "",
};

export const validationSchema = object({
  broker: string().trim().required("Required"),
  assets: string().trim().required("Required"),
  side: string().trim().required("Required"),
  amount: number().typeError("Invalid number"),
});
