import styled from "styled-components";

export const SwitchContainer = styled.div`
  .primary {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 4px;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span.active {
      background: ${({ theme }) => theme.global.text.primary};
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100%;
      transition: all 0.5s ease-in-out;
      img {
        filter: brightness(0) invert(1);
      }
    }

    &.size-medium {
      width: 50px;
      span {
        height: 22px;
        width: 22px;
      }
    }

    &.size-large {
      width: 60px;
      span {
        height: 26px;
        width: 26px;
      }
    }

    &.size-small {
      width: 45px;
      span {
        height: 18px;
        width: 18px;
      }
    }
  }
  .secondary {
    label {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 24px;
    }

    label input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        border-radius: 50%;
        position: absolute;
        content: "";
        height: 34px;
        width: 36px;
        left: 0;
        background-color: ${({ theme }) => theme.global.color.quinary};
        transition: 0.4s;
        bottom: -4px;
      }

      &.size-medium {
        height: 30px;
        width: 60px;

        &:before {
          height: 38px;
          width: 36px;
        }
      }

      &.size-large {
        height: 32px;
        width: 68px;

        &:before {
          height: 40px;
          width: 42px;
        }
      }
    }

    input:checked + .slider {
      background-color: #1f2127;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #1f2127;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
`;
