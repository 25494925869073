import styled from "styled-components";

export const BillingMobileCardContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  background: ${({ theme }) => theme.card.bg.quinary};
  border-radius: 10px;

  .header {
    padding: 10px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .strategy-name {
      font-size: 14px;

      .title {
        font-weight: 600;
      }

      .value {
        font-weight: 400;
      }
    }

    .btn-view-details {
      padding: 11px 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .details-wrapper {
    table {
      width: 100%;

      th {
        background: ${({ theme }) => theme.button.bg.secondary};
        font-weight: 600;
        font-size: 14px;
      }

      td,
      th {
        text-align: center;
      }

      td {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;
