/**
 * Axios Interceptor
 * @param {*} instance
 */
import { reloadTab } from "utils";
import { disconnectSocket } from "./socket";
import { AUTH_TOKEN_KEY, STATUS_CODES } from "utils/constants";
import { appClearStorage, getItemFromStorage } from "utils/storage";

export const interceptor = (instance) => {
  instance.interceptors.request.use(
    function (config) {
      const token = getItemFromStorage(AUTH_TOKEN_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === STATUS_CODES.UNAUTHORIZED) {
        appClearStorage();
        disconnectSocket();
        reloadTab();
        return;
      }
      return Promise.reject(error);
    }
  );
};
