import styled from "styled-components";

export const AvatarContainer = styled.div`
  position: relative;

  &.avatar-small {
    height: 28px;
    width: 28px;
  }

  &.avatar-medium {
    height: 48px;
    width: 48px;
  }

  &.avatar-large {
    width: 99px;
    height: 99px;
  }

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  label {
    position: absolute;
    border: 1px solid ${({ theme }) => theme.global.color.quinary};
    background-color: ${({ theme }) => theme.global.bg.quaternary};
    border-radius: 50%;
    font-size: 10px;
    cursor: pointer;
    bottom: 4px;
    right: 5px;
    img {
      padding: 4px;
    }
  }
`;
