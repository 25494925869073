import { get, post } from "services/httpService";

const SERVICE_URLS = {
  subscribeToPremiumPlan: (qs) => `subscribe/?${qs}`,
  unsubscribePlan: () => `cancel-subscription/`,
  getPlans: () => `plans/`,
};

export const subscribeToPremiumPlan = (qs) =>
  get(SERVICE_URLS.subscribeToPremiumPlan(qs));

export const unsubscribePlan = (payload) =>
  post(SERVICE_URLS.unsubscribePlan(), payload);

export const getPlans = (payload) => get(SERVICE_URLS.getPlans(), payload);
