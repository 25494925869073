import React from "react";
import { paymentFail } from "assets/images";
import { Button } from "components/Button";
import PropTypes from "prop-types";
import { PaymentModalContainer } from "./PaymentModal.styles";

export const PaymentFailedModal = (props) => {
  return (
    <PaymentModalContainer {...props}>
      <div className="logo-img">
        <img src={paymentFail} className="img" />
      </div>
      <div className="heading-space">
        <h3 className="heading">Payment failed!</h3>
      </div>
      <div className="btn-space">
        <Button
          label="Ok"
          className="btn-proceed"
          width={200}
          onClick={props.onHide}
        />
      </div>
    </PaymentModalContainer>
  );
};

PaymentFailedModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

PaymentFailedModal.defaultProps = {
  show: false,
};

PaymentFailedModal.displayName = "PaymentFailedModal";
