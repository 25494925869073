import { Account } from "features/Account";
import { Profile } from "features/Account/Profile";
import { PlanAndBilling } from "features/Account/PlanAndBilling";
import { PaymentDetail } from "features/Account/PurchasePlan/Detail";
import { AvailablePlan } from "features/Account/PurchasePlan/AvailablePlan";
import { Security } from "features/Account/Security";

import {
  GeneralIcon,
  SecurityIcon,
  RecieptIcon,
  PlansIcon,
} from "assets/icons/menu";

export const MAIN_ACCOUNT_PATH = "/account";
export const ACCOUNT_OVERVIEW_PATH = "/account/profile";
export const ACCOUNT_SECURITY_PATH = "/account/security";
export const ACCOUNT_NOTIFICATION_PATH = "/account/notification";
export const ACCOUNT_TWO_FACTOR_AUTHENTICATION_PATH =
  "/account/two-factor-authentication";
export const ACCOUNT_PLAN_BILLING_PATH = "/account/plan-billing";
export const ACCOUNT_AVAILABLE_PLANS_PATH = "/account/available-plans";
export const ACCOUNT_PLAN_DETAIL_PATH = "/account/plan/:id/detail";
export const ACCOUNT_PAYMENT_PATH = "/account/payment-method";

export default [
  {
    path: MAIN_ACCOUNT_PATH,
    component: <Account />,
    subRoute: [
      {
        path: "",
        component: <Profile />,
      },
      {
        path: "profile",
        component: <Profile />,
      },
      {
        path: "plan-billing",
        component: <PlanAndBilling />,
      },
      {
        path: "available-plans",
        component: <AvailablePlan />,
      },
      {
        path: "plan/:id/detail",
        component: <PaymentDetail />,
      },
      {
        path: "payment-method",
        component: <PaymentDetail />,
      },
      // {
      //   path: "notification",
      //   component: <Notification />,
      // },
      {
        path: "security",
        component: <Security />,
      },
    ],
  },
];

export const PROFILE_MENU = [
  {
    title: "My Profile",
    path: ACCOUNT_OVERVIEW_PATH,
    Icon: GeneralIcon,
    isVisible: true,
  },
  {
    title: "Plan & Billing",
    path: ACCOUNT_PLAN_BILLING_PATH,
    Icon: RecieptIcon,
    isVisible: true,
  },
  {
    title: "Available Plans",
    path: ACCOUNT_AVAILABLE_PLANS_PATH,
    Icon: PlansIcon,
    isVisible: false,
  },
  // {
  //   title: "Payment Methods",
  //   path: ACCOUNT_PAYMENT_PATH,
  //   Icon: PaymentMethodsIcon,
  //   isVisible: plan?.plan === "Premium",
  // },
  // {
  //   title: "Notifications",
  //   path: ACCOUNT_NOTIFICATION_PATH,
  //   Icon: NotificationIcon,
  //   isVisible: true,
  // },
  {
    title: "Security",
    path: ACCOUNT_SECURITY_PATH,
    Icon: SecurityIcon,
    isVisible: true,
  },
];
