import styled from "styled-components";
import { Form } from "react-bootstrap";

export const FormContainer = styled(Form)`
  .form-control {
    color: ${({ theme }) => theme.input.font.tertiary};
    margin-bottom: 1.8em;
    border-color: ${({ theme }) => theme.input.bg.primary};
    background-color: ${({ theme }) => theme.input.bg.primary};
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    padding: 10px 15px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme }) => theme.input.font.quaternary};
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  .padd-right {
    padding-right: 50px;
  }
  .form-error {
    color: ${({ theme }) => theme.modal.error.font.color} !important;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .input-form {
    position: relative;
    .input-form-label {
      font-weight: ${({ theme }) => theme.constants.FONT_WEIGHT_500};
      font-size: ${({ theme }) => theme.constants.FONT_SIZE_16};
      line-height: 130%;
      color: ${({ theme }) => theme.input.font.primary};
      margin-bottom: 15px;
    }
    .input-form-field {
      background: ${({ theme }) => theme.input.bg.primary};
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      border: 1px solid ${({ theme }) => theme.input.border.color};
      color: ${({ theme }) => theme.input.font.primary};
    }
    svg {
      position: absolute;
      top: 50px;
      right: 15px;
      cursor: pointer;
    }
  }
`;

export const InputTagsContainer = styled.div`
  .selected-tags {
    background: rgba(83, 91, 122, 0.8);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 190px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .selected-tags .remove-tag {
    display: none;
  }

  .tag {
    background: ${({ theme }) => theme.global.extraInfo.font.color};
    border-radius: 75px;
    color: ${({ theme }) => theme.global.color.quinary};
    padding: 2px 10px;
    margin-right: 5px;
  }

  input {
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    padding: 8px 12px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none;
    background-color: transparent;
    width: 100px;

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder.color} !important;
    }

    &:focus {
      outline: none;
    }
  }
`;
