import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useNavigation } from "utils/navigate";
import { NewStrategyPaymentContainer } from "./SubscriptionPayment.styles";
import { BackButton } from "style/components/BackButton.styles";
import { arrowLeftIcon as ArrowLeftIcon } from "assets/icons/marketplace";
import NewCard from "./NewCard";
import { SubHeading } from "style/components/SubHeading.styles";
import { STRIPE_PUBLISH_KEY } from "utils/constants";

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

const SubscriptionPayment = ({ isPaymentMethod }) => {
  const { navigateBack } = useNavigation();

  return (
    <NewStrategyPaymentContainer>
      <Container fluid>
        {!isPaymentMethod && (
          <Row>
            <Col>
              <BackButton
                label="Back"
                width={60}
                onClick={navigateBack}
                variant="secondary"
              >
                <ArrowLeftIcon className="icon-left" /> Back
              </BackButton>
            </Col>
          </Row>
        )}

        {isPaymentMethod && (
          <>
            <SubHeading>Payment Methods</SubHeading>
            <div className="divider mt-1 mb-3"></div>
          </>
        )}
        {/* <div className="card-wrapper">
          <SavedCard
            isPaymentMethod={isPaymentMethod}
            handleUpdateClick={handleUpdateClick}
          />
        </div> */}

        <div className="card-wrapper mt-4">
          <Elements stripe={stripePromise}>
            <NewCard isUpdateCard />
          </Elements>
        </div>
      </Container>
    </NewStrategyPaymentContainer>
  );
};

SubscriptionPayment.propTypes = {
  isPaymentMethod: PropTypes.bool,
};

SubscriptionPayment.defaultProps = {
  isPaymentMethod: false,
};

export default SubscriptionPayment;
