import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { setUserDetails, toast } from "Layout/slice";
import { parseErrorMessage } from "utils";
import { Loader } from "components/Loader";
import { Button } from "components/Button";
import { Input, Form } from "components/Form";
import { useNavigation } from "utils/navigate";
import { FormSubmitButton } from "../Account.styles";
import { HELP_CENTER_PATH } from "features/HelpCenter/routes";
import { initialValues, validationSchema } from "../validation";
import { ProfileContainer } from "./Profile.styles";
import { userDetailsState } from "Layout/slice/selector";
import { emailUnverfiedIcon, phoneUnverfiedIcon } from "assets/icons/profile";
import { penIcon } from "assets/icons/editor";
import { Heading } from "style/components/Heading.styles";
import ChangePhone from "../Components/ChangePhone";
import { resendVerification, updateProfile } from "../service";
import { resendEmailLink } from "features/Auth/service";

export const Profile = () => {
  const dispatch = useDispatch();
  const profileDetails = useSelector(userDetailsState);

  const { navigateToHelpCenter, navigateToPlans } = useNavigation();

  const [isEditableProfile, setIsEditableProfile] = useState(false);
  const [editPhoneVisible, setEditPhoneVisible] = useState(false);

  useEffect(() => {
    if (!profileDetails.phone && !profileDetails?.phone_verified) {
      setEditPhoneVisible(true);
    }
  }, [profileDetails]);

  const handleSubmit = async (values) => {
    try {
      await updateProfile(values);
      dispatch(
        toast({
          body: "Profile has been updated successfully",
        })
      );
      toggleEditableProfile();
      dispatch(setUserDetails({ ...profileDetails, ...values }));
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const response = await resendEmailLink({
        email: profileDetails.email,
      });
      dispatch(
        toast({
          type: "info",
          body: response.message,
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleVerifyPhone = async () => {
    try {
      const unmaskedPhone = profileDetails.phone.replace(/\D/g, "");
      const response = await resendVerification({
        type: "sms",
        phone: `+${unmaskedPhone}`,
      });
      dispatch(
        toast({
          type: "info",
          body: response.message,
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues(profileDetails),
    onSubmit: handleSubmit,
    validationSchema,
  });

  const toggleEditableProfile = () => {
    setIsEditableProfile(!isEditableProfile);
  };

  const handleContactUs = () => {
    const options = {
      pathname: HELP_CENTER_PATH,
      search: `?activeTab=contactUs`,
    };
    navigateToHelpCenter(options);
  };

  const handleEditPhoneVisible = (e) => {
    e.stopPropagation();
    setEditPhoneVisible(true);
  };

  const handleVerifiedPhone = () => {
    setEditPhoneVisible(false);
  };

  const { first_name, last_name, email } = formik.values;

  return (
    <ProfileContainer>
      <div className="account-information">
        <Heading>Profile</Heading>
        <div className="divider mt-1 mb-3" />
        {profileDetails !== null && (
          <Row>
            <Col md={10}>
              <section>
                {!isEditableProfile && (
                  <>
                    <Row className="view-profile">
                      <Col md={5}>
                        <div className="label">First Name</div>
                      </Col>
                      <Col md={7}>
                        <div className="value">{first_name}</div>
                      </Col>
                      <div className="divider mt-0 mb-3"></div>
                      <Col md={5}>
                        <div className="label">Last Name</div>
                      </Col>
                      <Col md={7}>
                        <div className="value">{last_name}</div>
                      </Col>
                      <div className="divider mt-0 mb-3"></div>
                      <Col md={5}>
                        <div className="label">Email address</div>
                      </Col>
                      <Col md={7}>
                        <div className="email-wrapper">
                          <div className="value email">{email}</div>

                          {!profileDetails?.email_verified && (
                            <span className="app-tooltip">
                              <span className="tooltip-arrow" />
                              <img src={emailUnverfiedIcon} className="mx-2" />
                              <span className="tooltiptext bottom">
                                Please verify your email address by clicking the
                                verify link in the email sent to you when you
                                signed up. If you can’t find it,{" "}
                                <span
                                  className="link"
                                  onClick={handleVerifyEmail}
                                >
                                  resend verification email
                                </span>{" "}
                              </span>
                            </span>
                          )}
                        </div>
                      </Col>
                      <div className="divider mt-0 mb-3"></div>
                      <Col md={5}>
                        <div className="label">Phone Number</div>
                      </Col>
                      <Col md={7}>
                        <div className="phone-wrapper">
                          {profileDetails?.phone && !editPhoneVisible && (
                            <>
                              <div className="value mb-0">
                                {profileDetails?.phone || ""}{" "}
                              </div>
                              <img
                                className="edit-icon mx-2"
                                src={penIcon}
                                height={15}
                                width={15}
                                onClick={handleEditPhoneVisible}
                              />
                            </>
                          )}

                          {editPhoneVisible && (
                            <ChangePhone onSuccess={handleVerifiedPhone} />
                          )}

                          {!editPhoneVisible &&
                            !profileDetails?.phone_verified && (
                              <span className="app-tooltip">
                                <span className="tooltip-arrow" />
                                <img
                                  src={phoneUnverfiedIcon}
                                  className="mx-2"
                                />
                                <span className="tooltiptext bottom">
                                  Please verify your phone number by verifying a
                                  text code.
                                  <span
                                    className="link"
                                    onClick={handleVerifyPhone}
                                  >
                                    Send text code now
                                  </span>{" "}
                                </span>
                              </span>
                            )}
                        </div>
                      </Col>
                      <div className="divider mt-0 mb-3"></div>
                    </Row>
                    <Button
                      size="small"
                      className="mt-3"
                      onClick={toggleEditableProfile}
                    >
                      Edit Profile
                    </Button>
                  </>
                )}

                {isEditableProfile && (
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <Input
                          label="First Name *"
                          type="text"
                          placeholder="Enter First Name"
                          {...formik.getFieldProps("first_name")}
                        />
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                          <div className="form-error">
                            {formik.errors.first_name}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <Input
                          label="Last Name *"
                          type="text"
                          placeholder="Enter Last Name"
                          {...formik.getFieldProps("last_name")}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <div className="form-error">
                            {formik.errors.last_name}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Input
                          label="Email *"
                          type="email"
                          placeholder="Email address"
                          value={profileDetails.email || ""}
                          disabled
                        />
                      </Col>
                    </Row>
                    <div className="button-wrapper">
                      <Button
                        variant="secondary"
                        size="large"
                        onClick={toggleEditableProfile}
                      >
                        Cancel
                      </Button>
                      <FormSubmitButton
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? (
                          <Loader className="on-btn" />
                        ) : (
                          "Update Now"
                        )}
                      </FormSubmitButton>
                    </div>
                  </Form>
                )}
              </section>

              {/* Close Account */}
              <section className="d-none">
                <Heading className="mt-5">Close Account</Heading>
                <div className="divider mt-1 mb-3"></div>

                <div className="close-account">
                  To close your account and delete your data,{" "}
                  <span className="link" onClick={handleContactUs}>
                    contact us.
                  </span>
                </div>
              </section>

              {profileDetails.surmount_plan?.monthly_price === 0 &&
                profileDetails.surmount_plan?.yearly_price === 0 && (
                  <div className="subscribe-plan d-none">
                    You are currently on a free plan to subscribe premium{" "}
                    <span className="link" onClick={navigateToPlans}>
                      click here
                    </span>
                  </div>
                )}
            </Col>
          </Row>
        )}
      </div>
    </ProfileContainer>
  );
};
