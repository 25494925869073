import axios from "axios";

import { interceptor } from "./interceptor";
import { BASE_URL } from "utils/constants";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
});

// enable axios interceptor
interceptor(instance);

// common request instance
const request = async ({ method, url, data, headers, baseURL }) => {
  try {
    let promise;
    if (method === "get") {
      promise = instance.get(url, { headers, baseURL });
    }
    if (method === "post" || method === "put" || method === "patch") {
      promise = instance[method](url, data, { headers, baseURL });
    }
    if (method === "delete") {
      promise = instance.delete(url, { data, headers, baseURL });
    }

    const response = await promise;
    const payload = response.data;
    if (headers) {
      return {
        data: payload,
        headers: response.headers,
      };
    }

    return payload;
  } catch (error) {
    console.log("error from httpService: ", error.message);
    throw error;
  }
};

export const get = (url, params) => request({ method: "get", url, ...params });
export const post = (url, data, params) =>
  request({ method: "post", url, data, ...params });
export const put = (url, data, params) =>
  request({ method: "put", url, data, ...params });
export const patch = (url, data, params) =>
  request({ method: "patch", url, data, ...params });
export const del = (url, data) => request({ method: "delete", url, data });
