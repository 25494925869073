import { createSlice } from "@reduxjs/toolkit";

import {
  setPortfoliosReducer,
  setTradingDetailReducer,
  setSelectedStrategyReducer,
  setIsTradingDetailLoadingReducer,
  setSelectedPortfolioReducer,
} from "./reducer";

const initialState = {
  portfolios: [],
  tradingDetail: {},
  selectedStrategy: {},
  isTradingDetailLoading: true,
  selectedPortfolio: {},
};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setPortfolios: setPortfoliosReducer,
    setTradingDetail: setTradingDetailReducer,
    setSelectedStrategy: setSelectedStrategyReducer,
    setIsTradingDetailLoading: setIsTradingDetailLoadingReducer,
    setSelectedPortfolio: setSelectedPortfolioReducer,
  },
});

export const {
  setPortfolios,
  setTradingDetail,
  setSelectedStrategy,
  setIsTradingDetailLoading,
  setSelectedPortfolio,
} = portfolioSlice.actions;
export default portfolioSlice.reducer;
