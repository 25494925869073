import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  MarketplaceIcon,
  StrategiesIcon,
  HelpCenterIcon,
  DashboardIcon,
  ArrowLeftIcon,
} from "assets/icons/menu";
import { isChildRoute } from "utils";
import { useNavigation } from "utils/navigate";
import { Button } from "components/Button";
import LocationPath from "hooks/locationPath";
import { setCollapsedSidebar } from "Layout/slice";
import { AppMenuContainer } from "./Sidebar.styles";
import { closeIcon, hamburgerIcon } from "assets/images";
import { collapsedSidebarSelect } from "Layout/slice/selector";

const AppMenu = ({ active }) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const collapsed = useSelector(collapsedSidebarSelect);

  const {
    navigateToPortfolio,
    navigateToStrategyList,
    navigateToMarketplace,
    navigateToHelpCenter,
    navigateBackToParent,
  } = useNavigation();

  const [showBackButton, setShowBackButton] = useState(false);

  const handleNavigateToPortfolio = () => {
    toggleCollapsed();
    navigateToPortfolio();
  };

  const handleNavigateToStrategyList = () => {
    toggleCollapsed();
    navigateToStrategyList();
  };

  const handleNavigateToHelpCenter = () => {
    toggleCollapsed();
    navigateToHelpCenter();
  };

  const handleNavigateToMarketplace = () => {
    toggleCollapsed();
    navigateToMarketplace();
  };

  const handleNavigateToParent = () => {
    toggleCollapsed();
    navigateBackToParent();
  };

  const handleChangePath = (pathname) => {
    if (isChildRoute(pathname)) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  };

  const toggleCollapsed = () => {
    dispatch(setCollapsedSidebar(!collapsed));
  };

  const BackIcon = () => {
    return (
      <Button
        size="small"
        className="move-back-btn"
        width="100%"
        onClick={handleNavigateToParent}
      >
        <img src={ArrowLeftIcon} />
      </Button>
    );
  };

  return (
    <AppMenuContainer>
      <LocationPath changedPath={handleChangePath} />
      <ul>
        <li className={"menu-item active"} onClick={toggleCollapsed}>
          <div className="inner-item" tabIndex={0} role="button">
            <span className="icon-wrapper">
              <span className="icon">
                {collapsed && (
                  <img
                    alt="toggle"
                    src={hamburgerIcon}
                    className="hamburger-icon"
                  />
                )}
                {!collapsed && (
                  <img
                    alt="toggle"
                    src={closeIcon}
                    className="hamburger-icon"
                  />
                )}
              </span>
            </span>
            <span className="item-content">Close</span>
          </div>
        </li>

        {showBackButton && (
          <li
            className={classNames("menu-item back-btn", {
              active: active.startsWith("/portfolio") || active === "/",
            })}
          >
            <div className="inner-item" tabIndex={1} role="button">
              {collapsed && (
                <span className="icon-wrapper">
                  <span className="icon">
                    <BackIcon fill={theme.aside.icon.primary} />
                  </span>
                </span>
              )}
              <span className="item-content">
                {!collapsed && (
                  <Button
                    size="small"
                    className="move-back-btn"
                    width="100%"
                    onClick={handleNavigateToParent}
                  >
                    <img src={ArrowLeftIcon} /> Back
                  </Button>
                )}
              </span>
            </div>
          </li>
        )}

        <li
          className={classNames("menu-item wt-nav-portfolio", {
            active: active.startsWith("/portfolio") || active === "/",
          })}
          onClick={handleNavigateToPortfolio}
        >
          <div className="inner-item" tabIndex={2} role="button">
            <span className="icon-wrapper">
              <span className="icon">
                <DashboardIcon fill={theme.aside.icon.primary} />
              </span>
            </span>
            <span className="item-content">Portfolio</span>
          </div>
        </li>

        <li
          className={classNames("menu-item", {
            active: active.startsWith("/marketplace"),
          })}
          onClick={handleNavigateToMarketplace}
        >
          <div className="inner-item" tabIndex={3} role="button">
            <span className="icon-wrapper">
              <span className="icon">
                <div className="marketplace-icon">
                  <MarketplaceIcon fill={theme.aside.icon.primary} />
                </div>
              </span>
            </span>
            <span className="item-content">Marketplace</span>
          </div>
        </li>

        <li
          className={classNames("menu-item", {
            active: active.startsWith("/strategy"),
          })}
          onClick={handleNavigateToStrategyList}
        >
          <div className="inner-item" tabIndex={4} role="button">
            <span className="icon-wrapper">
              <span className="icon">
                <StrategiesIcon fill={theme.aside.icon.primary} />
              </span>
            </span>
            <span className="item-content">Strategies</span>
          </div>
        </li>

        <li
          className={classNames("menu-item helpcenter-toggle", {
            active: active.startsWith("/help-center"),
          })}
          onClick={handleNavigateToHelpCenter}
        >
          <div className="inner-item" tabIndex={5} role="button">
            <span className="icon-wrapper">
              <span className="icon">
                <HelpCenterIcon fill={theme.aside.icon.primary} />
              </span>
            </span>
            <span className="item-content"> {!collapsed && "Help Center"}</span>
          </div>
        </li>
      </ul>
    </AppMenuContainer>
  );
};

AppMenu.defaultProps = {
  active: "",
};

AppMenu.propTypes = {
  active: PropTypes.string,
};

export { AppMenu };
