import {
  AUTH_TOKEN_KEY,
  BANNER_KEY,
  SIGNUP_EMAIL_KEY,
  STORAGE_PERSIST_KEY,
  USER_ID_KEY,
} from "./constants";
import { getEncrypted, getDencrypted } from "./security";

const getStorage = () => {
  const persist = localStorage.getItem(STORAGE_PERSIST_KEY);
  if (persist === null) {
    localStorage.clear();
  }
  return JSON.parse(persist) === true ? localStorage : sessionStorage;
};

export const getItemFromStorage = (key) => {
  const storage = getStorage();
  const encData = storage.getItem(key);
  if (encData) {
    const decData = getDencrypted(encData);
    return JSON.parse(decData);
  }
  return null;
};

export const setItemInStorage = (key, data) => {
  if (data) {
    const storage = getStorage();
    const stringifyData = JSON.stringify(data);
    const encData = getEncrypted(stringifyData);
    storage.setItem(key, encData);
  }
};

export const removeItemFromStorage = (key) => {
  if (key) {
    const storage = getStorage();
    storage.removeItem(key);
  }
};

export const appClearStorage = () => {
  removeItemFromStorage(USER_ID_KEY);
  removeItemFromStorage(AUTH_TOKEN_KEY);
  removeItemFromStorage(BANNER_KEY);
  removeItemFromStorage(SIGNUP_EMAIL_KEY);
};

// For async localstorage persistance
export const asyncSetItem = (key, data) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(key, data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const asyncGetItem = (key) => {
  return new Promise((resolve, reject) => {
    try {
      const data = localStorage.getItem(key);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

// For localstorage persistance
export const setItemPersist = (key, data) => {
  if (key) {
    const stringifyData = JSON.stringify(data);
    const encData = getEncrypted(stringifyData);
    localStorage.setItem(key, encData);
  }
};

export const getItemPersist = (key) => {
  const encData = localStorage.getItem(key);
  if (encData) {
    const decData = getDencrypted(encData);
    return JSON.parse(decData);
  }
  return null;
};
