import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";

import { AlertCircleIcon } from "assets/icons/strategy";

export const Tooltip = ({ children, tooltipValue, placement }) => {
  const renderTooltip = (props) => {
    return (
      <BSTooltip {...props}>
        <span>{tooltipValue || "N/A"}</span>
      </BSTooltip>
    );
  };

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 200 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  tooltipValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  children: <img src={AlertCircleIcon} />,
  placement: "right",
};
