import styled from "styled-components";
import BTTable from "react-bootstrap/Table";

export const TableContainer = styled(BTTable)`
  color: ${({ theme }) => theme.global.color.bodyColor};
  border-color: ${({ theme }) => theme.table.border.color};

  &.table > tbody {
    vertical-align: middle;

    tr:hover {
      background-color: ${({ theme }) => theme.table.bg.primary};
    }
  }

  .table-cell {
    text-align: left;
    padding: 0.5rem;
  }

  .no-data-text {
    color: #3c587c;
    text-align: center;
    border: none;
    padding: 20px 0px;
  }

  .data-count-text {
    color: ${({ theme }) => theme.global.color.bodyColor};
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
  }

  .table-pagination {
    border: none;

    .pagination-btn {
      background-color: ${({ theme }) => theme.table.pagination.bg.color};
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
    }
    .button-small {
      padding: 8px 6px;
    }
    .next {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .prev {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .inactive-page {
      background-color: ${({ theme }) => theme.table.pagination.bg.color};
    }
  }
`;
