import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
  h2 {
    margin-bottom: 5px;
  }

  .security-password-input {
    input {
      margin-bottom: 30px;
    }

    form .row {
      height: 7rem;
    }
  }

  button {
    margin: 40px 0 0;
    width: 350px;
  }

  .password-success-modal {
    .modal-dialog {
      margin-top: 100px;
      .modal-content {
        width: 392px !important;
        .logo-img {
          .img {
            height: 110px;
            width: 110px;
          }
        }
        .modal-body {
          div {
            .heading-space {
              .heading {
                font-weight: 700;
                font-size: 23px !important;
              }
            }
          }
        }
      }
    }
  }

  .two-factor-auth {
    margin: 4rem 0;
  }

  .submit-btn {
    width: 100%;
  }
`;
