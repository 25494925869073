import { ChangePassword } from "./ChangePassword";
import { TwoFactorAuthentication } from "./TwoFactorAuthentication";

export const Security = () => {
  return (
    <>
      <div className="mb-5">
        <TwoFactorAuthentication />
      </div>
      <ChangePassword />
    </>
  );
};
