import { post, get, del, put } from "services/httpService";

const SERVICE_URLS = {
  createStrategy: () => "my-strategies/",
  getStrategies: () => "my-strategies/",
  getStrategiesByPage: (qs) => `my-strategies/?${qs}`,
  getCommunityStrategies: (qs) => `community-strategies/?${qs}`,
  getStrategyDetails: (strategyId) => `strategy/${strategyId}/details/`,
  getAllPredefinedScenarios: () => "backtest-scenarios/all/",
  backtestResults: (strategyId, qs) => `strategy/${strategyId}/backtest/?${qs}`,
  backtestStrategy: (strategyId, qs) => `strategy/${strategyId}/backtest/${qs}`,
  strategyAPI: (strategyId) => `strategy/${strategyId}/edit/`,
  cloneStrategy: () => `strategy/clone/`,
  saveStrategyDescription: () => `strategy/edit-description/`,
  hostStrategy: (strategyId) => `strategy/${strategyId}/publish/`,
  getStrategyTemplates: () => `strategy-templates/`,
  generateStrategy: () => `generate-strategy/`,
  // Bookmark endpoints
  bookmarkStrategy: () => `bookmark-strategy/`,
  unbookmarkStrategy: () => `bookmark-strategy/`,
  // Start Trading endpoints
  startStrategy: () => `trading-connection/create/`,
  pauseStrategy: () => `trading-connections/pause/`,
  stopStrategy: () => `trading-connections/cancel/`,
  reactivateStrategy: () => `trading-connections/reactivate/`,
  prepareUnsubscribe: (strategyId) =>
    `prepare-unlicense/?strategy=${strategyId}`,
  strategyUnsubscribe: () => `license-strategy/`,
  updateStrategyAllocationAmount: (portfolioId) =>
    `update-trading-allocation/${portfolioId}/`,
  NCBStrategyAPI: (strategyId) => `strategy/nocode/${strategyId}/edit/`,
  getNCBTargetAssets: (qs) => `nocode/assets/?${qs}`,
  getTradingConnectionInfo: (qs) => `trading-connection/create/?${qs}`,
  updateTradeConnection: (accountId) =>
    `update-trading-allocation/${accountId}/`,
  getIndustry: () => `dynamic-json-schema?type=INDUSTRY`,
};

export const getAllPredefinedScenarios = () =>
  get(SERVICE_URLS.getAllPredefinedScenarios());

export const backtestResults = (strategyId, qs) =>
  get(SERVICE_URLS.backtestResults(strategyId, qs));

export const getStrategyCode = (strategyId) =>
  get(SERVICE_URLS.strategyAPI(strategyId));

export const getStrategies = () => get(SERVICE_URLS.getStrategies());

export const getStrategiesByPage = (qs) =>
  get(SERVICE_URLS.getStrategiesByPage(qs));

export const getStrategyDetails = (strategyId) =>
  get(SERVICE_URLS.getStrategyDetails(strategyId));

export const getCommunityStrategies = (qs) =>
  get(SERVICE_URLS.getCommunityStrategies(qs));

export const createStrategy = (payload) =>
  post(SERVICE_URLS.createStrategy(), payload);

export const backtestStrategy = (strategyId, qs, payload) => {
  if (qs) qs = `?${qs}`;
  return post(SERVICE_URLS.backtestStrategy(strategyId, qs), payload);
};

export const saveStrategy = (strategyId, payload) =>
  post(SERVICE_URLS.strategyAPI(strategyId), payload);

export const deleteStrategy = (strategyId) =>
  del(SERVICE_URLS.strategyAPI(strategyId));

export const bookmarkStrategy = (payload) =>
  post(SERVICE_URLS.bookmarkStrategy(), payload);

export const unbookmarkStrategy = (payload) =>
  del(SERVICE_URLS.unbookmarkStrategy(), payload);

export const startStrategy = (payload) =>
  post(SERVICE_URLS.startStrategy(), payload);

export const pauseStrategy = (payload) =>
  post(SERVICE_URLS.pauseStrategy(), payload);

export const stopStrategy = (payload) =>
  post(SERVICE_URLS.stopStrategy(), payload);

export const prepareUnsubscribe = (strategyId) =>
  get(SERVICE_URLS.prepareUnsubscribe(strategyId));

export const strategyUnsubscribe = (payload) =>
  del(SERVICE_URLS.strategyUnsubscribe(), payload);

export const reactivateStrategy = (payload) =>
  post(SERVICE_URLS.reactivateStrategy(), payload);

export const cloneStrategy = (payload) =>
  post(SERVICE_URLS.cloneStrategy(), payload);

export const saveStrategyDescription = (payload) =>
  post(SERVICE_URLS.saveStrategyDescription(), payload);

export const hostStrategy = (strategyId, payload) =>
  post(SERVICE_URLS.hostStrategy(strategyId), payload);

export const getStrategyTemplates = () =>
  get(SERVICE_URLS.getStrategyTemplates());

export const generateStrategy = (payload) =>
  post(SERVICE_URLS.generateStrategy(), payload);

export const updateStrategyAllocationAmount = (portfolioId, payload) =>
  put(SERVICE_URLS.updateStrategyAllocationAmount(portfolioId), payload);

export const getNCBTargetAssets = (qs) =>
  get(SERVICE_URLS.getNCBTargetAssets(qs));

export const saveNCBStrategyBot = (strategyId, payload) =>
  post(SERVICE_URLS.NCBStrategyAPI(strategyId), payload);

export const getNCBStrategyBot = (strategyId) =>
  get(SERVICE_URLS.NCBStrategyAPI(strategyId));

export const getTradingConnectionInfo = (qs) =>
  get(SERVICE_URLS.getTradingConnectionInfo(qs));

export const updateTradeConnection = (accountId, payload) =>
  put(SERVICE_URLS.updateTradeConnection(accountId), payload);

export const getIndustry = () => get(SERVICE_URLS.getIndustry());
