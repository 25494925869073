import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { themeState } from "Layout/slice/selector";
import { THEME_TYPES } from "utils/constants";

export const Loader = (props) => {
  const theme = useSelector(themeState);

  const loaderTheme =
    theme === THEME_TYPES.DARK ? THEME_TYPES.LIGHT : THEME_TYPES.DARK;

  return (
    <div className="loader">
      <Spinner variant={loaderTheme} animation="border" {...props} />
    </div>
  );
};
