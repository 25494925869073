import styled from "styled-components";

import { Modal } from "components/Modal";

export const PremiumPlanModalContainer = styled(Modal)`
  .modal-header {
    justify-content: center;
  }

  .modal-body {
    margin-top: 0;
    padding-bottom: 0;

    .card-wrapper {
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      border: 2px solid ${({ theme }) => theme.card.border.color};
      background: ${({ theme }) => theme.card.bg.secondary};
      padding: 20px;
      text-align: center;
    }

    .dismiss {
      margin-top: 10px;
      cursor: pointer;
      text-align: center;
    }
  }
`;
