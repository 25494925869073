import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import { useTheme } from "styled-components";

import { Input, Form, Select } from "components/Form";
import { ManualTradingPanelContainer } from "./ManualTradingPanel.styles";
import { Button } from "components/Button";
import { AddTargetAssetModal } from "shared/Modal/AddTargetAssetModal";
import { initialValues, validationSchema } from "./validation";
import {
  getAssetTypes,
  getTradeAccounts,
  getTradeAssets,
  getTradeQuote,
  placeOrder,
} from "features/Portfolio/service";
import { toast } from "Layout/slice";
import { debounce, makeQueryParams, parseErrorMessage, roundOff } from "utils";
import { userDetailsState } from "Layout/slice/selector";
import { PremiumPlanModal } from "shared/Modal/PremiumPlanModal";
import { Heading } from "style/components/Heading.styles";
import { SearchIcon as SearchIconImage } from "assets/icons/editor";
import { closeIcon } from "assets/images";
import { AsyncSelect } from "components/Form/AsyncSelect";

export const ManualTradingPanel = ({ onHide }) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsState);

  const [showAddAssetModal, setShowAddAssetModal] = useState(false);
  const [showPremiumPlan, setShowPremiumPlan] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [portfolios, setPortfolio] = useState([]);
  const [estimated, setEstimated] = useState(0);
  const [symbolSearch, setSymbolSearch] = useState("");

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues,
    validationSchema,
  });

  const { values } = formik;

  const debouncedSearch = useMemo(
    () => debounce(handleSearchChange, 500),
    [symbolSearch]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getAssetTypes();
        const allTypes = response.map((type) => ({
          label: type[0],
          value: type[1],
        }));
        setAssetTypes(allTypes);
      } catch (error) {
        dispatch(
          toast({
            type: "error",
            body: parseErrorMessage(error),
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (values.assets) {
        try {
          const qs = makeQueryParams({
            asset_type: values.assets,
          });
          const response = await getTradeAccounts(qs);
          setPortfolio(response);
        } catch (error) {
          dispatch(
            toast({
              type: "error",
              body: parseErrorMessage(error),
            })
          );
        }
      }
    })();
  }, [values.assets]);

  useEffect(() => {
    (async () => {
      if (values.symbol) {
        try {
          const qs = makeQueryParams({
            symbol: values.symbol,
          });
          const response = await getTradeQuote(qs);
          setEstimated(response.price);
        } catch (error) {
          dispatch(
            toast({
              type: "error",
              body: parseErrorMessage(error),
            })
          );
        }
      }
    })();
  }, [values.symbol]);

  async function handleSubmit(values) {
    try {
      if (!userDetails.surmount_plan?.name) {
        togglePremiumPlanModal();
        return;
      }
      let payload = {
        account_id: values.broker,
        side: values.side,
        symbol: values.symbol,
        quantity: values.amount,
      };

      if (values.price) {
        payload.price = values.price;
      }

      await placeOrder(payload);
      dispatch(
        toast({
          body: "Trade placed!",
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  }

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const togglePremiumPlanModal = () => {
    setShowPremiumPlan(!showPremiumPlan);
  };

  const getSymbols = async (value) => {
    try {
      const qs = makeQueryParams({
        broker_account: values.broker,
        asset_type: values.assets,
        search: value,
      });
      const response = await getTradeAssets(qs);
      return response;
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  async function handleSearchChange(inputValue, callback) {
    setSymbolSearch(inputValue);
    const allSymbols = await getSymbols(inputValue);
    callback(allSymbols);
  }

  return (
    <>
      <ManualTradingPanelContainer>
        <Heading>Quick Trade</Heading>

        <img className="close-icon" src={closeIcon} onClick={onHide} />

        <Form className="form" onSubmit={formik.handleSubmit}>
          <Select
            options={assetTypes}
            placeholder="Select Assets Type"
            className="mb-3"
            name="select-order-assets"
            onChange={(val) => {
              handleChange("assets", val.value);
            }}
          />

          {formik.touched.assets && formik.errors.assets && (
            <div className="form-error">{formik.errors.assets}</div>
          )}

          <Select
            options={portfolios}
            placeholder="Select Broker Account"
            name="select-order-broker"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.broker_account_id}
            onChange={(val) => {
              handleChange("broker", val.broker_account_id);
            }}
            className="mb-3"
          />

          {formik.touched.broker && formik.errors.broker && (
            <div className="form-error">{formik.errors.broker}</div>
          )}

          <AsyncSelect
            loadOptions={debouncedSearch}
            placeholder="Search Symbol"
            name="select-order-symbol"
            onChange={(val) => {
              handleChange("symbol", val.symbol);
            }}
            className="mb-3"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.symbol}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <SearchIconImage
                  stroke={theme.heading.color.secondary}
                  className="search-icon"
                />
              ),
            }}
          />
          {formik.touched.symbol && formik.errors.symbol && (
            <div className="form-error">{formik.errors.symbol}</div>
          )}

          <div className="button-wrapper">
            <Button
              className={classNames({
                buy: values.side === "buy",
              })}
              onClick={() => handleChange("side", "buy")}
            >
              Buy
            </Button>
            <Button
              className={classNames({
                sell: values.side === "sell",
              })}
              onClick={() => handleChange("side", "sell")}
            >
              Sell
            </Button>
          </div>

          <div className="button-wrapper">
            <Button
              className={classNames({
                active: values.orderType === "shares",
              })}
              onClick={() => handleChange("orderType", "shares")}
            >
              Shares
            </Button>
            <Button
              className={classNames({
                active: values.orderType === "dollars",
              })}
              onClick={() => handleChange("orderType", "dollars")}
            >
              Dollars
            </Button>
          </div>

          <Input
            label=""
            type="number"
            placeholder={
              values.orderType === "dollars" ? "Dollars Amount" : "Share Amount"
            }
            {...formik.getFieldProps("amount")}
          />
          {formik.touched.amount && formik.errors.amount && (
            <div className="form-error">{formik.errors.amount}</div>
          )}

          <div className="divider" />

          <div className="estimated">
            ${roundOff(estimated)} Estimated Value
          </div>

          <Button type="submit" size="small" className="btn-place-order">
            Review Order
          </Button>
        </Form>

        {showAddAssetModal && (
          <AddTargetAssetModal
            show={showAddAssetModal}
            onHide={() => {
              setShowAddAssetModal(false);
            }}
          />
        )}
      </ManualTradingPanelContainer>

      {showPremiumPlan && (
        <PremiumPlanModal
          show={showPremiumPlan}
          onHide={togglePremiumPlanModal}
        />
      )}
    </>
  );
};

ManualTradingPanel.propTypes = {
  onHide: PropTypes.func,
};
