import styled from "styled-components";
import { Modal } from "components/Modal";

export const ProgressModalContainer = styled(Modal)`
  .modal-dialog {
    margin-top: 70px;
    max-width: 392px;
    min-height: 200px;
    box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 20%),
      0 6px 20px 0 rgb(0 0 0 / 19%);
    .modal-content {
      min-height: 200px;
      padding-bottom: 0px;
      .btn-close {
        display: none;
      }
      h2 {
        font-weight: 700;
        font-size: 23px;
        margin-bottom: 20px;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        margin-top: 10px;

        text-align: center;
      }
      .modal-body {
        h2 {
          margin-top: 35px;
          font-weight: 700;
          font-size: 23px;
          line-height: 30px;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
        }
      }
    }
  }
`;
