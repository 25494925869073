import { useEffect, useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Button } from "components/Button";
import { CustomDropdownContainer } from "./Dropdown.styles";

export const CustomDropdown = ({
  label,
  buttonText,
  children,
  open,
  toggleOpen,
  isFloatedLabel,
  onHide,
}) => {
  const ref = useRef(null);

  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    toggleOpen();
    setTarget(event.target);
  };

  const closeOpenMenus = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onHide();
      setTarget(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  return (
    <CustomDropdownContainer ref={ref}>
      <div
        className={classNames({
          "floated-label": isFloatedLabel,
        })}
      >
        {label && <label>{label}</label>}
        <Button className="btn-select" onClick={handleClick}>
          {buttonText}
        </Button>
      </div>

      <Overlay
        show={open}
        target={target}
        placement="bottom-start"
        container={ref}
        containerPadding={20}
      >
        <Popover>
          <Popover.Body>{children}</Popover.Body>
        </Popover>
      </Overlay>
    </CustomDropdownContainer>
  );
};

CustomDropdown.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  toggleOpen: PropTypes.func,
  onHide: PropTypes.func,
  isFloatedLabel: PropTypes.bool,
};

CustomDropdown.defaultProps = {
  open: false,
  isFloatedLabel: false,
};
