import styled from "styled-components";
import PropTypes from "prop-types";

import { Placeholder } from "components/Placeholder";

const TablePlaceholderContainer = styled.div`
  .order-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8em;
    margin-bottom: 10px;

    .placeholder-glow {
      flex: 1;
    }

    .symbol,
    .side {
      width: 200px;
    }
  }
`;

const RowPlaceholder = () => {
  return (
    <TablePlaceholderContainer>
      <Placeholder
        size="lg"
        col={{ xs: 12, lg: 12 }}
        className="placeholder-custom"
      />
    </TablePlaceholderContainer>
  );
};

export const TablePlaceholder = ({ headerCount }) => {
  const placeholders = Array.from({ length: 3 }, (_, index) => (
    <tr key={index}>
      {Array.from({ length: headerCount }, (_, i) => (
        <td key={i}>
          <RowPlaceholder />
        </td>
      ))}
    </tr>
  ));

  return <>{placeholders}</>;
};

TablePlaceholder.propTypes = { headerCount: PropTypes.number };
