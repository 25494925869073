export const setPortfoliosReducer = (state, action) => {
  state.portfolios = action.payload;
};

export const setTradingDetailReducer = (state, action) => {
  state.tradingDetail = action.payload;
};

export const setSelectedStrategyReducer = (state, action) => {
  state.selectedStrategy = action.payload;
};

export const setIsTradingDetailLoadingReducer = (state, action) => {
  state.isTradingDetailLoading = action.payload;
};

export const setSelectedPortfolioReducer = (state, action) => {
  state.selectedPortfolio = action.payload;
};
