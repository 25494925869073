import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .pie-chart {
    .recharts-legend-wrapper {
      .recharts-default-legend {
        padding-right: 40px !important;
        .recharts-legend-item {
          margin-bottom: 20px;
        }
      }
      .line {
        border-radius: 10px;
        width: 15px;
        height: 2px;
        display: inline-block;
        margin-right: 6px;
      }
      ul {
        display: block;
        width: 280px;
        padding-left: 0;
        li {
          display: inline-block;
          height: 20px;
          width: 65px;
        }
      }
    }
  }

  .recharts-legend-wrapper {
    ul {
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-top: 15px;
    }
    li {
      display: inline-block;
      font-size: 0.7em;
      margin-right: 1em;
      margin-left: 1em;
    }
    .circle {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
  }

  .recharts-tooltip-wrapper{
    &:focus-visible{
      outline: none;
    }
  }
`;

export default GlobalStyle;
