import { useState } from "react";
import PropTypes from "prop-types";
import { light, moon } from "assets/images";
import classNames from "classnames";
import { SwitchContainer } from "./Switch.styles";

export const Switch = ({
  defaultValue,
  size,
  onChange,
  backgroundColor,
  disabled,
  variant,
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = () => {
    if (!disabled) {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  return (
    <SwitchContainer>
      {variant == "primary" ? (
        <div
          className={`primary size-${size} ${disabled ? "disabled" : ""}`}
          style={{ backgroundColor }}
        >
          <span
            onClick={handleChange}
            className={classNames({
              active: !checked,
            })}
          >
            <img src={moon} alt="" />
          </span>
          <span
            onClick={handleChange}
            className={classNames({
              active: checked,
            })}
          >
            <img src={light} alt="" />
          </span>
        </div>
      ) : (
        <div className={`secondary size-${size} ${disabled ? "disabled" : ""}`}>
          <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
            />
            <span
              className={`slider size-${size}`}
              style={{ backgroundColor }}
            ></span>
          </label>
        </div>
      )}
    </SwitchContainer>
  );
};

Switch.propTypes = {
  defaultValue: PropTypes.bool,
  backgroundColor: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  defaultValue: true,
  backgroundColor: "#101c42",
  variant: "primary",
  size: "medium",
  onChange: undefined,
  disabled: false,
};
