import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  initialChangePassValues,
  changePassValidationSchema,
} from "../../validation";
import { toast } from "Layout/slice";
import { parseErrorMessage } from "utils";
import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { changePassword } from "../../service";
import { Input, Form } from "components/Form";
import { ChangePasswordContainer } from "./ChangePassword.styles";
import { Heading } from "style/components/Heading.styles";

export const ChangePassword = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      await changePassword(values);
      formik.resetForm();
      dispatch(
        toast({
          body: "Your password has been updated",
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: initialChangePassValues,
    validationSchema: changePassValidationSchema,
  });

  return (
    <ChangePasswordContainer>
      <section>
        <Heading>Change Password</Heading>
        <div className="divider mt-1 mb-3"></div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <Input
                className="security-password-input"
                label="Current Password*"
                type="password"
                {...formik.getFieldProps("current_password")}
              />
              {formik.touched.current_password &&
              formik.errors.current_password ? (
                <p className="form-error">{formik.errors.current_password}</p>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xl={4} lg={5} md={6}>
              <Input
                className="security-password-input"
                label="New Password*"
                type="password"
                {...formik.getFieldProps("new_password")}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <p className="form-error">{formik.errors.new_password}</p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <Input
                className="security-password-input"
                label="Confirm New Password*"
                type="password"
                {...formik.getFieldProps("confirm_new_password")}
              />
              {formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password ? (
                <p className="form-error">
                  {formik.errors.confirm_new_password}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <Button
                type="submit"
                className="submit-btn"
                disabled={!formik.isValid}
              >
                {formik.isSubmitting ? (
                  <Loader className="on-btn" />
                ) : (
                  "Update Now"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </section>
    </ChangePasswordContainer>
  );
};
