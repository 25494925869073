import { lazy } from "react";

const HelpCenter = lazy(() => import("features/HelpCenter"));

export const HELP_CENTER_PATH = "/help-center";

export default [
  {
    path: HELP_CENTER_PATH,
    component: <HelpCenter />,
  },
];
