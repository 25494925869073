export const SelectStyles = (theme) => ({
  option: (provided) => ({
    ...provided,
    color: theme.dropdown.font.secondary,
    opacity: 0.7,
    backgroundColor: theme.dropdown.bg.primary,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: theme.input.bg.primary,
    color: theme.dropdown.font.secondary,
    border: `1.5px solid ${theme.input.bg.primary}`,
    borderRadius: theme.constants.BORDER_RADIUS_8,
    padding: "5px 10px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.dropdown.bg.primary,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.dropdown.font.primary,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
  }),
  input: (provided) => ({
    ...provided,
    color: theme.dropdown.font.primary,
  }),
});
