import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { contains } from "utils";
import { toast } from "Layout/slice";
import { Button } from "components/Button";
// import { useNavigation } from "utils/navigate";
import { EARLY_USER_MESSAGE } from "utils/constants";
import { userDetailsState } from "Layout/slice/selector";
import { AvailablePlanCardContainer } from "./PlanCard.styles";

export const AvailablePlanCard = ({ plan }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsState);

  // const { navigateToAccountPaymentPlanDetail } = useNavigation();

  const { card } = JSON.parse(plan.description);
  const price = plan.price.unit_amount;

  const onPlanSelect = () => {
    dispatch(
      toast({
        body: EARLY_USER_MESSAGE,
      })
    );
    // navigateToAccountPaymentPlanDetail(plan.price.id, { state: plan });
  };

  return (
    <AvailablePlanCardContainer>
      <div className="plan-name">{plan.name}</div>
      <div className="amount">
        <div
          className={classNames({
            "line-through": price !== 0,
          })}
        >
          ${price}
        </div>

        {price !== 0 && (
          <div>
            $0
            <span>/month</span>
          </div>
        )}
      </div>

      <div>
        <Button
          disabled={contains(plan.name, userDetails?.surmount_plan?.name)}
          onClick={onPlanSelect}
          className="get-start-btn"
          label={
            contains(plan.name, userDetails?.surmount_plan?.name)
              ? "Your Current Plan"
              : "Switch to this plan"
          }
        />
        <ul>
          {Object.values(card).map((benefit, index) => {
            return <li key={index}>{benefit}</li>;
          })}
        </ul>
      </div>
    </AvailablePlanCardContainer>
  );
};

AvailablePlanCard.propTypes = {
  plan: PropTypes.object,
};
