import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Heading } from "style/components/Heading.styles";
import { TwoFactorAuthenticationContainer } from "./TwoFactorAuthentication.styles";
import { Switch } from "components/Switch";
import { Tooltip } from "components/Tooltip";
import { infoIcon } from "assets/icons/strategy";
import { userDetailsState } from "Layout/slice/selector";
import { toast } from "Layout/slice";
import { parseErrorMessage } from "utils";
import { change2FA } from "../../service";
import ChangePhone from "../../Components/ChangePhone";

export const TwoFactorAuthentication = () => {
  const dispatch = useDispatch();
  const profileDetails = useSelector(userDetailsState);

  const [enable, setEnable] = useState(false);

  const handleSwitchChange = async (value) => {
    if (profileDetails?.phone_verified) {
      handleChange2FA(value);
    } else {
      setEnable(value);
    }
  };

  const handleChange2FA = async (value) => {
    try {
      await change2FA({
        value: value ? "ON" : "OFF",
      });
      dispatch(
        toast({
          body: `2FA has been ${value ? "enabled" : "disabled"} successfully!`,
        })
      );
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  const handleVerifiedCode = () => {
    setEnable(false);
    handleChange2FA(true);
  };

  return (
    <TwoFactorAuthenticationContainer>
      <Heading>Two-Factor Authentication</Heading>
      <div className="divider mt-1 mb-3"></div>

      <div className="switch-wrapper">
        Enable Two-Factor auth
        <Tooltip
          placement="auto"
          tooltipValue="For better security, we highly encourage all users to turn on Two-Factor auth. This requires you to input a 6-digit text code when you sign in to verify your identity"
        >
          <img src={infoIcon} height={20} width={20} />
        </Tooltip>
        <Switch
          variant="secondary"
          size="small"
          onChange={handleSwitchChange}
          defaultValue={profileDetails?.two_factor_auth_enabled ? true : false}
        />
      </div>

      {enable && <ChangePhone onSuccess={handleVerifiedCode} />}
    </TwoFactorAuthenticationContainer>
  );
};
