import rcolor from "rcolor";

import {
  flatIcon,
  bullishIcon,
  bearishIcon,
  choppyIcon,
  choppyBlueIcon,
  bullishBlueIcon,
  bearishBlueIcon,
  flatBlueIcon,
} from "assets/icons/backtest";
import { COLOR_GREEN } from "style/theme";
import { getItemFromStorage } from "./storage";
import suggestionsList from "./pythonSuggestions.json";
import brokerWalkthrough from "./brokerWalkthrough.json";
import { AUTH_TOKEN_KEY, DEPLOY_STATUS, FALLBACK_LANG } from "./constants";

export default suggestionsList;

export const contains = (target, str) => {
  return (target || "").includes(str);
};

export const isPaperAcount = (str) => {
  return contains(str.toLowerCase(), "paper");
};

export const isLocaleEn = (locale) => {
  return contains(locale, FALLBACK_LANG);
};

export const getBrokerIconByKey = (brokerKey, theme) => {
  let icon = "paper_surmount.png";

  if (brokerKey) {
    icon = brokerKey;
    if (theme === "light" && contains(brokerKey, "paper")) {
      icon += `_light`;
    }
    icon += `.svg`;
  }
  return `https://broker-icons-public.s3.amazonaws.com/${icon}`;
};

export const getBrokerIcon = (url, theme) => {
  let _url = url;

  if (theme === "light" && contains(_url, "surmount_paper")) {
    _url = _url.replace("surmount_paper", "surmount_paper_light");
  }
  return _url;
};

export const setAssetIconByKey = (assetKey) => {
  if (!assetKey) {
    return;
  }
  return `https://financialmodelingprep.com/image-stock/${assetKey}.png`;
};

export const roundOff = (value, decimals = 2) => {
  if (value && typeof value == "number") {
    return value.toFixed(decimals);
  }
  return 0;
};

export const roundNumber = (value) => {
  if (value && typeof value == "number") {
    return Math.round(value);
  }
  return 0;
};

const average = (numbers) => {
  if (numbers.length) {
    const avg = numbers.reduce((a, b) => a + b, 0) / numbers.length;
    return roundOff(Number(avg));
  } else {
    return 0;
  }
};

export const mapPerformaceData = ({
  day = null,
  week = null,
  threeMonths = null,
  year = null,
}) => {
  let dayAvg = day;
  let weekAvg = week;
  let threeMonthsAvg = threeMonths;
  let yearAvg = year;

  if (dayAvg && dayAvg.profit_loss_pct) {
    dayAvg = average(dayAvg.profit_loss_pct);
  }
  if (weekAvg && weekAvg.profit_loss_pct) {
    weekAvg = average(weekAvg.profit_loss_pct);
  }
  if (threeMonthsAvg && threeMonthsAvg.profit_loss_pct) {
    threeMonthsAvg = average(threeMonthsAvg.profit_loss_pct);
  }
  if (yearAvg && yearAvg.profit_loss_pct) {
    yearAvg = average(yearAvg.profit_loss_pct);
  }

  dayAvg = Number.isInteger(dayAvg) ? roundOff(dayAvg) : dayAvg;
  weekAvg = Number.isInteger(weekAvg) ? roundOff(weekAvg) : weekAvg;
  threeMonthsAvg = Number.isInteger(threeMonthsAvg)
    ? roundOff(threeMonthsAvg)
    : threeMonthsAvg;
  yearAvg = Number.isInteger(yearAvg) ? roundOff(yearAvg) : yearAvg;

  return [
    {
      name: "Day",
      value: dayAvg,
      isProfit: dayAvg > 0,
    },
    {
      name: "Week",
      value: weekAvg,
      isProfit: weekAvg > 0,
    },
    {
      name: "3 Months",
      value: threeMonthsAvg,
      isProfit: threeMonthsAvg > 0,
    },
    {
      name: "Year",
      value: yearAvg,
      isProfit: yearAvg > 0,
    },
  ];
};

export const openAlpacaPopUp = (uri) => {
  return new Promise((resolve) => {
    const authWindow = window.open(uri);
    let snippet = uri | null;

    const interval = setInterval(async () => {
      try {
        snippet =
          authWindow && authWindow.location && authWindow.location.search;
      } catch (error) {
        console.error("error: ", error);
      }
      if (snippet) {
        const rawCode = snippet.substring(1);
        const code = JSON.parse(
          '{"' + rawCode.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        authWindow.close();
        resolve(code);
        clearInterval(interval);
      }
    }, 100);
  });
};

export const isLoggedIn = () => {
  const token = getItemFromStorage(AUTH_TOKEN_KEY);
  if (token) {
    return true;
  }
  return false;
};

export const getStatusValues = (deploy_status) => {
  return DEPLOY_STATUS[deploy_status];
};

export const transformString = (str) => {
  if (!str?.length) {
    return "";
  }

  let convertedStr = str.charAt(0).toUpperCase() + str.substring(1);

  convertedStr = convertedStr.replace("c", "C");

  if (contains(str, "_")) {
    return convertedStr.split("_").join(" ");
  } else {
    return convertedStr.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
};

export const getPerformanceCellInfo = (value) => {
  let sign = "";
  let className = "";
  if (value < 0) {
    className = "red-value";
  } else if (value > 0) {
    sign = "+";
    className = "green-value";
  }
  return { className, sign };
};

export const getStatusCellClass = (value) => {
  let className = "";
  if (value == "Active") {
    className = "text-success";
  } else if (value == "Stop") {
    className = "text-primary";
  }
  return className;
};

export const buttonTextHandler = (subscriptionStatus) => {
  if (subscriptionStatus === "Active") {
    return { id: "pause", title: "Pause Strategy" };
  } else {
    return {
      id: "start",
      title: "Start Strategy",
    };
  }
};

export const openNewTab = (url, target = "_blank") => {
  window.open(url, target);
};

export const reloadTab = () => {
  window.location.reload();
};

export const setAmountColor = (value, isProfit) => {
  if (value == "0.00" || value == "0") {
    return "default";
  }
  return isProfit ? "profit" : "loss";
};

export const parseErrorMessage = (error) => {
  let msg = error.message;
  const data = error?.response?.data;
  const err = error?.response?.error;

  if (data?.message) {
    msg = data.message;
  }
  if (err?.message) {
    msg = err.message;
  }
  if (data?.email) {
    const emailErrors = data.email || [];
    msg = emailErrors[0] || "Error Occurred";
  }

  return msg;
};

export const getMaskedEmail = (email) => {
  return email.replace(/^(.{2})[^@]+/, "$1***");
};

export const getStatusColor = (status, theme) => {
  if (status === "Active") {
    return COLOR_GREEN;
  } else if (status === "Not connected") {
    return theme === "light" ? "#048af5" : "#0A7EFF";
  }
  return "#ffffff";
};

export const getRandomColor = () => {
  return rcolor();
};

export const insertSelectedUnit = (value, unit) => {
  const fixedValue = roundOff(Number(value));
  return unit === "%" ? `${fixedValue}${unit}` : `${unit}${fixedValue}`;
};

export const isEmptyObj = (obj) => {
  if (!obj) {
    return false;
  }
  return Object.keys(obj).length === 0;
};

export const renderScenarioIcon = (type, isActive) => {
  const iconMeta = {
    BULL: isActive ? bullishBlueIcon : bullishIcon,
    BEAR: isActive ? bearishBlueIcon : bearishIcon,
    CHOPPY: isActive ? choppyBlueIcon : choppyIcon,
    FLAT: isActive ? flatBlueIcon : flatIcon,
  };

  return iconMeta[type] ?? iconMeta.FLAT;
};

export const sleep = ({ timeInms = 1000 }) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, timeInms);
  });
};

export const makeQueryParams = (obj) => {
  return new URLSearchParams(obj).toString();
};

export const getFileExtension = (filename) => {
  return filename.split(".").pop();
};

export const makeLabel = (str) => {
  if (!str) {
    return "";
  }
  return str.split("_").join(" ");
};

export const mapKeyStats = (stats) => {
  let data = {};
  if (stats?.length) {
    for (let stat of stats) {
      data[stat.name.toLowerCase().replace(" ", "_")] = stat.value;
    }
  }
  return data;
};

export const brokerWalkthroughList = () => {
  return brokerWalkthrough;
};

export const numberSeparator = (value) => {
  if (!value) {
    return 0;
  }
  if (typeof value === "number") {
    return value.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  return value;
};

export const hasObjectEmptyValue = (obj) => {
  if (obj === null) {
    return false;
  }
  return !Object.values(obj).every((val) => {
    if (typeof val === "object") {
      return !hasObjectEmptyValue(val);
    }
    return val !== "";
  });
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(context, args);
    }, delay);
  };
};

export const isJsonObject = (obj) => {
  if (!obj) {
    return false;
  }
  return obj.constructor == Object;
};

export const scrollToDiv = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
};

export const getRiskPotentialLabel = (level) => {
  if (level <= 5 / 3) {
    return "Low";
  } else if (level <= 10 / 3) {
    return "Moderate";
  } else if (level <= 5) {
    return "High";
  }
};

export const calcPercentage = (value, total) => {
  return (value / total) * 100;
};

export const getTimeStampInSeconds = () => {
  return new Date().getTime();
};

export const getConnectedPortfolios = (data) => {
  return data.filter((d) => d.broker !== "paper");
};

export const getFilteredPortfolios = (data, theme) => {
  const _portfolios = [];

  for (let i = 0; i < data.length; i++) {
    let d = data[i];
    const hp = d.historical_performance;
    let prf = {};
    if (hp) {
      prf = {
        day: hp["1d"],
        week: hp["1w"],
        threeMonths: hp["3m"],
        year: hp["1y"],
      };
    }
    d = {
      id: d.id,
      brokerLogo: getBrokerIconByKey(d.broker, theme.mode),
      name: d.nickname || d.broker,
      created_at: d.created_at,
      performance: mapPerformaceData(prf),
      ...d,
    };
    _portfolios.push(d);
  }

  return _portfolios;
};

export const trimTooltipString = (string) => {
  if (!string) return "";
  const length = 600;
  return string.length > length
    ? string.substring(0, length - 3) + "..."
    : string.substring(0, length);
};

export const newAllocationPayload = (strategies, accountValue) => {
  let newAllocation = {
    new_allocation: {},
  };
  for (let index = 0; index < strategies.length; index++) {
    const strategy = strategies[index];
    const dollars = Number(strategy.current_allocation.dollars.raw_number);
    const value = (dollars * 100) / accountValue;
    newAllocation.new_allocation[strategy.strategy_name] = value / 100;
  }

  return newAllocation;
};

export const cleanNumber = (value = "") => {
  return value?.replace("$", "")?.replace(",", "");
};

export const isChildRoute = (pathname) => {
  const count = (pathname.match(/\//g) || []).length;

  if (count > 1) {
    return true;
  }

  return false;
};

export const getFilteredPositions = (data) => {
  let positions = [];
  for (let index = 0; index < data.length; index++) {
    const position = data[index];
    const symbol = position.symbol.symbol.symbol.toLowerCase();

    if (symbol !== "cash") {
      positions.push(position);
    }
  }
  return positions;
};

export const getPercentage = (percent, total) => {
  return (percent / 100) * total;
};

export const getUnique = (data, key) => {
  return [...new Set(data.map((item) => item[key]))];
};

export const toTitleCase = (str) => {
  if (!str) {
    return "";
  }
  let splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const updateAllocationStrategy = (
  { value, stInx },
  strategies,
  accountValue
) => {
  const val = Number(value);
  const allStrategies = JSON.parse(JSON.stringify(strategies));
  const currentAllocation = allStrategies[stInx].current_allocation;

  const percentage = (val / accountValue.raw_number) * 100;
  const parsePercentage = roundOff(percentage, 2);

  currentAllocation.dollars.raw_number = value || 0;
  currentAllocation.percent = percentage > 100 ? "--" : `${parsePercentage}%`;

  return allStrategies;
};

export const validateAllocationAmount = (investedAmount, maxAllocation) => {
  let hasError = true;
  let message = "";

  if (Number.isNaN(investedAmount)) {
    message = "Please enter a valid number";
  } else if (investedAmount < 0) {
    message = `Please enter a number greater than ${0}`;
  } else if (investedAmount > maxAllocation?.raw_number) {
    message = `Your total allocation cannot exceed ${maxAllocation.formatted}`;
  } else {
    message = "";
    hasError = false;
  }

  return { message, hasError };
};
