import { get, post, put, del } from "services/httpService";

const SERVICE_URLS = {
  getStrategyFiles: (strategyId) => `/strategy/${strategyId}/github/repo/`,
  createRepoGithub: (strategyId) => `/strategy/${strategyId}/github/repo/`,
  getFiles: (strategyId) =>
    `strategy/${strategyId}/github/repo/content/?all=true`,
  createUpdateFile: ({ strategyId, fileName }) =>
    `/strategy/${strategyId}/github/repo/content/${fileName}/`,
  getDownloadRepo: (strategyId) =>
    `/strategy/${strategyId}/github/repo/presign/`,
  collaborator: ({ collaboratorName }) =>
    `/user/github/repo/collaborator/${collaboratorName}/invite/`,
  getCollaboratorsInvited: () => `/user/github/repo/collaborator/invite/`,
  getGitUsername: () => `/user/github/`,
  getFileCode: (strategyId, fileName) =>
    `/strategy/${strategyId}/github/repo/content/${fileName}/`,
  uploadFiles: ({ strategyId }) =>
    `/strategy/${strategyId}/github/repo/upload/`,
  deleteFile: ({ strategyId, fileName }) =>
    `/strategy/${strategyId}/github/repo/content/${fileName}/`,
  renameFolder: ({ strategyId, oldName }) =>
    `/strategy/${strategyId}/github/repo/copy/${oldName}/`,
  duplicateFolder: ({ strategyId, oldName }) =>
    `/strategy/${strategyId}/github/repo/copy/${oldName}/`,
  saveAllFiles: ({ strategyId }) =>
    `/strategy/${strategyId}/github/repo/multi-content/`,
  deleteFolder: ({ strategyId, folderName }) =>
    `/strategy/${strategyId}/github/repo/folder/${folderName}/`,
};

export const fetchStrategyRepo = ({ strategyId }) =>
  get(SERVICE_URLS.getStrategyFiles(strategyId));

export const createRepoGithub = ({
  strategyId,
  strategyName,
  useDefault = false,
}) =>
  post(SERVICE_URLS.createRepoGithub(strategyId), {
    repository_name: strategyName,
    use_default_github: useDefault,
  });

export const fetchFiles = ({ strategyId }) =>
  get(SERVICE_URLS.getFiles(strategyId));

export const createUpdateFile = ({
  strategyId,
  fileName,
  message,
  content,
  sha,
}) =>
  post(SERVICE_URLS.createUpdateFile({ strategyId, fileName }), {
    message,
    content,
    sha,
  });

export const fetchDownloadRepo = (strategyId) =>
  get(SERVICE_URLS.getDownloadRepo(strategyId));

export const addCollaborator = ({ collaboratorName }) =>
  put(SERVICE_URLS.collaborator({ collaboratorName }));

export const getIsCollaborator = ({ collaboratorName }) =>
  get(SERVICE_URLS.collaborator({ collaboratorName }));

export const fetchCollaboratorsInvited = () =>
  get(SERVICE_URLS.getCollaboratorsInvited());

export const fetchGitUsername = () => get(SERVICE_URLS.getGitUsername());

export const getFileCode = async ({ strategyId, fileName }) =>
  get(SERVICE_URLS.getFileCode(strategyId, fileName));

export const uploadFiles = ({ strategyId, data }) =>
  post(SERVICE_URLS.uploadFiles({ strategyId }), data);

export const deleteFile = ({ strategyId, fileName, data }) =>
  del(SERVICE_URLS.deleteFile({ strategyId, fileName }), data);

export const renameFolder = ({ strategyId, oldName, newName }) =>
  put(SERVICE_URLS.renameFolder({ strategyId, oldName }), {
    new_folder_path: newName,
    delete_old: true,
    override: false,
  });

export const duplicateFolder = ({ strategyId, oldName, newName }) =>
  put(SERVICE_URLS.duplicateFolder({ strategyId, oldName }), {
    new_folder_path: newName,
    delete_old: false,
    override: false,
  });

export const saveAllFiles = ({ strategyId, data }) =>
  post(SERVICE_URLS.saveAllFiles({ strategyId }), data);
export const deleteFolder = ({ strategyId, folderName }) =>
  del(SERVICE_URLS.deleteFolder({ strategyId, folderName }), {});
