const PRIMARY_FONTS_FAMILY = "Poppins, sans-serif";

const COLOR_WHITE = "#FFFFFF";
const COLOR_BLACK = "#000000";
const COLOR_LIGHT_WHITE = "#ffffff4d";
const COLOR_LIGHTER_WHITE = "#F3F3F3";

const COLOR_CLAY = "#202C40";

const COLOR_LIGHT_GREY = "#eeeeee";
const COLOR_DARK_GREY = "#4a4c55";
const COLOR_LIGHTER_GREY = "#E6E6E6";

const COLOR_TRUE_RED = "#ff8282";

const COLOR_LIGHT_BLUE = "rgba(165, 199, 255, 0.03)";
const COLOR_MEDIUM_BLUE = "#048AF5";
const COLOR_DARK_BLUE = "#121E32";
const COLOR_ALICE_BLUE = "#f6faff";
const COLOR_COBALT_BLUE = "#0A7EFF";
const COLOR_LIGHTER_BLUE = "#0F192D";

const COLOR_LIGHT_CYAN = "#a5c7ff";

export const COLOR_GREEN = "#2bc155";
export const COLOR_RED = "#FF0000";

const themeConstants = {
  FONT_SIZE_12: "12px",
  FONT_SIZE_14: "14px",
  FONT_SIZE_16: "16px",
  FONT_SIZE_18: "18px",
  FONT_SIZE_28: "28px",

  FONT_WEIGHT_400: "400",
  FONT_WEIGHT_500: "500",
  FONT_WEIGHT_600: "600",
  FONT_WEIGHT_700: "700",

  BORDER_RADIUS_8: "8px",
  BORDER_RADIUS_19: "19px",
  BORDER_RADIUS_24: "24px",

  COLOR_GREEN,
  COLOR_RED,
};

export const darkTheme = {
  mode: "dark",
  constants: themeConstants,
  heading: {
    color: {
      primary: COLOR_COBALT_BLUE,
      secondary: COLOR_WHITE,
    },
  },
  subHeading: {
    color: {
      primray: "rgba(255, 255, 255, 0.7)",
      secondary: COLOR_WHITE,
    },
  },
  label: {
    color: {
      primary: "#fff8f885",
    },
  },
  toast: {
    bg: {
      primary: COLOR_CLAY,
      error: COLOR_TRUE_RED,
      info: COLOR_MEDIUM_BLUE,
    },
  },
  global: {
    bg: {
      primary: "#070B12",
      secondary: "rgba(23, 34, 55, 0.3)",
      tertiary: "#1f2127",
      quaternary: "#252730",
      quinary: "#121e31",
      septenary: COLOR_WHITE,
      auth: "#070B12",
    },
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
    extraInfo: {
      font: {
        color: COLOR_WHITE,
      },
    },
    caption: {
      font: {
        color: COLOR_LIGHT_CYAN,
      },
    },
    color: {
      primary: "#101c42",
      secondary: "#000823",
      tertiary: "#048df9",
      quaternary: "#737373",
      quinary: "#0157ff",
      senary: "#0a7eff",
      septenary: "#576f9c",
      bodyColor: COLOR_LIGHT_GREY,
      noData: "#3c587c",
      highlights: COLOR_MEDIUM_BLUE,
      status: "#0a7eff",
      profit: COLOR_GREEN,
      arrow: COLOR_WHITE,
    },
    fonts: {
      poppins: PRIMARY_FONTS_FAMILY,
    },
    text: {
      primary: "rgba(1, 87, 255, 1)",
      secondary: "rgba(238, 238, 238, 1)",
      tertiary: "#dce9ff",
      quaternary: COLOR_LIGHT_GREY,
      active: "rgb(32, 44, 64)",
      grey: COLOR_DARK_GREY,
    },
  },
  button: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: COLOR_DARK_BLUE,
      tertiary: "rgba(165, 199, 255, 0.2)",
      quaternary: "rgb(12 20 31)",
      quinary: COLOR_DARK_BLUE,
      senary: "#737373",
      error: COLOR_TRUE_RED,
      septenary: COLOR_DARK_BLUE,
    },
    font: {
      primary: COLOR_WHITE,
      secondary: COLOR_MEDIUM_BLUE,
      family: PRIMARY_FONTS_FAMILY,
      quaternary: COLOR_WHITE,
      quinary: COLOR_WHITE,
      senary: COLOR_MEDIUM_BLUE,
      septenary: COLOR_MEDIUM_BLUE,
    },
    border: {
      color: COLOR_MEDIUM_BLUE,
    },
    hover: {
      color: "#0084ed",
    },
  },
  input: {
    bg: {
      primary: COLOR_DARK_BLUE,
      secondary: COLOR_WHITE,
    },
    font: {
      primary: COLOR_WHITE,
      secondary: COLOR_MEDIUM_BLUE,
      tertiary: "#ddd",
      quaternary: "#ddd",
      family: PRIMARY_FONTS_FAMILY,
      opacity: 0.5,
    },
    border: {
      color: "rgb(18, 30, 50)",
      secondary: COLOR_WHITE,
    },
    placeholder: {
      color: COLOR_DARK_GREY,
    },
    icon: {
      color: COLOR_DARK_GREY,
    },
  },
  card: {
    bg: {
      primary: "rgba(23, 34, 55, 0.5)",
      secondary: "rgba(32, 44, 64, 0.2)",
      tertiary: COLOR_CLAY,
      quaternary: "rgb(18, 30, 50)",
      quinary: "rgba(32, 44, 64, 0.2)",
      senary: COLOR_DARK_BLUE,
      septenary: "#0f192d",
      panel: "#0f192d",
    },
    border: {
      color: COLOR_LIGHT_BLUE,
      secondary: COLOR_CLAY,
      tertiary: COLOR_CLAY,
    },
    shadow: "none",
    font: {
      primary: COLOR_WHITE,
    },
    color: {
      primary: COLOR_WHITE,
      secondary: COLOR_WHITE,
      tertiary: COLOR_WHITE,
      quinary: COLOR_WHITE,
    },
    heading: {
      font: {
        color: COLOR_WHITE,
      },
    },
  },
  modal: {
    heading: {
      font: {
        primary: COLOR_WHITE,
        secondary: COLOR_MEDIUM_BLUE,
      },
    },
    subHeading: {
      font: {
        color: COLOR_LIGHT_GREY,
      },
    },
    bg: {
      primary: "#0c121d",
      secondary: "rgba(32, 44, 64, 0.2)",
      tertiary: COLOR_CLAY,
      quaternary: COLOR_DARK_BLUE,
    },
    border: {
      color: COLOR_CLAY,
    },
    error: {
      font: {
        color: COLOR_TRUE_RED,
      },
    },
    button: {
      font: {
        secondary: "#0A76E1",
      },
      close: {
        color: "#ff8282",
      },
    },
  },
  aside: {
    bg: {
      primary: "rgba(23, 34, 55, 0.3)",
    },
    font: {
      color: COLOR_LIGHT_CYAN,
    },
    button: {
      color: "#adadad",
      hover: "#dee2ec",
    },
    link: {
      color: "#888",
      active: COLOR_MEDIUM_BLUE,
    },
    icon: {
      primary: COLOR_LIGHT_CYAN,
    },
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
  },
  dropdown: {
    bg: {
      primary: "#0c121d",
      secondary: "#0f192d",
      tertiary: COLOR_CLAY,
      quaternary: COLOR_DARK_BLUE,
      popover: "#1f2c40",
    },
    font: {
      primary: COLOR_WHITE,
      secondary: "#dfdfdf",
    },
    hover: {
      primary: "#4a4c55",
    },
  },
  table: {
    bg: {
      primary: COLOR_CLAY,
    },
    font: {
      primary: COLOR_WHITE,
      secondary: COLOR_LIGHT_GREY,
    },
    border: {
      color: "#2b374d",
    },
    pagination: {
      bg: {
        color: "#048af5c4",
      },
    },
  },
  tooltip: {
    bg: {
      primary: COLOR_LIGHT_CYAN,
    },
    font: {
      color: "#070B12",
    },
  },
  datePicker: {
    font: {
      primary: "#999",
      secondary: "#01aef2",
    },
  },
  tab: {
    bg: {
      primary: "#2f323e",
      secondary: "rgba(165, 199, 255, 0.1)",
    },
    color: {
      primary: COLOR_WHITE,
      secondary: COLOR_LIGHT_CYAN,
      tertiary: COLOR_CLAY,
      quaternary: COLOR_CLAY,
      quinary: COLOR_CLAY,
    },
  },
  chart: {
    legend: {
      opacity: 0.3,
    },
    tooltip: {
      bg: "#0C121D",
    },
    bar: {
      primary: "#A5C7FF",
    },
    axis: {
      primary: COLOR_WHITE,
    },
  },
  image: {
    color: {
      primary: COLOR_WHITE,
      secondary: COLOR_WHITE,
      tertiary: COLOR_LIGHT_CYAN,
      quaternary: COLOR_WHITE,
      quinary: "#A5C7FF",
    },
    opacity: 0.5,
  },
  statementBox: {
    bg: {
      primary: "#0c141f",
      secondary: "rgba(165, 199, 255, 0.1)",
      tertiary: COLOR_DARK_BLUE,
      select: COLOR_DARK_BLUE,
      quaternary: COLOR_CLAY,
    },
    color: {
      primary: COLOR_WHITE,
      secondary: "#384457",
      select: COLOR_WHITE,
    },
    border: {
      primary: "rgba(255, 255, 255, 0.5)",
      secondary: COLOR_LIGHTER_GREY,
      select: COLOR_CLAY,
    },
  },
  switch: {
    bg: {
      primary: "#8c908b",
      secondary: COLOR_WHITE,
      tertiary: "#a2ff82",
      quaternary: "#26B74E",
    },
  },
  backButton: {
    bg: {
      primary: COLOR_DARK_BLUE,
    },
    font: {
      color: COLOR_MEDIUM_BLUE,
    },
  },
  divider: {
    bg: {
      primary: "#1b232b",
      secondary: "#2b374d",
    },
  },
  walkthrough: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
    },
    font: {
      primary: COLOR_WHITE,
    },
    button: {
      bg: COLOR_WHITE,
      color: {
        primary: COLOR_MEDIUM_BLUE,
        secondary: COLOR_WHITE,
        tertiary: "#C9C9C9",
      },
    },
  },
  editor: {
    bg: "vs-dark",
    border: "1px solid #4a4c55",
    tools: {
      card: {
        bg: COLOR_CLAY,
        border: COLOR_CLAY,
      },
    },
  },
  tag: {
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
    bg: {
      primary: COLOR_CLAY,
    },
  },
  nav: {
    font: {
      color: COLOR_WHITE,
    },
  },
  banner: {
    icon: {
      color: "white",
    },
    button: {
      bg: COLOR_WHITE,
      color: COLOR_MEDIUM_BLUE,
    },
    arrow: COLOR_WHITE,
  },
  menu: {
    card: {
      bg: COLOR_LIGHTER_BLUE,
      active: COLOR_CLAY,
    },
    divider: COLOR_LIGHT_WHITE,
    input: {
      bg: COLOR_CLAY,
      active: COLOR_DARK_BLUE,
    },
  },
};

export const lightTheme = {
  mode: "light",
  constants: themeConstants,
  heading: {
    color: {
      primary: COLOR_COBALT_BLUE,
      secondary: COLOR_CLAY,
    },
  },
  subHeading: {
    color: {
      primary: COLOR_CLAY,
      secondary: COLOR_WHITE,
    },
  },
  label: {
    color: {
      primary: "#0A76E1",
    },
  },
  toast: {
    bg: {
      primary: COLOR_WHITE,
      error: COLOR_TRUE_RED,
      info: COLOR_MEDIUM_BLUE,
    },
  },
  global: {
    bg: {
      primary: "#F1F7FF",
      secondary: COLOR_LIGHT_WHITE,
      tertiary: "#1f2127",
      quaternary: "#252730",
      quinary: COLOR_WHITE,
      septenary: COLOR_WHITE,
      auth: COLOR_WHITE,
    },
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
    extraInfo: {
      font: {
        color: COLOR_CLAY,
        family: PRIMARY_FONTS_FAMILY,
      },
    },
    caption: {
      font: {
        color: COLOR_MEDIUM_BLUE,
        family: PRIMARY_FONTS_FAMILY,
      },
    },
    color: {
      primary: "#101c42",
      secondary: "#000823",
      tertiary: "#048df9",
      quaternary: "#737373",
      quinary: "#0157ff",
      senary: "#0a7eff",
      septenary: "#576f9c",
      bodyColor: COLOR_CLAY,
      noData: "#3c587c",
      highlights: COLOR_MEDIUM_BLUE,
      status: "#048af5",
      profit: "#03CD17",
      arrow: COLOR_BLACK,
    },
    fonts: {
      poppins: PRIMARY_FONTS_FAMILY,
    },
    text: {
      primary: "rgba(1, 87, 255, 1)",
      secondary: COLOR_CLAY,
      tertiary: COLOR_CLAY,
      quaternary: "#0c121db3",
      active: "rgba(165, 199, 255, 0.1)",
      grey: COLOR_LIGHT_GREY,
    },
  },
  button: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: "rgba(165, 199, 255, 0.2)",
      tertiary: "rgba(165, 199, 255, 0.1)",
      quaternary: COLOR_MEDIUM_BLUE,
      quinary: COLOR_MEDIUM_BLUE,
      senary: COLOR_MEDIUM_BLUE,
      error: COLOR_TRUE_RED,
      septenary: "#5969831a",
    },
    font: {
      primary: COLOR_WHITE,
      secondary: COLOR_MEDIUM_BLUE,
      family: PRIMARY_FONTS_FAMILY,
      quaternary: COLOR_CLAY,
      quinary: COLOR_MEDIUM_BLUE,
      senary: COLOR_WHITE,
      septenary: "#048AF5",
    },
    border: {
      color: COLOR_MEDIUM_BLUE,
    },
    hover: {
      color: "#0084ed",
    },
  },
  input: {
    bg: {
      primary: "rgba(165, 199, 255, 0.1)",
      secondary: COLOR_LIGHT_GREY,
    },
    font: {
      primary: COLOR_CLAY,
      secondary: COLOR_MEDIUM_BLUE,
      tertiary: "#ddd",
      quaternary: "#1f2c40",
      family: PRIMARY_FONTS_FAMILY,
      opacity: 1,
    },
    border: {
      color: COLOR_LIGHTER_GREY,
      secondary: COLOR_CLAY,
    },
    placeholder: {
      color: COLOR_DARK_GREY,
    },
    icon: {
      color: COLOR_LIGHT_CYAN,
    },
  },
  card: {
    bg: {
      primary: COLOR_WHITE,
      secondary: "rgba(255, 255, 255, 0.8)",
      tertiary: COLOR_LIGHTER_WHITE,
      quaternary: "rgba(255, 255, 255, 0.7)",
      quinary: "rgba(165, 199, 255, 0.1)",
      senary: COLOR_MEDIUM_BLUE,
      septenary: "#f7f9ff",
      panel: COLOR_WHITE,
    },
    border: {
      color: COLOR_LIGHT_BLUE,
      secondary: "#C9C9C9",
      tertiary: "#e7e7e7",
    },
    font: {
      primary: "#3c587c",
    },
    color: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: COLOR_WHITE,
      tertiary: COLOR_CLAY,
      quinary: "#1f2c40",
    },
    shadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    heading: {
      font: {
        color: COLOR_CLAY,
      },
    },
  },
  modal: {
    heading: {
      font: {
        primary: COLOR_CLAY,
        secondary: COLOR_MEDIUM_BLUE,
        family: PRIMARY_FONTS_FAMILY,
      },
    },
    subHeading: {
      font: {
        color: COLOR_CLAY,
      },
    },
    bg: {
      primary: COLOR_WHITE,
      secondary: "rgba(32, 44, 64, 0.2)",
      tertiary: COLOR_WHITE,
      quaternary: "rgba(255, 255, 255, 0.7)",
    },
    border: {
      color: COLOR_WHITE,
    },
    error: {
      font: {
        color: COLOR_TRUE_RED,
      },
    },
    button: {
      font: {
        secondary: "#0A76E1",
      },
      close: {
        color: "#ff8282",
      },
    },
  },
  aside: {
    bg: {
      primary: COLOR_WHITE,
    },
    font: {
      color: COLOR_MEDIUM_BLUE,
    },
    button: {
      color: "#adadad",
      hover: "#dee2ec",
    },
    link: {
      color: "#888",
      active: COLOR_MEDIUM_BLUE,
    },
    icon: {
      primary: COLOR_MEDIUM_BLUE,
    },
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
  },
  dropdown: {
    bg: {
      primary: COLOR_WHITE,
      secondary: COLOR_LIGHTER_WHITE,
      tertiary: COLOR_MEDIUM_BLUE,
      quaternary: COLOR_MEDIUM_BLUE,
      popover: COLOR_WHITE,
    },
    font: {
      primary: COLOR_CLAY,
      secondary: COLOR_CLAY,
    },
    hover: {
      primary: COLOR_LIGHT_GREY,
    },
  },
  table: {
    bg: {
      primary: COLOR_LIGHT_GREY,
    },
    font: {
      primary: COLOR_CLAY,
      secondary: COLOR_LIGHT_GREY,
    },
    border: {
      color: "#2b374d",
    },
    pagination: {
      bg: {
        color: "#048af5c4",
      },
    },
  },
  tooltip: {
    bg: {
      primary: COLOR_LIGHT_CYAN,
    },
    font: {
      color: "#070B12",
    },
  },
  datePicker: {
    font: {
      primary: "#999",
      secondary: "#01aef2",
    },
  },
  tab: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: "rgba(165, 199, 255, 0.17)",
    },
    color: {
      primary: COLOR_WHITE,
      secondary: COLOR_MEDIUM_BLUE,
      tertiary: COLOR_MEDIUM_BLUE,
      quaternary: COLOR_WHITE,
      quinary: COLOR_MEDIUM_BLUE,
    },
  },
  chart: {
    legend: {
      opacity: 1,
    },
    tooltip: {
      bg: COLOR_WHITE,
    },
    bar: {
      primary: "#A5C7FF",
    },
    axis: {
      primary: COLOR_CLAY,
    },
  },
  image: {
    color: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: COLOR_WHITE,
      tertiary: COLOR_MEDIUM_BLUE,
      quaternary: COLOR_CLAY,
      quinary: COLOR_MEDIUM_BLUE,
    },
    opacity: 1,
  },
  statementBox: {
    bg: {
      primary: COLOR_WHITE,
      secondary: "rgba(165, 199, 255, 0.1)",
      tertiary: "rgba(165, 199, 255, 0.1)",
      select: "rgba(165, 199, 255, 0.1)",
      quaternary: COLOR_WHITE,
    },
    color: {
      primary: COLOR_MEDIUM_BLUE,
      secondary: COLOR_MEDIUM_BLUE,
      select: COLOR_CLAY,
    },
    border: {
      primary: COLOR_MEDIUM_BLUE,
      select: COLOR_LIGHTER_GREY,
    },
  },
  switch: {
    bg: {
      primary: "#C9C9C9",
      secondary: COLOR_WHITE,
      tertiary: COLOR_MEDIUM_BLUE,
      quaternary: COLOR_WHITE,
    },
  },
  backButton: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
    },
    font: {
      color: COLOR_WHITE,
    },
  },
  divider: {
    bg: {
      primary: "#dfdfdf",
      secondary: "#dfdfdf",
    },
  },
  walkthrough: {
    bg: {
      primary: COLOR_MEDIUM_BLUE,
    },
    font: {
      primary: COLOR_WHITE,
    },
    button: {
      bg: COLOR_WHITE,
      color: {
        primary: COLOR_MEDIUM_BLUE,
        secondary: COLOR_WHITE,
        tertiary: "#C9C9C9",
      },
    },
  },
  editor: {
    bg: "vs-light",
    border: "none",
    tools: {
      card: {
        bg: COLOR_ALICE_BLUE,
        border: COLOR_LIGHTER_GREY,
      },
    },
  },
  tag: {
    border: {
      primary: COLOR_LIGHT_BLUE,
      secondary: "#979797",
    },
    bg: {
      primary: COLOR_LIGHT_GREY,
    },
  },
  nav: {
    font: {
      color: COLOR_MEDIUM_BLUE,
    },
  },
  banner: {
    icon: {
      color: "white",
    },
    button: {
      bg: COLOR_WHITE,
      color: COLOR_MEDIUM_BLUE,
    },
    arrow: COLOR_WHITE,
  },
  menu: {
    card: {
      bg: COLOR_WHITE,
      active: COLOR_LIGHTER_WHITE,
    },
    divider: "#C9C9C9",
    input: {
      bg: COLOR_LIGHTER_GREY,
      active: COLOR_WHITE,
    },
  },
};
