import { createAsyncThunk } from "@reduxjs/toolkit";

import { isLoggedIn } from "utils";

export const updateIsLoggedIn = createAsyncThunk(
  "auth/isLoggedIn",
  async () => {
    return isLoggedIn();
  }
);
