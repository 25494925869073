import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { toast } from "Layout/slice";
import { Avatar } from "components/Avatar";
import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { PAGE_SIZE } from "utils/constants";
import { getNCBTargetAssets } from "features/Strategy/service";
import { makeQueryParams, parseErrorMessage, debounce } from "utils";
import { AddTargetAssetModalContainer } from "./AddTargetAssetModal.styles";
import { updateSelectedTargetAssets } from "features/Strategy/NoCodeBuilder/slice";
import { selectedTargetAssetsSelector } from "features/Strategy/NoCodeBuilder/slice/selector";

export const AddTargetAssetModal = (props) => {
  const dispatch = useDispatch();
  const selectedTargetAssets = useSelector(selectedTargetAssetsSelector);

  const [assets, setAssets] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAssets, setSelectedAssets] = useState(selectedTargetAssets);

  // const premadeScreeners = ["Popular", "Small Caps", "Popular", "Small Caps"];

  useEffect(() => {
    getTargetAssets();
  }, []);

  const getTargetAssets = async (
    page = 1,
    search = searchValue,
    clearRecords = false
  ) => {
    try {
      setLoading(true);
      const qs = makeQueryParams({
        size: PAGE_SIZE,
        page,
        search: search,
      });
      const response = await getNCBTargetAssets(qs);

      const allAssets = response.data;
      selectedAssets.map((asset) => {
        allAssets.map((allAsset) => {
          if (asset.asset === allAsset.asset) {
            allAsset.isSelected = true;
          }
          return allAsset;
        });
        return asset;
      });

      if (clearRecords) {
        setAssets(allAssets);
      } else {
        setAssets([...assets, ...allAssets]);
      }

      setTotal(response.total_count);
      setActivePage(page);
      setTotalPages(response.total_pages);
    } catch (error) {
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMoreClick = () => {
    const page = activePage + 1;
    if (page <= totalPages) {
      getTargetAssets(page);
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    getTargetAssets(1, value, true);
  };

  const debouncedSearch = useMemo(
    () => debounce(handleSearchChange, 500),
    [searchValue]
  );

  const handleCheckboxChange = (e, asset) => {
    const { checked } = e.target;
    const selectedInx = selectedAssets.findIndex(
      (selectedAsset) => selectedAsset.asset === asset.asset
    );

    if (checked) {
      setSelectedAssets([
        ...selectedAssets,
        { ...asset, title: asset.asset, key: asset.asset, price: asset.price },
      ]);
    } else {
      let updatedSelected = [...selectedAssets];
      updatedSelected.splice(selectedInx, 1);
      setSelectedAssets(updatedSelected);
    }
  };

  const handleAddClick = () => {
    dispatch(updateSelectedTargetAssets(selectedAssets));
    props.onHide();
  };

  return (
    <AddTargetAssetModalContainer
      heading="Add Target Assets"
      headingClass="add-asset-modal-heading"
      {...props}
    >
      <div className="asset-search">
        <div className="search-title">Asset Search</div>
        <div className="d-flex position-relative">
          <input
            className="search-input"
            placeholder="Search asset..."
            onChange={debouncedSearch}
          />
        </div>
      </div>

      {/* <div className="premade-screeners">
        <Row>
          {premadeScreeners.map((screener, idx) => {
            return (
              <Col md={3} key={idx}>
                <div className="screener">
                  <span>{screener}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
 */}
      {isLoading && (
        <div className="mt-3">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="found-assets">
          {/* <img
          className="arrow-back"
          src={iconLeftArrow}
          alt="icon"
          onClick={() => setSelectedIndex(null)}
        /> */}
          {/* <div className="selected-screener mt-3">
          <div>{premadeScreeners[selectIndex]}</div>
        </div> */}

          <div className="heading mt-3">Found {total} Assets</div>
          <div className="asset-list">
            {assets.map((asset, idx) => {
              return (
                <div className="asset-item" key={idx}>
                  <div className="asset-item-left">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      value=""
                      defaultChecked={asset.isSelected}
                      onChange={(e) => handleCheckboxChange(e, asset)}
                    />
                    <Avatar
                      src={asset.image}
                      name={asset?.name}
                      round={true}
                      size={30}
                      className="asset-img"
                    />
                    <div className="asset-title text-elipses">
                      {asset.asset}
                      <div className="mobile-asset-name text-elipses">
                        {asset?.name}
                      </div>
                    </div>
                  </div>
                  <div className="asset-subscript text-elipses">
                    {asset?.name}
                  </div>
                  <div className="asset-value">${asset.price || 0}</div>
                  {/* <img
                    className="arrow-right"
                    src={iconArrowRight}
                    alt="icon"
                  /> */}
                </div>
              );
            })}
          </div>
          {activePage < totalPages && (
            <div className="link-more" onClick={handleLoadMoreClick}>
              Load More
            </div>
          )}
          <Button
            className="add-now-btn"
            label={"Add"}
            onClick={handleAddClick}
          ></Button>
        </div>
      )}
    </AddTargetAssetModalContainer>
  );
};

AddTargetAssetModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

AddTargetAssetModal.defaultProps = {
  show: true,
};

AddTargetAssetModal.displayName = "CreateStrategyModal";
