import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  isEmptyObj,
  parseErrorMessage,
  roundOff,
  sleep,
  makeLabel,
} from "utils";
import {
  initialValues,
  validationSchema,
} from "shared/Modal/HostStrategyModal/validation";
import { Button } from "components/Button";
import { Input } from "components/Form";
import { Loader } from "components/Loader";
import { useNavigation } from "utils/navigate";
// import { ConfirmHostIcon } from "assets/images/host";
// import { ConfirmationAlert } from "shared/Alert";
import { getStrategyDetails, hostStrategy } from "features/Strategy/service";
import { ProgressModal } from "shared/Modal/HostStrategyModal/ProgressModal";
import { HostFormContainer } from "./Host.styles";
import { BackButton } from "style/components/BackButton.styles";
import { iconClose } from "assets/images";
import { toast } from "Layout/slice";

const HostKeyStats = ({ stats }) => {
  return (
    <div className="host-key-statistics-container">
      <h3>Key Statistics</h3>
      {stats === null || isEmptyObj(stats) ? (
        <h4 className="no-stats">No Key Statistics found</h4>
      ) : (
        <ul>
          {Object.keys(stats).map((key) => {
            const formattedKey = makeLabel(key);
            return (
              <li key={key}>
                <span className="key">{formattedKey}</span>
                <span className="stats">{roundOff(stats[key])}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export const HostStrategyForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [strategyDetail, setStrategyDetail] = useState({});
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState(1);
  const { navigateBack, navigateToHostStrategyProgress } = useNavigation();
  const strategyId = strategyDetail.strategy_id;
  const toggleProgressModal = () => setShowProgressModal(!showProgressModal);

  const handleSubmit = async (values) => {
    toggleProgressModal();
    await sleep({ timeInms: 1000 });
    setShowProgressModal(false);
    // const result = await ConfirmationAlert({
    //   title: "Host your strategy?",
    //   icon: ConfirmHostIcon,
    //   html: "<p class='host-desc'>This will allow other traders to subscribe</p>",
    // });
    // if (result.isConfirmed) {
    toggleProgressModal();
    setProgress(99);
    await sleep({ timeInms: 1000 });
    setShowProgressModal(false);
    onHostStrategy(values);
    // }
  };

  const formik = useFormik({
    initialValues: initialValues(strategyDetail),
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const onHostStrategy = async (values) => {
    try {
      formik.setSubmitting(true);
      await hostStrategy(id, values);
      setProgress(1);
      formik.setSubmitting(false);
      navigateToHostStrategyProgress(strategyId);
    } catch (error) {
      setProgress(1);
      formik.setSubmitting(false);
      dispatch(
        toast({
          type: "error",
          body: parseErrorMessage(error),
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getStrategyDetails(id);
        setStrategyDetail(data);
      } catch (error) {
        dispatch(
          toast({
            type: "error",
            body: parseErrorMessage(error),
          })
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <HostFormContainer>
      <BackButton
        label="< Back"
        width={60}
        onClick={navigateBack}
        className="back-btn"
      />
      <div className="creation-form">
        <img src={iconClose} className="btn-close" onClick={navigateBack} />
        <div className="body-heading">Strategy Host</div>
        <Form onSubmit={formik.handleSubmit} className="form host-form">
          <Input
            placeholder="Enter Name"
            label="Name"
            className="host-input"
            {...formik.getFieldProps("name")}
          />
          {formik.errors.name && (
            <div className="form-error">{formik.errors.name}</div>
          )}
          <Input
            placeholder="Enter Description"
            label="Description"
            className="host-input desc-input"
            type="textarea"
            rows={4}
            as="textarea"
            {...formik.getFieldProps("description")}
          />
          {formik.errors.description && (
            <div className="form-error">{formik.errors.description}</div>
          )}

          {isLoading ? (
            <Loader />
          ) : (
            <HostKeyStats stats={strategyDetail.key_stats} />
          )}

          <div className="price-input-group">
            <Form.Label className="price-input-label">
              Price per month
            </Form.Label>
            <InputGroup className="price-group-wrapper">
              <Form.Control
                placeholder="$ 0.0"
                className="host-input price-input"
                {...formik.getFieldProps("price")}
              />
            </InputGroup>
          </div>
          {formik.errors.price && (
            <div className="form-error price-error">{formik.errors.price}</div>
          )}

          <Button
            disabled={formik.isSubmitting}
            type="submit"
            label="Submit"
            className="submit-btn"
          >
            {formik.isSubmitting ? <Loader size="sm" /> : "Submit"}
          </Button>
        </Form>

        {showProgressModal && (
          <ProgressModal
            show={showProgressModal}
            onHide={toggleProgressModal}
            progress={progress}
          />
        )}
      </div>
    </HostFormContainer>
  );
};

HostKeyStats.propTypes = {
  stats: PropTypes.object,
};
HostKeyStats.defaultProps = {
  stats: {},
};
