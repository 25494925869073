import PropTypes from "prop-types";
import { ButtonContainer } from "./Button.styles";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variant,
  size,
  label,
  width,
  className,
  children,
  ...props
}) => {
  return (
    <ButtonContainer
      type="button"
      className={[`button-${size}`, `button-${variant}`, className].join(" ")}
      style={{ width }}
      {...props}
    >
      {children ? children : label}
    </ButtonContainer>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf(["primary", "secondary"]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  width: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: "primary",
  size: "medium",
  onClick: undefined,
  className: "",
};

Button.displayName = "Button";
