import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  HOME_PATH,
  PORTFOLIO_DETAIL_PATH,
  PORTFOLIO_PATH,
} from "features/Portfolio/routes";
import {
  SIGNIN_PATH,
  SIGNUP_PATH,
  FORGOT_PASS_PATH,
  CHECK_EMAIL_PATH,
  SIGNUP_EMAIL_PATH,
  VERIFY_PHONE_PATH,
} from "features/Auth/routes";
import {
  MARKETPLACE_PATH,
  MARKETPLACE_STRATEGY_DETAIL_PATH,
} from "features/Marketplace/routes";
import {
  STRATEGY_DETAIL_PATH,
  STRATEGY_EDIT_PATH,
  STRATEGY_PATH,
  STRATEGY_HOST_PATH,
  STRATEGY_HOST_PROGRESS_PATH,
  STRATEGY_HOST_FORM_PATH,
} from "features/Strategy/routes";
import {
  PAYMENT_PLAN_PATH,
  PAYMENT_PLAN_DETAIL_PATH,
} from "features/Account/PurchasePlan/routes";
import {
  ACCOUNT_NOTIFICATION_PATH,
  ACCOUNT_PAYMENT_PATH,
  ACCOUNT_PLAN_BILLING_PATH,
  ACCOUNT_TWO_FACTOR_AUTHENTICATION_PATH,
  ACCOUNT_PLAN_DETAIL_PATH,
  ACCOUNT_OVERVIEW_PATH,
  ACCOUNT_SECURITY_PATH,
} from "features/Account/routes";
import { isChildRoute } from "utils";
import { trackPage } from "./tracking";
import { setCurrentPath } from "Layout/slice";
import { PLANS_PATH } from "features/Plans/routes";
import { currentPathSelect } from "Layout/slice/selector";
import { HELP_CENTER_PATH } from "features/HelpCenter/routes";
import { NOTIFICATION_PATH } from "features/Notification/routes";
import { WELCOME_PATH, WELCOME_CONNECTED_PATH } from "features/Auth/routes";

export function useNavigation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = useSelector(currentPathSelect);

  const navigateWithTracking = (path, options) => {
    trackPage({ page: path });

    if (typeof path === "string" && !isChildRoute(path)) {
      dispatch(setCurrentPath(path));
    }
    if (options) navigate(path, options);
    else navigate(path);
  };

  // Marketplace
  const navigateToMarketplace = () => {
    navigateWithTracking(MARKETPLACE_PATH);
  };

  const navigateToMarketplaceDetail = (id) => {
    navigateWithTracking(MARKETPLACE_STRATEGY_DETAIL_PATH.replace(":id", id));
  };

  // Portfolio
  const navigateToPortfolio = () => {
    navigateWithTracking(PORTFOLIO_PATH);
  };

  const navigateToPortfolioDetail = (id, options) => {
    navigateWithTracking(PORTFOLIO_DETAIL_PATH.replace(":id", id), options);
  };

  // Strategy
  const navigateToStrategyList = (param) => {
    if (param) {
      navigateWithTracking(`${STRATEGY_PATH}?${param}`);
    } else {
      navigateWithTracking(STRATEGY_PATH);
    }
  };

  const navigateToStrategyDetail = (id) => {
    navigateWithTracking(STRATEGY_DETAIL_PATH.replace(":id", id));
  };

  const navigateToStrategyEdit = (id) => {
    navigateWithTracking(STRATEGY_EDIT_PATH.replace(":id", id));
  };

  const navigateToNoCodeBuilder = (options) => {
    navigateWithTracking(options);
  };

  const navigateToNoCodeBuilderEdit = (options) => {
    navigateWithTracking(options);
  };

  // Payment
  const navigateToPaymentPlan = () => {
    navigateWithTracking(PAYMENT_PLAN_PATH);
  };

  const navigateToPaymentPlanDetail = (id, options) => {
    navigateWithTracking(PAYMENT_PLAN_DETAIL_PATH.replace(":id", id), options);
  };

  const navigateToAccountPaymentPlanDetail = (id, options) => {
    navigateWithTracking(ACCOUNT_PLAN_DETAIL_PATH.replace(":id", id), options);
  };

  // Auth
  const navigateToSignin = () => {
    navigateWithTracking(SIGNIN_PATH);
  };

  const navigateToSignup = () => {
    navigateWithTracking(SIGNUP_PATH);
  };

  const navigateToSignupEmail = () => {
    navigateWithTracking(SIGNUP_EMAIL_PATH);
  };

  const navigateToVerifyPhone = () => {
    navigateWithTracking(VERIFY_PHONE_PATH);
  };

  const navigateToWelcome = () => {
    navigateWithTracking(WELCOME_PATH);
  };

  const navigateToForgotPassword = () => {
    navigateWithTracking(FORGOT_PASS_PATH);
  };

  const navigateToCheckEmail = (options) => {
    navigateWithTracking(CHECK_EMAIL_PATH, options);
  };

  const navigateToNewBrokerConnect = () => {
    navigateWithTracking(WELCOME_CONNECTED_PATH);
  };

  // Profile
  const navigateToAccountOverview = () => {
    navigateWithTracking(ACCOUNT_OVERVIEW_PATH);
  };

  const navigateToAccountSecurity = () => {
    navigateWithTracking(ACCOUNT_SECURITY_PATH);
  };

  const navigateToAccountTwoFactorAuthentication = () => {
    navigateWithTracking(ACCOUNT_TWO_FACTOR_AUTHENTICATION_PATH);
  };

  const navigateToProfileNotification = () => {
    navigateWithTracking(ACCOUNT_NOTIFICATION_PATH);
  };

  const navigateToReceipts = () => {
    navigateWithTracking(ACCOUNT_PLAN_BILLING_PATH);
  };

  const navigateToPlans = () => {
    navigateWithTracking(PLANS_PATH);
  };

  const navigateToProfilePayment = () => {
    navigateWithTracking(ACCOUNT_PAYMENT_PATH);
  };

  // Common
  const navigateTo = (path, options) => {
    navigateWithTracking(path, options);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const navigateBackToParent = () => {
    navigate(currentPath);
  };

  const navigateToHome = () => {
    navigateWithTracking(HOME_PATH);
  };

  const navigateToHostStrategy = (id) => {
    navigateWithTracking(STRATEGY_HOST_PATH.replace(":id", id));
  };

  const navigateToHostStrategyProgress = (strategyId) => {
    navigateWithTracking(
      STRATEGY_HOST_PROGRESS_PATH.replace(":id", strategyId)
    );
  };

  const navigateToHostStrategyForm = (strategyId) => {
    navigateWithTracking(STRATEGY_HOST_FORM_PATH.replace(":id", strategyId));
  };

  // Notification
  const navigateToNotification = () => {
    navigateWithTracking(NOTIFICATION_PATH);
  };

  // Help Center
  const navigateToHelpCenter = (options) => {
    navigateWithTracking(options || HELP_CENTER_PATH);
  };

  return {
    navigateTo,
    navigateBack,
    navigateBackToParent,
    navigateToHome,
    navigateToSignin,
    navigateToSignup,
    navigateToMarketplace,
    navigateToForgotPassword,
    navigateToPortfolio,
    navigateToStrategyList,
    navigateToCheckEmail,
    navigateToStrategyDetail,
    navigateToPaymentPlan,
    navigateToPortfolioDetail,
    navigateToPaymentPlanDetail,
    navigateToMarketplaceDetail,
    navigateToStrategyEdit,
    navigateToHostStrategy,
    navigateToHostStrategyProgress,
    navigateToNoCodeBuilder,
    navigateToNoCodeBuilderEdit,
    navigateToHostStrategyForm,
    navigateToNotification,
    navigateToHelpCenter,
    navigateToSignupEmail,
    navigateToVerifyPhone,
    navigateToWelcome,
    navigateToNewBrokerConnect,
    navigateToProfileNotification,
    navigateToReceipts,
    navigateToPlans,
    navigateToProfilePayment,
    navigateToAccountTwoFactorAuthentication,
    navigateToAccountOverview,
    navigateToAccountPaymentPlanDetail,
    navigateToAccountSecurity,
  };
}
