import styled from "styled-components";

export const TwoFactorAuthenticationContainer = styled.section`
  h2 {
    margin-bottom: 5px;
  }

  .switch-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 25px;

    .secondary {
      margin-left: 100px;
      label {
        width: 46px;
        height: 26px;
      }

      .slider {
        background-color: ${({ theme }) => theme.switch.bg.primary} !important;

        &:before {
          background-color: ${({ theme }) => theme.switch.bg.secondary};
          height: 20px;
          width: 20px;
          left: 3px;
          top: 3px;
          bottom: 0px;
        }
      }

      input:checked + .slider {
        background-color: ${({ theme }) => theme.button.bg.primary} !important;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    }
  }
`;
