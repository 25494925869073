import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Popover, ListGroup, OverlayTrigger } from "react-bootstrap";
import { useTheme } from "styled-components";

import { toast } from "Layout/slice";
import NotificationCard from "./Card";
import { makeQueryParams } from "utils";
import { socket } from "services/socket";
import { iconClose } from "assets/images";
import { useNavigation } from "utils/navigate";
import { NotificationIcon } from "assets/icons/navbar";
import { clearNotifications, getAllNotifications } from "./service";
import { NotificationPopover, NotificationCount } from "./Notification.styles";
import { Button } from "components/Button";

const NotificationSidebar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { navigateToNotification } = useNavigation();

  const [total, setTotal] = useState(0);
  const [notifCount, setNotifCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const [showViewAll, setShowViewAll] = useState(false);
  const [seenNotificationId, setSeenNotificationId] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (socket && socket?.connected) {
      socket.on("notification_count", listenNotificationCount);
    }

    return () => {
      socket.off("notification_count", listenNotificationCount);
    };
  }, [socket, socket?.connected]);

  const listenNotificationCount = (data) => {
    const count = data?.unread_notification || 0;
    setNotifCount(count);
  };

  const handleClearNotifications = async () => {
    try {
      const ids = notifications.map((notification) => notification.id);
      await clearNotifications({
        notifications: ids,
      });
      getNotifications(1, true);
      dispatch(
        toast({
          body: "All notifications have been marked as read",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getNotifications = async (page = 1, reset = false) => {
    try {
      const qs = makeQueryParams({
        page,
        size: 10,
      });
      const response = await getAllNotifications(qs);
      setTotal(response.total_count);
      setUnseenCount(response.unseen.length);
      if (reset) {
        setNotifications(response.data);
      } else {
        setNotifications([...notifications, ...response.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllNotificationClick = () => {
    navigateToNotification();
    onHide();
  };

  const handleSeeMoreClick = () => {
    setShowViewAll(true);
    getNotifications(2);
  };

  const handleNotificationClick = (id) => {
    setSeenNotificationId([...seenNotificationId, id]);
  };

  const onHide = () => {
    document.body.click();
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <NotificationPopover id="popover-positioned-bottom">
          <Popover.Body className="body-wrapper">
            <Popover.Header as="h3">
              Notifications
              <img src={iconClose} onClick={onHide} />
            </Popover.Header>

            <div className="mark-as-read">
              {unseenCount > 0 && (
                <Button
                  variant="secondary"
                  size="large"
                  onClick={handleClearNotifications}
                >
                  Mark all as read
                </Button>
              )}
            </div>

            <ListGroup>
              {notifications.map((notification) => (
                <ListGroup.Item
                  key={notification.id}
                  className={classNames({
                    seen:
                      notification.seen ||
                      seenNotificationId.includes(notification.id),
                  })}
                >
                  <NotificationCard
                    {...notification}
                    showEnvelope={false}
                    handleClick={handleNotificationClick}
                    handleClearClick={() => getNotifications(1, true)}
                  />
                </ListGroup.Item>
              ))}
              {notifications.length === 0 && (
                <ListGroup.Item className="no-data">{`You're all caught up`}</ListGroup.Item>
              )}
            </ListGroup>
            <div className="actions">
              {total > 10 && !showViewAll && (
                <Button size="small" onClick={handleSeeMoreClick}>
                  See more
                </Button>
              )}

              {showViewAll && (
                <Button onClick={handleAllNotificationClick}>
                  View all notifications
                </Button>
              )}
            </div>
          </Popover.Body>
        </NotificationPopover>
      }
    >
      <NotificationCount>
        <NotificationIcon
          alt="notification"
          stroke={theme.image.color.tertiary}
          className="icon-notification"
        />
        {notifCount > 0 && <span className="count">{notifCount}</span>}
      </NotificationCount>
    </OverlayTrigger>
  );
};

export default NotificationSidebar;
