import styled from "styled-components";

import { iconChecklist } from "assets/icons/payment";

export const StrategyPaymentContainer = styled.div`
  padding: 20px 0;
  padding-bottom: 4em;

  .header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: ${({ theme }) => theme.card.bg.secondary};
    border: 2px solid rgba(165, 199, 255, 0.03);
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    margin: 10px 0;

    h2 {
      margin-bottom: 0;
    }
  }

  .payment-method-section {
    margin: 20px auto;

    .card-wrapper {
      display: flex;
      justify-content: center;

      .card {
        width: 350px;
      }
    }

    .disclaimer {
      font-weight: ${({ theme }) => theme.constants.FONT_WEIGHT_400};
      font-size: ${({ theme }) => theme.constants.FONT_SIZE_12};
      color: ${({ theme }) => theme.global.extraInfo.font.color};
      text-align: center;
      margin-top: 3rem;
    }

    .payment-method-details {
      background-color: ${({ theme }) => theme.card.bg.secondary};
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      padding: 10px 3rem;
      width: 85%;

      .paynow-btn {
        display: block;
        width: 80%;
        margin: 12px auto;
        height: 40px;
        padding: 0;
      }

      .detail-card {
        .price-card {
          background: ${({ theme }) => theme.card.bg.tertiary};
          border-radius: 19px;
          padding: 2px 10px;
          width: 190px;
          margin: 0 auto;
          margin-bottom: 22px;
          h4 {
            margin: 10px 0px;
            font-weight: 700;
            font-size: 35px;
            color: ${({ theme }) => theme.global.color.senary};
            text-align: center;
            span {
              font-weight: 500;
              font-size: 12px;
              color: ${({ theme }) => theme.global.extraInfo.font.color};
            }
          }
        }

        h2 {
          text-align: center;
          margin-bottom: 10px;
        }

        .plan-features {
          margin-top: 1rem;

          .title {
            font-size: 18px;
            text-align: start;
          }

          ul {
            padding-left: 0;

            li {
              background: url("${iconChecklist}") no-repeat 7px 7px transparent;
              padding: 6px 0px 6px 35px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .payment-method-section .card-wrapper {
      margin-bottom: 30px;
      .card {
        width: 100%;
      }
    }
  }
`;

export const NewStrategyPaymentContainer = styled.div`
  padding-bottom: 4em;

  .card-wrapper {
    background-color: ${({ theme }) => theme.card.bg.quinary};
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    padding: 10px 3rem;
    width: 600px;
    margin: 0 auto 20px;
  }

  @media (max-width: 768px) {
    .card-wrapper {
      width: 100%;
    }
  }
`;

export const SavedCardContainer = styled.div`
  padding: 12px 0;
  .wrapper {
    margin-top: 1rem;

    .no-card {
      .link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    button {
      display: flex;
      justify-content: center;
      width: 100px;

      .on-btn {
        margin-right: 5px;
      }
    }
  }
`;

export const NewCardContainer = styled.div`
  padding: 12px 0;

  .wrapper {
    margin-top: 1rem;

    .card-container {
      padding: 5px 10px 5px 10px;
      background: ${({ theme }) => theme.input.bg.primary};
      border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      border: 1px solid ${({ theme }) => theme.input.border.color};
      color: ${({ theme }) => theme.input.font.primary};
      width: 100%;
    }

    .add-button {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        display: flex;
        justify-content: center;
        width: 250px;

        .on-btn {
          margin-right: 13px;
        }
      }
    }
  }
`;

export const StripeCard = (theme) => ({
  base: {
    fontSize: "1rem",
    iconColor: theme.global.extraInfo.font.color,
    backgroundColor: theme.input.bg.primary,
    color: theme.input.font.primary,
    lineHeight: "32px",
    "::placeholder": {
      color: theme.input.placeholder.color,
    },
  },
  invalid: {
    iconColor: theme.toast.bg.error.primary,
    color: theme.toast.bg.error.primary,
  },
  complete: {
    iconColor: "#cbf4c9",
  },
});
