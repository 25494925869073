import styled from "styled-components";

export const ProfileContainer = styled.div`
  min-height: 400px;

  .account-information {
    padding: 0;

    h2 {
      margin-bottom: 5px;
    }

    .form-error {
      margin-top: -10px;
      margin-bottom: 10px;
    }

    .button-wrapper {
      float: right;

      button {
        width: 130px;
        padding: 10px 0px !important;
        height: 100% !important;
        margin-left: 0.5em;
      }
    }

    .input-form {
      .input-form-field {
        margin-bottom: 15px;
      }
      .input-form-field::placeholder,
      .input-form-field-description::placeholder {
        color: ${({ theme }) => theme.input.placeholder.color};
      }
      .input-form-field-description {
        border-radius: 10px;
      }
    }

    .view-profile {
      .label {
        margin-bottom: 1.25rem;
      }

      .email-wrapper,
      .phone-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .value,
        .input-form .input-form-field {
          margin-bottom: 0;
        }

        .tooltiptext {
          width: 200px;

          .link {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .email {
          margin-right: 14px;
        }

        .edit-icon {
          cursor: pointer;
        }
      }

      .email-wrapper img {
        height: 20px;
      }

      .phone-wrapper .app-tooltip img {
        height: 28px;
      }
      .value {
        font-weight: 500;
        margin-bottom: 1rem;

        &.email {
          word-break: break-all;
        }
      }
    }

    .subscribe-plan {
      text-align: center;
      margin-top: 12px;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .close-account .link {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    .account-information .button-wrapper {
      float: initial;
      text-align: center;
    }
  }
`;
