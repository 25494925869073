import { createSlice } from "@reduxjs/toolkit";
import {
  getStrategyFilesCases,
  postStrategyRepoCases,
  editorReducers,
  getStrategyDefaultFileCases,
} from "./reducer";

const initialState = {
  data: {},
  defaultFile: {},
  flags: {
    selectedFileIndex: null,
    activeIndex: null,
  },
  status: {
    getStrategyFiles: "idle",
    createStrategyRepo: "idle",
    getStrategyDefaultFile: "idle",
  },
  error: {
    getStrategyFiles: null,
    createStrategyRepo: null,
    getStrategyDefaultFile: null,
  },
  repoDetail: {
    name: "",
    fileType: "",
    toggleNameModal: false,
  },
  isDeployed: 0,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: editorReducers,
  extraReducers: (builder) => {
    getStrategyFilesCases(builder);
    postStrategyRepoCases(builder);
    getStrategyDefaultFileCases(builder);
  },
});

export const {
  addCodeToFile,
  changeSelectedFileIndex,
  changeActiveIndex,
  showFileTab,
  addCustomFile,
  hideFileTab,
  updateFile,
  addFile,
  deleteFileAtIndex,
  updateRepoData,
  updateToggleName,
  clearState,
  updateFolderName,
  updateDefaultFile,
  expandFolderTab,
  collapseFolderTab,
  deleteFolderFromList,
  updateDeployedStrategyStatus,
} = editorSlice.actions;

export default editorSlice.reducer;
