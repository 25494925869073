import { configureStore } from "@reduxjs/toolkit";

import layoutReducer from "Layout/slice";
import authReducer from "features/Auth/slice";
import strategyReducer from "features/Strategy/slice";
import portfolioReducer from "features/Portfolio/slice";
import editorReducer from "features/Strategy/CodeBuilder/slice";
import noCodeBuilderReducer from "features/Strategy/NoCodeBuilder/slice";

export const store = configureStore({
  reducer: {
    strategy: strategyReducer,
    portfolio: portfolioReducer,
    auth: authReducer,
    editor: editorReducer,
    layout: layoutReducer,
    noCodeBuilder: noCodeBuilderReducer,
  },
});
