import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ManualTradingFloatingButtonContainer } from "./ManualTradingFloatingButton.styles";
import { Button } from "components/Button";
import { Draggable } from "components/Draggable";
import { ManualTradingPanel } from "shared/ManualTradingPanel";

const WHITE_LIST = ["/", "/portfolio", /^\/portfolio\/[a-f0-9-]+\/detail$/];

const ManualTradingFloatingButton = () => {
  const { pathname } = useLocation();

  const [visible, setVisible] = useState(false);
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    let match = WHITE_LIST.some((url) => {
      if (typeof url === "string") {
        return url === pathname;
      } else if (url instanceof RegExp) {
        return url.test(pathname);
      }
      return false;
    });

    setVisible(match);
    setShowPanel(false);
  }, [pathname]);

  const handleButtonClick = () => {
    setShowPanel(true);
  };

  const handleClosePanel = () => {
    setShowPanel(false);
  };

  return (
    visible && (
      <>
        <ManualTradingFloatingButtonContainer>
          {!showPanel && (
            <Button onClick={handleButtonClick}>Manual Trading</Button>
          )}
        </ManualTradingFloatingButtonContainer>

        {showPanel && (
          <Draggable
            initialPos={{ x: "auto", y: "auto", right: 0, bottom: "50px" }}
            style={{
              cursor: "move",
              zIndex: 9,
            }}
          >
            <ManualTradingPanel onHide={handleClosePanel} />
          </Draggable>
        )}
      </>
    )
  );
};

export default ManualTradingFloatingButton;
