import { lazy } from "react";

const PaymentPlan = lazy(() =>
  import("features/Account/PurchasePlan/AvailablePlan")
);
const PaymentDetail = lazy(() =>
  import("features/Account/PurchasePlan/Detail")
);

export const PAYMENT_PLAN_PATH = "/payment/plan";
export const PAYMENT_PLAN_DETAIL_PATH = "/payment/plan/:id/detail";

export default [
  {
    path: PAYMENT_PLAN_PATH,
    component: <PaymentPlan />,
  },
  {
    path: PAYMENT_PLAN_DETAIL_PATH,
    component: <PaymentDetail />,
  },
];
