import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "./namespaces";
import PrivateApp from "./private";

// Custom hooks
import ScrollToTop from "hooks/scrollTop";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Suspense>
        <Routes>
          {/* Private Routes */}
          <Route path="*" element={<PrivateApp />} />

          {/* Public Routes */}
          {publicRoutes.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
