import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { toast } from "Layout/slice";
import { numberSeparator, openNewTab, roundOff } from "utils";
import { unixToDateTime, MONTH_DATE_YEAR_FORMAT } from "utils/date";
import { BillingMobileCardContainer } from "./HistoryMobileCard.styles";

const HistoryMobileCard = ({ subscription }) => {
  const dispatch = useDispatch();

  const handleOpenInvoice = () => {
    if (
      !subscription.hosted_invoice_url ||
      subscription.hosted_invoice_url === "No invoice"
    ) {
      dispatch(
        toast({
          type: "error",
          body: "No invoice found",
        })
      );
    } else {
      openNewTab(subscription.hosted_invoice_url);
    }
  };

  const amount = subscription.total / 100;
  const formattedAmount = numberSeparator(roundOff(amount));

  return (
    <BillingMobileCardContainer>
      <div className="header">
        <div className="strategy-name">
          <div className="title">Item</div>
          <div className="value">
            {subscription.subscription_details?.metadata?.description || "-"}
          </div>
        </div>

        <div className="btn-view-details" onClick={handleOpenInvoice}>
          View details
        </div>
      </div>
      <div className="details-wrapper">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Price</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {unixToDateTime(subscription.created, MONTH_DATE_YEAR_FORMAT)}
              </td>
              <td>{formattedAmount}</td>
              <td>{subscription.status.toUpperCase()}</td>
            </tr>
          </tbody>
        </table>
        <div className="divider"></div>
      </div>
    </BillingMobileCardContainer>
  );
};

HistoryMobileCard.propTypes = {
  subscription: PropTypes.object,
};

export default HistoryMobileCard;
