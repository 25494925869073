import styled from "styled-components";

export const Heading = styled.h2`
  font-weight: ${({ theme }) => theme.constants.FONT_WEIGHT_700};
  font-size: ${({ theme }) => theme.constants.FONT_SIZE_28};
  color: ${({ theme }) => theme.heading.color.secondary};
  margin-bottom: 0.85em;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
