import { get, post, put, del } from "services/httpService";

const SERVICE_URLS = {
  getProfile: () => "user/detail/",
  updateProfile: () => "user/detail/",
  getBillingHistory: (qs) => `user/billing-history/?${qs}`,
  changePassword: () => "user/change-password/",
  uploadUserIcon: () => "user-icon/",
  purchasePlan: () => "user/plan-subscription/",
  getCardDetails: () => "user/payment-details/",
  updateCardDetails: () => "subscription/update-payment-method/",
  cancelPlan: () => "user/cancel-subscription/",
  change2FA: () => `user/mfa/`,
  verifyPhone: () => "user/verify/",
  updatePhone: () => `user/phone/`,
  resendVerification: () => "user/send-mfa/",
  updateAndverifyPhone: () => "user/phone/",
};

export const getProfile = () => get(SERVICE_URLS.getProfile());
export const getBillingHistory = (qs) =>
  get(SERVICE_URLS.getBillingHistory(qs));

export const changePassword = (payload) =>
  post(SERVICE_URLS.changePassword(), payload);

export const updateProfile = (payload) =>
  put(SERVICE_URLS.updateProfile(), payload);

export const uploadUserIcon = (payload) =>
  post(SERVICE_URLS.uploadUserIcon(), payload);

export const purchasePlan = (payload) =>
  post(SERVICE_URLS.purchasePlan(), payload);

export const getCardDetails = (payload) =>
  get(SERVICE_URLS.getCardDetails(), payload);

export const updateCardDetails = (payload) =>
  post(SERVICE_URLS.updateCardDetails(), payload);

export const cancelPlan = (payload) => del(SERVICE_URLS.cancelPlan(), payload);

export const change2FA = (payload) => post(SERVICE_URLS.change2FA(), payload);

export const updatePhone = (payload) =>
  post(SERVICE_URLS.updatePhone(), payload);

export const verifyPhone = (payload) =>
  post(SERVICE_URLS.verifyPhone(), payload);

export const resendVerification = (payload) =>
  post(SERVICE_URLS.resendVerification(), payload);

export const updateAndverifyPhone = (payload) =>
  put(SERVICE_URLS.updateAndverifyPhone(), payload);
