import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import {
  iconAmex,
  iconVisa,
  iconDiners,
  iconEftposAu,
  iconUnionpay,
  iconMastercard,
  iconJcb,
} from "assets/icons/payment";
import { unixToDateTime, MONTH_DATE_YEAR_FORMAT } from "utils/date";
import { useNavigation } from "utils/navigate";
import { PlanCardContainer } from "./PlanCard.styles";
import { userDetailsState } from "Layout/slice/selector";
import { contains, numberSeparator, roundOff } from "utils";

const PlanCard = () => {
  const { navigateToProfilePayment } = useNavigation();

  const userDetails = useSelector(userDetailsState);

  const handleUpdateClick = () => {
    navigateToProfilePayment();
  };

  const brandIcons = {
    visa: iconVisa,
    amex: iconAmex,
    diners: iconDiners,
    eftpos_au: iconEftposAu,
    jcb: iconJcb,
    mastercard: iconMastercard,
    unionpay: iconUnionpay,
  };

  const plan = userDetails.surmount_plan;
  const subscription = userDetails?.stripe_subscription;
  const defaultPayment = subscription?.default_payment_method;
  const price = subscription?.items?.data[0]?.price.unit_amount / 100;
  const formattedPrice = numberSeparator(roundOff(price));

  return (
    <PlanCardContainer>
      {/* {userDetails?.plan?.plan === plan.name ? (
        <div className="plan-header">Current Plan</div>
      ) : (
        <div className="plan-header">Available Plan</div>
      )} */}

      <div className="plan-name">{plan?.name}</div>
      <div className="plan-details">
        <Row>
          <Col md={7}>
            <ul>
              {plan.benefits.slice(0, 5).map((benefit, index) => {
                return <li key={index}>{benefit}</li>;
              })}
            </ul>
          </Col>
          <Col md={5}>
            <h4>Payment</h4>
            {contains(plan?.name, "Free") && (
              <>
                <div className="amount-wrapper">
                  <span className="amount">$0</span>
                  <sub className="duration">/month</sub>
                </div>
              </>
            )}

            {contains(plan?.name, "Premium") && userDetails?.dummy_premium && (
              <>
                <div className="amount-wrapper">
                  <div className="line-through">$20</div>
                  <span className="amount"> $0</span>
                  <sub className="duration">/month</sub>
                </div>
                <p className="benefit">
                  Thank you for being an early user! You’re getting all the
                  benefits of Surmount Premium for free for a limited time!
                </p>
              </>
            )}

            {contains(plan?.name, "Premium") && userDetails?.payment_failed && (
              <p className="benefit">
                Your recent payment for {plan.name} failed. Please update your
                payment method
              </p>
            )}

            {contains(plan?.name, "Premium") &&
              !userDetails?.dummy_premium &&
              !userDetails?.payment_failed && (
                <>
                  <p className="mb-4">
                    Your next bill is for <b>${formattedPrice || "0.00"}</b> +
                    tax on{" "}
                    <b>
                      {unixToDateTime(
                        subscription?.current_period_end,
                        MONTH_DATE_YEAR_FORMAT
                      ) || "x/x/xx"}
                      .
                    </b>
                  </p>
                  <div className="card-wrapper">
                    {brandIcons[defaultPayment.card.brand] && (
                      <div className="icon">
                        <img src={brandIcons[defaultPayment.card.brand]} />
                      </div>
                    )}
                    <div>
                      <div className="ending">
                        <span className="brand">
                          {defaultPayment.card.brand}
                        </span>{" "}
                        ending in {defaultPayment.card.last4 || "xxxx"}
                      </div>
                      <div className="date">
                        Expires:{" "}
                        {`${defaultPayment.card.exp_month}/${defaultPayment.card.exp_year}` ||
                          "xx/xxxx"}
                      </div>
                    </div>
                    <div className="btn-update" onClick={handleUpdateClick}>
                      Update
                    </div>
                  </div>
                </>
              )}
          </Col>
        </Row>
      </div>
    </PlanCardContainer>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
  handleCancelPlan: PropTypes.func,
};

export default PlanCard;
