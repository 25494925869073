import { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "styled-components";

import { PROFILE_MENU } from "../routes";
import { MobileHeaderMenuContainer } from "./MobileHeaderMenu.styles";

const MobileHeaderMenu = ({ navigationDict, active }) => {
  const theme = useTheme();

  return (
    <MobileHeaderMenuContainer>
      {PROFILE_MENU.map(
        ({ path, Icon, title, isVisible }) =>
          isVisible && (
            <Fragment key={path}>
              <div
                onClick={navigationDict[path]}
                className={classNames("link", {
                  active: active === path,
                })}
              >
                <span className="icon">
                  <Icon fill={theme.aside.icon.primary} width={30} />
                </span>
                <span>{title}</span>
              </div>
            </Fragment>
          )
      )}
    </MobileHeaderMenuContainer>
  );
};

MobileHeaderMenu.propTypes = {
  navigationDict: PropTypes.object,
  active: PropTypes.string,
};

export default MobileHeaderMenu;
