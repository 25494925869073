import { lazy } from "react";
import { HostStrategyForm } from "./Host/HostStrategyForm";

const StrategyList = lazy(() => import("features/Strategy"));
const CodeBuilder = lazy(() => import("features/Strategy/CodeBuilder"));
const StrategyDetail = lazy(() => import("features/Strategy/Detail"));
const StrategHost = lazy(() => import("features/Strategy/Host"));
const HostProgress = lazy(() => import("features/Strategy/Host/HostProgress"));
const NoCodeBuilder = lazy(() => import("features/Strategy/NoCodeBuilder"));

export const STRATEGY_PATH = "/strategy";
export const STRATEGY_LIST_PATH = "/strategy/list";
export const STRATEGY_CREATE_PATH = "/strategy/create";
export const STRATEGY_EDIT_PATH = "/strategy/:id/edit";
export const STRATEGY_DETAIL_PATH = "/strategy/:id/detail";
export const STRATEGY_HOST_PATH = "/strategy/:id/host";
export const STRATEGY_HOST_PROGRESS_PATH = "/strategy/:id/host-progress";
export const STRATEGY_HOST_FORM_PATH = "/strategy/:id/host-form";
export const NO_CODE_BUILDER_PATH = "/strategy/:id/no-code-builder";
export const NO_CODE_BUILDER_EDIT_PATH = "/strategy/:id/ncb-edit";

export default [
  {
    path: STRATEGY_PATH,
    component: <StrategyList />,
  },
  {
    path: STRATEGY_LIST_PATH,
    component: <StrategyList />,
  },
  {
    path: STRATEGY_CREATE_PATH,
    component: <CodeBuilder />,
  },
  {
    path: STRATEGY_EDIT_PATH,
    component: <CodeBuilder />,
  },
  {
    path: STRATEGY_DETAIL_PATH,
    component: <StrategyDetail />,
  },
  {
    path: STRATEGY_HOST_PATH,
    component: <StrategHost />,
  },
  {
    path: STRATEGY_HOST_PROGRESS_PATH,
    component: <HostProgress />,
  },
  {
    path: NO_CODE_BUILDER_PATH,
    component: <NoCodeBuilder />,
  },
  {
    path: NO_CODE_BUILDER_EDIT_PATH,
    component: <NoCodeBuilder />,
  },
  {
    path: STRATEGY_HOST_FORM_PATH,
    component: <HostStrategyForm />,
  },
];
