import React from "react";
import { Button } from "components/Button";
import { successIcon } from "assets/images";
import PropTypes from "prop-types";
import { PaymentModalContainer } from "shared/Modal/PaymentFailedModal/PaymentModal.styles";

export const PaymentSuccessModal = (props) => {
  return (
    <PaymentModalContainer show={props.show} onHide={props.onHide}>
      <div className="top-heading-space">
        <h3 className="top-heading">Victory</h3>
      </div>
      <div className="logo-img">
        <img src={successIcon} className="img" />
      </div>
      <div className="heading-space">
        <h3 className="heading">Payment Successful!</h3>
      </div>
      <div className="btn-space">
        <Button
          onClick={props.handleProceedClick ?? props.onHide}
          label={props.buttonTitle}
          width={229}
          className="btn-proceed"
        />
      </div>
    </PaymentModalContainer>
  );
};

PaymentSuccessModal.propTypes = {
  show: PropTypes.bool,
  showDescription: PropTypes.bool,
  onHide: PropTypes.func,
  handleProceedClick: PropTypes.func,
  buttonTitle: PropTypes.string,
};

PaymentSuccessModal.defaultProps = {
  show: false,
  showDescription: true,
  buttonTitle: "Proceed",
};

PaymentSuccessModal.displayName = "PaymentSuccessModal";
