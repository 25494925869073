import styled from "styled-components";
import { Modal } from "components/Modal";

export const PaymentModalContainer = styled(Modal)`
  .modal-content {
    background: ${({ theme }) => theme.global.bg.quaternary};
    border-radius: 15px;
    width: 446px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 0;

    .modal-header {
      border: 0;
    }
    .close {
      border: 0;
      right: 12px;
      top: 1px;
      position: absolute;
      color: ${({ theme }) => theme.modal.button.close.color};
      background: transparent;
      font-size: 1.5rem;
      z-index: 1;
    }

    .modal-body {
      .top-heading-space {
        display: flex;
        justify-content: center;
        .top-heading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 23px;
          line-height: 24px;
          text-align: center;
          color: ${({ theme }) => theme.heading.color.secondary};
        }
      }
      .para-space {
        margin-top: 0.5em;
        p {
          font-family: "Poppins";
          font-weight: 400;
          font-size: 15px;
          text-align: center;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
        }
      }
      .btn-space {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        margin-bottom: 1em;
        .btn-proceed {
          background: ${({ theme }) => theme.global.color.quinary};
          box-shadow: none;
          border-radius: 7px;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
          padding: 8px 0;
          margin-top: 12px;
        }
      }
      .logo-img {
        display: flex;
        justify-content: center;
        margin: 16px 0;

        .img {
          width: 149px;
          height: 135px;
        }
      }
      .heading-space {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        .heading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: ${({ theme }) => theme.heading.color.secondary};
          text-align: center;
        }
      }
    }
  }
`;
