import aes from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";

import { AES_KEY } from "./constants";

export const getDencrypted = (bytes) => {
  const dencrypted = aes.decrypt(bytes, AES_KEY);
  return dencrypted.toString(encUtf8);
};

export const getEncrypted = (string) => {
  const encrypted = aes.encrypt(string, AES_KEY);
  return encrypted.toString();
};
