import styled from "styled-components";

export const MobileHeaderMenuContainer = styled.div`
  display: flex;
  gap: 20px;
  overflow: scroll;
  margin-bottom: 10px;

  .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: ${({ theme }) => theme.global.color.quaternary};
    padding: 1.2rem 0rem;
    display: flex;
    align-items: center;
    min-width: 200px;
    cursor: pointer;

    &.active {
      color: ${({ theme }) => theme.nav.font.color};

      .icon {
        opacity: 1;
      }
    }

    .icon {
      margin-right: 10px;
      opacity: 0.5;
      width: 30px;
      display: inline-block;
    }
  }
`;
