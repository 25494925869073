import { string, object } from "yup";

export const phoneInitialValues = ({ phone }) => ({
  phone: phone || "",
});

export const phoneValidationSchema = object({
  phone: string()
    .trim()
    .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number"),
});

export const codeInitialValues = {
  code: "",
};

export const codeValidationSchema = object({
  code: string()
    .trim()
    .max(6, "Must be 6 characters or less")
    .required("Required"),
});
