import { createSlice } from "@reduxjs/toolkit";

import { isLoggedInCases, setAuthUserReducer } from "./reducer";

const initialState = {
  isLoggedIn: false,
  authUser: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: { setAuthUser: setAuthUserReducer },
  extraReducers: (builder) => {
    isLoggedInCases(builder);
  },
});

export const { setAuthUser } = authSlice.actions;

export default authSlice.reducer;
