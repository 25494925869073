import { Routes, Route, Navigate } from "react-router-dom";

import { isLoggedIn } from "utils";
import { privateRoutes } from "./namespaces";

const PrivateRoutes = () => {
  return (
    <Routes>
      {!isLoggedIn() && (
        <Route
          path="*"
          element={
            <Navigate
              to="/signin"
              replace
              state={{ from: location.pathname }}
            />
          }
        />
      )}

      {isLoggedIn() &&
        privateRoutes.map((route) => (
          <Route path={route.path} element={route.component} key={route.path}>
            {route.subRoute?.map((subRoute) => (
              <Route
                path={subRoute.path}
                element={subRoute.component}
                key={subRoute.path}
              />
            ))}
          </Route>
        ))}
    </Routes>
  );
};

export default PrivateRoutes;
