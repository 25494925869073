import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { MONTH_DATE_YEAR_FORMAT } from "utils/date";
import { DateTimePickerContainer } from "./DateTimePicker.styles";

const DateTimePicker = ({ label, ...props }) => {
  return (
    <DateTimePickerContainer>
      <Form.Group className="mb-4">
        {label && <Form.Label>{label}</Form.Label>}
        <DatePicker
          dateFormat={MONTH_DATE_YEAR_FORMAT}
          customInput={<input className="form-control filter-form-control" />}
          {...props}
        />
      </Form.Group>
    </DateTimePickerContainer>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
};

export default DateTimePicker;
