import styled from "styled-components";
import { Modal } from "components/Modal";

export const AddTargetAssetModalContainer = styled(Modal)`
  .modal-dialog {
    min-width: 584px;
    height: 616px;

    .modal-content {
      padding: 30px;
      height: 630px;

      .modal-header {
        padding-top: 7px;
        .add-asset-modal-heading {
          margin: 0px auto;
          padding-bottom: 40px;
          font-weight: 700;
          font-size: 28px;
          line-height: 130%;
          color: ${({ theme }) => theme.modal.subHeading.font.color};
        }
      }
      .modal-body {
        padding: 0;
      }
      .asset-search {
        margin: auto;
        margin-top: 37px;
        .search-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 130%;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
          margin-bottom: 23px;
        }
        .search-input {
          color: ${({ theme }) => theme.input.font.primary} !important;
          padding-left: 20px;
          width: 524px;
          height: 46px;
          background: ${({ theme }) => theme.input.bg.primary};
          border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
          padding-right: 125px;
          border: 1px solid ${({ theme }) => theme.input.border.color};

          &:focus {
            outline: none;
          }
        }
        .search-button {
          position: absolute;
          width: 100px;
          height: 40px;
          background: #048af5;
          ${({ theme }) => theme.constants.BORDER_RADIUS_8};
          right: 10px;
          top: 8px;
        }
      }
      .premade-screeners {
        margin: auto;
        padding-top: 2.5em;
        padding-bottom: 1em;
        .heading {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
          margin-top: 29px;
          margin-bottom: 0.6em;
        }
        .screener {
          mix-blend-mode: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 113.6px;
          height: 50px;
          background: ${({ theme }) => theme.dropdown.bg.secondary};
          border: 2px solid #a5c7ff;
          border-radius: 6px;

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: #a5c7ff;
          }
        }
      }
      .found-assets {
        margin: auto;

        .arrow-back {
          position: absolute;
          margin-top: 10px;
          cursor: pointer;
        }
        .selected-screener {
          width: 135px;
          height: 43px;
          background: #1e1e2e;
          mix-blend-mode: normal;
          border: 1px solid #ffffff;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          gap: 9px;
          display: flex;
          cursor: pointer;
          margin: auto;
          div {
            font-weight: 500;
            font-size: 15px;
            line-height: 0px;
          }
        }
        .heading {
          text-transform: capitalize;
          font-weight: 700;
          font-size: 16px;
          line-height: 130%;
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.heading.color.secondary};
        }
        .asset-list {
          overflow: auto;
          height: 230px;
          .asset-item {
            width: 100%;
            background: ${({ theme }) => theme.input.bg.primary};
            border-radius: 31px;
            height: 40px;
            mix-blend-mode: normal;
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 15px;

            .asset-item-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .asset-img {
                width: 29px;
                height: 28px;
                margin: 0 6px;
              }
              .asset-title {
                margin-left: 17px;
                font-weight: 700;
                font-size: 18px;
                width: 100px;
                color: ${({ theme }) => theme.global.extraInfo.font.color};

                .mobile-asset-name {
                  font-weight: 500;
                  font-size: 13px;
                  width: 130px;
                }
              }
            }
            .arrow-right {
              height: 12px;
            }
            .asset-subscript {
              font-weight: 500;
              width: 200px;
              font-size: 14px;
              color: ${({ theme }) => theme.global.extraInfo.font.color};
            }
            .asset-value {
              width: 80px;
              text-align: right;
              font-weight: 500;
              font-size: 14px;
              color: ${({ theme }) => theme.constants.COLOR_GREEN};
            }
          }
        }
        .add-now-btn {
          font-size: 14px;
          display: block;
          margin: auto;
          margin-top: 32px;
          width: 524px;
          height: 50px;
          background: #048af5;
          ${({ theme }) => theme.constants.BORDER_RADIUS_8};
        }
        .link-more {
          cursor: pointer;
          text-align: center;
          text-decoration: underline;
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .modal-dialog {
      min-width: auto;

      .modal-content {
        padding: 12px;
        height: 590px;

        .search-button {
          top: 3px;
        }

        .asset-search .search-input {
          width: 100%;
          height: 45px;
        }

        .asset-title {
          font-weight: 600;
          font-size: 14px;
          width: 130px;

          .mobile-asset-name {
            display: none;
          }
        }

        .asset-subscript {
          display: none;
        }

        .found-assets .add-now-btn {
          width: 100%;
        }
      }
    }
  }
`;
