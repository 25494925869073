import styled from "styled-components";

import { HostBanner } from "assets/images/Host";

export const HostContainer = styled.div`
  .back-btn {
    padding: 3px 25px 3px 20px !important;
    width: 110px !important;
    height: 45px;
    background-color: ${({ theme }) => theme.card.bg.quaternary} !important;
    ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    border: none;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #048af5;
    margin-left: 10px;
  }

  .strategy-host {
    .flex-section {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }

    .section-title {
      font-weight: 700;
      font-size: 18px;
    }
    .section-desc {
      font-weight: 400;
      font-size: 14px;
      color: #c6c6c6;
    }

    .host-section {
      background-image: url("${HostBanner}");
      background-size: 100% 100%;
      -o-background-size: 100% 100%;
      -webkit-background-size: 100% 100%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20px;
      padding: 93px 36px;
      width: 100%;
      height: 298.33px;
      margin-bottom: 100px;
      background-color: rgba(23, 34, 55, 0.3);
      border-radius: 10px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 14px;
      }

      .title {
        font-weight: 700;
        font-size: 64px;
        line-height: 100%;
        color: ${({ theme }) => theme.heading.color.secondary};
      }

      .desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
      }

      .host-btn {
        margin-top: 5px;
        width: 314px;
        height: 50px;
        background: #048af5;
        ${({ theme }) => theme.constants.BORDER_RADIUS_8};
      }
    }

    .progress-section {
      height: 1000px;
      margin-bottom: 100px;
      position: relative;
      .progress-title {
        font-weight: 600;
        font-size: 25px;
        color: ${({ theme }) => theme.global.extraInfo.font.color};
        text-align: center;
        margin-top: 240px;
      }

      img {
        position: absolute;
        top: 60px;
        right: 40px;
      }

      .req-btn {
        padding: 3px 25px;
        width: 314px;
        height: 50px;
        background: #048af5;
        ${({ theme }) => theme.constants.BORDER_RADIUS_8};
        margin: 0 auto;
        display: block;
      }
      .item-wrapper {
        padding: 0;
        .item-icon {
          padding: 0;
        }
        .item-icon-inactive {
          background: #048af5;
          opacity: 0.5;
        }
      }
      .stepper-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: ${({ theme }) => theme.global.extraInfo.font.color};
      }
      .title-inactive {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .income-section {
      align-items: center;
      padding: 0px 80px 0px 30px;
      margin-bottom: 20px;
      margin-bottom: 100px;

      .section-child {
        width: 60%;
        .section-title {
          font-weight: 700;
          font-size: 28px;
          line-height: 130%;
          text-align: right;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
        }
        .section-desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: right;
          color: #c6c6c6;
        }
      }
    }

    .reward-section {
      align-items: center;
      padding: 50px 80px 50px 30px;
      background-color: rgba(23, 34, 55, 0.3);
      border-radius: 10px;
      margin-bottom: 100px;
      .section-child {
        width: 60%;
        .section-title {
          text-align: left;
          font-weight: 700;
          font-size: 28px;
          line-height: 130%;
          color: ${({ theme }) => theme.global.extraInfo.font.color};
        }
        .section-desc {
          text-align: left;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .host-section {
      h2 {
        font-size: 32px !important;
      }
    }
    .host-section,
    .income-section,
    .reward-section {
      img {
        width: 200px !important;
      }
    }
  }
  @media (max-width: 600px) {
    .host-section {
      button {
        width: 100% !important;
      }
    }
    .host-section,
    .income-section,
    .reward-section {
      padding-right: 30px !important;
      img {
        display: none;
      }
      .section-child {
        width: 100% !important;
      }
    }
    .progress-title {
      margin-top: 50px !important;
    }
    .stepper {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      .stepper-item {
        &:after {
          display: none;
        }
        .item-icon {
          width: 20px !important;
        }
      }
    }
  }
`;

export const HostFormContainer = styled.div`
  .back-btn {
    padding: 3px 25px 3px 20px !important;
    width: 110px !important;
    height: 45px;
    background-color: ${({ theme }) => theme.global.color.bodyColor} !important;
    ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    border: none;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #048af5;
  }
  .body-heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    color: ${({ theme }) => theme.global.color.bodyColor};
  }
  .creation-form {
    padding: 30px 30px 40px;
    width: 492px;
    background: ${({ theme }) => theme.global.color.bodyColor};
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    position: relative;
    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      opacity: 1;
    }
  }
  .form-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    margin-top: 30px;
  }
  .host-key-statistics-container {
    color: ${({ theme }) => theme.global.color.tertiary};
    box-shadow: none;
    width: 100%;
    margin-top: 40px;

    .no-stats {
      padding-bottom: 14px;
      color: ${({ theme }) => theme.global.color.quaternary};
      font-size: 14px;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: ${({ theme }) => theme.heading.color.secondary};
      margin-bottom: 20px;
    }
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      padding: 0;
      margin: 0;
      padding-bottom: 0.7em;
      // @include respond-max("md-tab") {
      //   columns: 1;
      //   -webkit-columns: 1;
      //   -moz-columns: 1;
      // }
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;

        .key {
          color: ${({ theme }) => theme.global.color.bodyColor};
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins";
          font-style: normal;

          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .stats {
          font-size: 16px;
          font-weight: 400;
          color: ${({ theme }) => theme.global.color.quinary};
          font-family: "Poppins";
          font-style: normal;
          text-align: right;
          text-transform: capitalize;
        }
      }
    }
  }
  .form-control {
    padding: 10px 15px;
    width: 432px;
    background: ${({ theme }) => theme.input.bg.primary};
    border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    border: none;
    color: ${({ theme }) => theme.heading.color.secondary};
  }
  .submit-btn {
    padding: 3px 25px;
    width: 432px;
    height: 50px;
    background: #048af5;
    ${({ theme }) => theme.constants.BORDER_RADIUS_8};
    margin-top: 30px;
  }
  @media (max-width: 520px) {
    .creation-form {
      width: 100% !important;
      .submit-btn {
        width: 100%;
      }
      .host-input {
        .form-control {
          width: 100%;
        }
      }
    }
  }
`;
