import { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import { useTheme } from "styled-components";

import { EyeIcon, EyeSlashIcon } from "assets/images";

const Input = ({
  label,
  helpText,
  placeholder,
  disabled,
  type,
  className,
  ...props
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group className={`input-form ${className ? className : ""}`}>
      <div className="d-flex justify-content-between">
        {label && <Form.Label className="input-form-label">{label}</Form.Label>}
        {helpText && <span className="text-muted">{helpText}</span>}
      </div>

      <Form.Control
        disabled={disabled}
        className={classNames("input-form-field", {
          "padd-right": type === "password",
          "input-form-field-description": type === "textarea",
        })}
        placeholder={placeholder}
        type={showPassword ? "text" : type}
        {...props}
      />
      {type == "password" && (
        <>
          {showPassword && (
            <EyeSlashIcon
              fill={theme.input.icon.color}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
          {!showPassword && (
            <EyeIcon
              fill={theme.input.icon.color}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </>
      )}
    </Form.Group>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  label: null,
  helpText: "",
  placeholder: "",
  disabled: false,
};

export default Input;
