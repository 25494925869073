import PropTypes from "prop-types";
import { Placeholder as BSPlaceholder } from "react-bootstrap";

export const Placeholder = ({
  size,
  bg,
  children,
  as = "div",
  col = { xs: 12 },
  isLoading = true,
  animation = "glow",
  ...props
}) =>
  isLoading ? (
    <BSPlaceholder bg={bg} as={as} animation={animation}>
      <BSPlaceholder {...col} size={size} {...props} />
    </BSPlaceholder>
  ) : (
    <>{children}</>
  );

Placeholder.propTypes = {
  as: PropTypes.any,
  col: PropTypes.any,
  bg: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  animation: PropTypes.string,
};
