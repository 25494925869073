import styled from "styled-components";
import { iconClose } from "assets/images";
import BTModal from "react-bootstrap/Modal";

export const ModalContainer = styled(BTModal)`
  .modal-header {
    border: 0;
    .body-heading {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: ${({ theme }) => theme.global.color.bodyColor};
    }
  }

  .btn-close {
    background: url("${iconClose}") center/1em auto no-repeat;
    position: absolute;
    right: 16.8px;
    top: 16.8px;
    opacity: 1;
    outline: none;
    z-index: 1;

    :hover {
      color: ${({ theme }) => theme.modal.button.close.color};
    }
    &:focus {
      box-shadow: none;
    }
  }

  .modal-body {
    margin-top: -50px;
    padding-top: 0;
  }
`;
