import styled from "styled-components";

export const PlanCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.card.bg.secondary};
  border: 1px solid #a5c7ff;
  padding: 0 1.5em 1.5rem;
  border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};
  min-width: 214px;
  max-width: 340px;
  height: 100%;
  margin: 0 auto;

  .plan-name {
    background: ${({ theme }) => theme.card.bg.senary};
    color: ${({ theme }) => theme.button.font.primary};
    border-radius: 0px 0px 20px 20px;
    width: 85%;
    margin-top: -1px;
    font-weight: 700;
    font-size: 25px;
    padding: 2px 5px;
  }

  p.amount {
    font-size: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    margin-top: 1.6em;
    margin-bottom: 1em;

    span {
      font-size: 14px;
      color: #c9c9c9;
      font-weight: 600;
      vertical-align: middle;
      display: inline-block;
      margin-left: 8px;
      margin-top: 10px;
    }

    sup {
      font-size: 12px;
      font-weight: 600;
      vertical-align: super;
      margin-right: 5px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    margin-top: 3em;
    margin-bottom: 1em;
    text-align: start;
  }

  ul {
    list-style: disc;

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .get-start-btn {
    margin-top: 20px;
    width: 100%;
  }
`;

export const AvailablePlanCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  height: 100%;
  width: 340px;
  background-color: ${({ theme }) => theme.card.bg.quinary};
  border-radius: ${({ theme }) => theme.constants.BORDER_RADIUS_8};

  &:hover {
    box-shadow: 0px 0px 20px rgba(4, 138, 245, 0.3);
  }

  .plan-name {
    color: ${({ theme }) => theme.global.extraInfo.font.color};
    font-weight: 600;
    font-size: 25px;
    padding: 2px 5px;
  }

  .line-through {
    position: relative;
    display: inline-block;
  }

  .line-through::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1.5px;
    background-color: ${({ theme }) => theme.global.extraInfo.font.color};
    transform: rotate(-50deg);
    transform-origin: 50% 50%;
  }

  .amount {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    font-size: 28px;
    font-weight: 600;
    margin: 1rem 0;
    color: ${({ theme }) => theme.global.extraInfo.font.color};

    span {
      font-size: 12px;
      font-weight: 400;
      vertical-align: middle;
      display: inline-block;
      margin-left: 8px;
      margin-top: 10px;
    }

    sup {
      font-size: 12px;
      font-weight: 600;
      vertical-align: super;
      margin-right: 5px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 1.5rem;

    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      line-height: 2rem;

      &::marker {
        color: gray;
      }
    }
  }

  .get-start-btn {
    margin-bottom: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
