import styled from "styled-components";

export const PlanAndBillingContainer = styled.div`
  .plan-wrapper {
    margin-bottom: 3rem;
  }
  h2 {
    margin-bottom: 5px;
  }

  .billing-history-wrapper {
    tr {
      border-color: ${({ theme }) => theme.table.border.color};

      th {
        text-align: start;
      }
    }
  }

  @media (max-width: 1600px) {
    .row [class*="col-xl"] {
      width: 88%;
    }
  }

  @media (max-width: 576px) {
    .plan-wrapper .available-plan-wrapper {
      flex-direction: column;
    }
  }
`;

export const ViewDetailsLink = styled.div`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;
