import { useEffect, useState } from "react";
import { Alert, CloseButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { BannerContainer } from "./Banner.styles";
import { useTheme } from "styled-components";
import { contains, isLoggedIn, parseErrorMessage } from "utils";
import LocationPath from "hooks/locationPath";
import { setHasBanner, toast } from "Layout/slice";
import { getBannerDetails } from "services";
import { getItemFromStorage, setItemInStorage } from "utils/storage";
import { BANNER_KEY } from "utils/constants";
import { WELCOME_PATH } from "features/Auth/routes";
import { Button } from "components/Button";
import { useNavigation } from "utils/navigate";

const Banner = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { navigateTo } = useNavigation();
  const { pathname } = useLocation();

  const [bannerData, setBannerData] = useState([]);
  const [show, setShow] = useState(false);
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const closedBanner = getItemFromStorage(BANNER_KEY);

  useEffect(() => {
    setIsBannerClosed(closedBanner);
    if (closedBanner !== null) {
      dispatch(setHasBanner(!closedBanner));
    }
  }, [closedBanner]);

  useEffect(() => {
    handlePathShowBanner(pathname);
  }, [isLoggedIn()]);

  const getBanner = async () => {
    if (isLoggedIn()) {
      try {
        const response = await getBannerDetails();
        setBannerData(response);
        if (response.length > 0 && !closedBanner) {
          dispatch(setHasBanner(true));
        }
      } catch (error) {
        dispatch(
          toast({
            type: "error",
            body: parseErrorMessage(error),
          })
        );
      }
    }
  };

  const toggleStrategyShow = () => {
    setShow(!show);
  };

  const handleButtonClick = (link, inx) => {
    handleClose(inx);
    const linkData = link.split("?");
    navigateTo({
      pathname: linkData[0],
      search: linkData[1],
    });
  };

  const handleClose = (inx) => {
    const allBanners = [...bannerData];
    allBanners.splice(inx, 1);

    if (allBanners.length === 0) {
      toggleStrategyShow();
      setIsBannerClosed(true);
      setItemInStorage(BANNER_KEY, true);
      dispatch(setHasBanner(false));
    }

    setBannerData(allBanners);
  };

  const handlePathChange = (path) => {
    handlePathShowBanner(path);
    getBanner();
  };

  const handlePathShowBanner = (url) => {
    if (isLoggedIn() && !contains(url, WELCOME_PATH)) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <LocationPath changedPath={handlePathChange} />
      {!isBannerClosed && show && (
        <BannerContainer>
          {bannerData.map((data, inx) => {
            const banner = data?.banners
              ? JSON.parse(data?.banners?.content)
              : {};
            return (
              <Alert className="alert" key={data.id}>
                <div className="wrapper">
                  <div className="message-wrapper">
                    {banner.message}
                    <Button
                      className="btn-action"
                      onClick={() => handleButtonClick(data.banners.link, inx)}
                    >
                      {banner.bubble}
                    </Button>
                  </div>
                  <CloseButton
                    onClick={() => handleClose(inx)}
                    variant={theme.banner.icon.color}
                  />
                </div>
              </Alert>
            );
          })}
        </BannerContainer>
      )}
    </>
  );
};

export default Banner;
