import { NavDropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import { DropdownContainer } from "./Dropdown.styles";

export const Dropdown = ({
  title,
  align,
  options,
  onNavClick,
  onOptionClick,
  className,
  disabled,
  divider,
  children,
  Option,
  ...props
}) => {
  return (
    <DropdownContainer className={`${disabled && "disabled"}`}>
      {children}
      <NavDropdown
        onClick={onNavClick}
        align={align}
        title={title}
        className={className}
        id="basic-nav-dropdown"
        disabled={disabled}
        {...props}
      >
        {options.map((option, i) => {
          return (
            <div
              key={option.key ? option.key : i}
              className={classNames({
                "selected-option": title == option.key,
              })}
            >
              <NavDropdown.Item
                eventKey={option.id}
                onClick={(e) => {
                  onOptionClick(option, e);
                }}
              >
                {!Option && (
                  <>
                    <span className="icon">{option.icon}</span>
                    <span className="title">{option.title}</span>
                  </>
                )}
                {Option && <Option {...option} />}
              </NavDropdown.Item>
              {options.length - 1 !== i && divider && <NavDropdown.Divider />}
            </div>
          );
        })}
      </NavDropdown>
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  title: PropTypes.any,
  align: PropTypes.oneOf(["start", "end"]),
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
  onNavClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  children: PropTypes.node,
  Option: PropTypes.func,
  svgIcon: PropTypes.func,
};

Dropdown.defaultProps = {
  title: null,
  align: "end",
  options: [],
  onOptionClick: null,
  onNavClick: null,
  className: "",
  disabled: false,
  divider: true,
};
