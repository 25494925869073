import {
  removeStrategy,
  fetchStrategyList,
  fetchCommunityStrategiesList,
} from "./thunk";

export const strategyListCases = (builder) => {
  builder.addCase(fetchStrategyList.pending, (state) => {
    state.loading = true;
    state.errorMessage = "";
  });
  builder.addCase(fetchStrategyList.fulfilled, (state, action) => {
    state.strategies = action.payload;
    state.loading = false;
  });
  builder.addCase(fetchStrategyList.rejected, (state, action) => {
    state.loading = false;
    state.errorMessage = action.payload.error;
  });
};

export const removeStrategyCases = (builder) => {
  builder.addCase(removeStrategy.pending, (state) => {
    state.loading = true;
    state.errorMessage = "";
    state.refetchStrategies = false;
  });
  builder.addCase(removeStrategy.fulfilled, (state) => {
    state.loading = false;
    state.errorMessage = "";
    state.refetchStrategies = true;
  });
  builder.addCase(removeStrategy.rejected, (state, action) => {
    state.loading = false;
    state.errorMessage = action.payload.error;
    state.refetchStrategies = false;
  });
};

export const communityStrategylistCases = (builder) => {
  builder.addCase(fetchCommunityStrategiesList.pending, (state) => {
    state.loading = true;
    state.errorMessage = "";
  });
  builder.addCase(fetchCommunityStrategiesList.fulfilled, (state, action) => {
    state.communityStrategies = action.payload;
    state.loading = false;
  });
  builder.addCase(fetchCommunityStrategiesList.rejected, (state, action) => {
    state.loading = false;
    state.errorMessage = action.payload.error;
  });
};

export const setErrorMessageReducer = (state, action) => {
  state.errorMessage = action.payload;
};

export const setBacktestStatusLog = (state, action) => {
  if (Array.isArray(action.payload)) {
    state.backtestStatusLog = [];
  } else {
    state.backtestStatusLog.push(action.payload);
  }
};
