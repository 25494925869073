import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorMessage, makeQueryParams } from "utils";

import {
  deleteStrategy,
  getCommunityStrategies,
  getStrategies,
} from "../service";

export const fetchStrategyList = createAsyncThunk("strategy/list", async () => {
  const response = await getStrategies();
  return response;
});

export const fetchBookmarkedStrategyList = createAsyncThunk(
  "strategy/list",
  async (filter) => {
    const response = await getCommunityStrategies(
      makeQueryParams({ type: filter })
    );
    return response;
  }
);

export const fetchCommunityStrategiesList = createAsyncThunk(
  "community-strategy/list",
  async (params, thunkAPI) => {
    try {
      const response = await getCommunityStrategies();
      return response;
    } catch (error) {
      const msg = parseErrorMessage(error);
      return thunkAPI.rejectWithValue({ error: msg });
    }
  }
);

export const removeStrategy = createAsyncThunk(
  "strategy/delete",
  async (strategyId, thunkAPI) => {
    try {
      await deleteStrategy(strategyId);
      return strategyId;
    } catch (error) {
      const msg = parseErrorMessage(error);
      return thunkAPI.rejectWithValue({ error: msg });
    }
  }
);
