import { lazy } from "react";

const Plans = lazy(() => import("features/Plans"));

export const PLANS_PATH = "/plans";

export default [
  {
    path: PLANS_PATH,
    component: <Plans />,
  },
];
