import { lazy } from "react";

const Notifications = lazy(() => import("features/Notification"));

export const NOTIFICATION_PATH = "/notification";

export default [
  {
    path: NOTIFICATION_PATH,
    component: <Notifications />,
  },
];
